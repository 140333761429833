import API from '../http-helper.service';

let accessToken = localStorage.getItem('access_token') ? 
	localStorage.getItem('access_token') : '';
let config = {};
if(accessToken && accessToken.length > 0) 
  config = { headers:{ 'Authorization': 'Bearer ' + accessToken + '' } };
let userId=localStorage.getItem('ge_sso') ? 
localStorage.getItem('ge_sso') : '';


export default class ReviewPortalService {
    getAllRegions() {
        return API.get('builder/assetMgmt/region', config);
    }

    getTurbinesForSite(siteId) {
        return API.get('builder/assetMgmt/turbines?siteId=' + siteId, config);
    }

    getAllFormNames() {
        return API.get('builder/forms/getFormName', config);
    }

    getAllGroups() {
        return API.get('category?categoryType=Group', config);
    }

    getAllUsers() {
        return API.get('user-mgmt/users', config);
    }

    getAllFormsBySortingorSearching(searchQuery, sortParameter, currentPage, 
    		rowsPerPageSize) {
    	let page = currentPage > 0 ? (currentPage - 1) : currentPage;
        let baseUrl = 'filler/formData/filter?page=' + page + 
        	'&size=' + rowsPerPageSize;
        if (sortParameter) {
            if (sortParameter.value && sortParameter.value !== "") {
                baseUrl = baseUrl + "&sort=" + sortParameter.value + "," + 
                	sortParameter.order;
            }
        }
        return API.post(baseUrl, searchQuery, config).then(res => {
            return res;
        }, error => {
            return error;
        });
    }
    getAllUsersListAutoComplete(searchQuery) {
        return API.get(`user-mgmt/users/userlist/${searchQuery}`, config).then(res => {
            let item=[]
            if(res.data){
                item=res.data.map((val)=>{
                  return{
                    id:val.sso,
                    label:`${val.userFirstName} ${val.userLastName}(${val.sso})`,
                    email:val.email
                  }
                })
            }
          return item;
        }, error => {
          return error;
        });
      }
    approveTransferForm(formObject) {
        let configData = { headers: {...config.headers, userId: userId} };
        let baseUrl = 'filler/formData/transferFormData';
        return API.post(baseUrl, formObject, configData).then(res => {
          return res;
        }, err => {
          let error;
          error = (err.response) ? err.response : error;
          return error;
        });
      }
}