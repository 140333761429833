import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { DialogActions, Typography, withStyles } from '@material-ui/core';

const styles = theme => ({
    MuiPaper: {
        width: '100%',
    },
    closeButton: {
        position: 'absolute', right: theme.spacing(4), top: theme.spacing(2),
        color: theme.palette.grey[500]
    }
});

class ImagePopup extends Component {
    constructor(props) {
        super();
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onClose} classes={{ paper: classes.MuiPaper }}
                    aria-labelledby="form-dialog-title">
                        <DialogActions>
                        <IconButton id="iconButton" aria-label="close"
                        className={classes.closeButton} onClick={this.props.onClose}><CloseIcon />
                        </IconButton>
                        </DialogActions>
                    <DialogTitle id="dialog-title">
                        <Typography variant="h6">{this.props.title}</Typography>
                    </DialogTitle>
                    <DialogContent><img src={this.props.imageUrl} alt={this.props.title} height='350px' width='550px'/></DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ImagePopup);



