import Main from "../layout/Main";
import Minimal from "../layout/Minimal";
import LandingPage from "../review-portal/views/LandingPage";
import { ROLES } from "../services/form.helper.service";
import AssetProfiling from "../views/AssetProfiling";
import EditForm from "../views/EditForm";
import ExistingForms from "../views/ExistingForms";
import FormTabs from "../views/FormTabs";
import UserGroup from "../views/UserGroup";
import UserManagement from "../views/UserManagement";

export const RoutesConfig = {
    routes: [
      {
        path: '/createForm',
        component: FormTabs,
        layout: Minimal,
        roles: [ROLES.GLOBALADMIN] 
      },
      {
        path: '/existingForms',
        component: ExistingForms,
        layout: Minimal,
        roles: [ROLES.GLOBALADMIN] 
      },
      {
        path: '/editForm/:id',
        component: EditForm,
        layout: Main,
        roles: [ROLES.GLOBALADMIN] 
      },
      {
        path: '/usergroup',
        component: UserGroup,
        layout: Minimal,
        roles: [ROLES.GLOBALADMIN] 
      },
      {
        path: '/users',
        component: UserManagement,
        layout: Minimal,
        roles: [ROLES.GLOBALADMIN] 
      },
      {
        path: '/assets',
        component: AssetProfiling,
        layout: Minimal,
        roles: [ROLES.GLOBALADMIN, ROLES.ASSETMANAGER] 
      },
      {
        path: '/review',
        component: LandingPage,
        layout: Minimal,
        roles: [ROLES.GLOBALADMIN, ROLES.SITEMANAGER, ROLES.TECHNICIAN] 
      }
    ],
  };