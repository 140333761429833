import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, Box, Typography, Button, Divider, Grid, Select, FormControl, 
	MenuItem, InputLabel, TextField, Chip } from '@material-ui/core';
import { KeyboardDatePicker } from "@material-ui/pickers";
import ReviewPortalService from '../../services/review-portal-services/review-portal.service';
import { FORM_STATUS, ADVANCED_SEARCH_CRITERIA } from '../utilities/Constants';
import { FormStore } from '../store/form.store';
import AdvancedSearchSummary from './AdvancedSearchSummary';
import ExpandLessOutlined from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';
import API from '../../services/http-helper.service';
import FormBuilderService from '../../services/form-builder.service';
import { Autocomplete } from '@material-ui/lab';
import FormFillerService from '../../services/review-portal-services/form-filler.service';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
const styles = (theme) => ({
    container: {
        paddingTop: 20, paddingLeft: 20, paddingRight: 20, margin: "2%",
        position: 'relative', minHeight: '40%'
    },
    criteriaView: { marginTop: 20, marginBottom: 40 },
    heading: { fontWeight: 'bold' },
    buttonsView: { marginBottom: 10, marginTop: 10, float: 'right' },
    button: { margin: 10 },
    applyButton : {
        color: '#419BAC', border: '1px solid rgba(0, 0, 0, 0.23)',
        padding: '5px 15px', borderColor: '#419BAC', borderRadius: 3
    },
    divider: { marginBottom: '5px' },
    collapseIcon: {
        color: "#00000094", position: 'absolute', top: '32px', right: '20px',
        border: 'solid', borderRadius: '50%', borderWidth: '1px',
        borderColor: '#00000094', marginBottom: '5px'
    }
});

class AdvancedSearch extends React.Component {
    constructor(props) {
        super();
        this.reviewPortalService = new ReviewPortalService();
        this.formBuilderService = new FormBuilderService();
        this.formFillerService = new FormFillerService();
        this.state = {
            expanded: true,
            selectedDurationValue: '',
            searchCriteria: {},
            summaryValues: [],
            selectedWindfarmValues: [],
            selectedTurbineValues: [],
            selectedFormNames: [],
            selectedSubmittedByValues: [],
            advancedSearchFields: [
                {
                    elements: [
                        {
                            type: 'dropdown',
                            label: ADVANCED_SEARCH_CRITERIA.REGION,
                            name: 'Region',
                            multiple: false,
                            onChange: this.onChangeRegion.bind(this),
                            api: this.reviewPortalService.getAllRegions,
                            options: '',
                            menuItemValue: 'siteGroupId',
                            menuItemName: 'siteGroupName'
                        },
                        {
                            type: 'siteautocomplete',
                            label: ADVANCED_SEARCH_CRITERIA.WINDFARM,
                            name: 'Windfarms',
                            multiple: false,
                            onChange: this.onChangeWindfarm.bind(this),
                            options: [],
                            menuItemValue: 'siteId',
                            menuItemName: 'siteId'
                        },
                        {
                            type: 'turbineautocomplete',
                            label: ADVANCED_SEARCH_CRITERIA.TURBINES,
                            name: 'Turbines',
                            multiple: true,
                            onChange: this.onChangeTurbine.bind(this),
                            options: [],
                            menuItemValue: 'windTurbineId',
                            menuItemName: 'windTurbineId'
                        }
                    ]
                },
                {
                    elements: [
                        {
                            type: 'autocomplete',
                            label: ADVANCED_SEARCH_CRITERIA.FORM_NAME,
                            name: 'Form Name',
                            multiple: false,
                            onChange: this.onChangeFormName.bind(this),
                            api: this.reviewPortalService.getAllFormNames,
                            options: [],
                            menuItemValue: 'formName',
                            menuItemName: 'formName'
                        },
                        {
                            type: 'dropdown',
                            label: ADVANCED_SEARCH_CRITERIA.GROUP,
                            name: 'Group',
                            multiple: false,
                            onChange: this.onChangeGroupCategory.bind(this),
                            api: this.reviewPortalService.getAllGroups,
                            menuItemValue: 'categoryName',
                            menuItemName: 'categoryName'
                        },
                        {
                            type: 'dropdown',
                            label: ADVANCED_SEARCH_CRITERIA.SUB_GROUP,
                            name: 'Sub Group',
                            multiple: false,
                            onChange: this.onChangeSubGroupCategory.bind(this),
                            options: [],
                            menuItemValue: 'categoryName',
                            menuItemName: 'categoryName'
                        },
                    ]
                },
                {
                    elements: [
                        {
                            type: 'dropdown',
                            dropdownType: 'static',
                            label: ADVANCED_SEARCH_CRITERIA.FORM_STATUS,
                            name: 'Form Status',
                            multiple: true,
                            onChange: this.onChangeFormStatus.bind(this),
                            options: [FORM_STATUS.COMPLETED, FORM_STATUS.REJECTED, FORM_STATUS.INPROGRESS, FORM_STATUS.APPROVED],
                        },
                        {
                            type: 'autocomplete',
                            label: ADVANCED_SEARCH_CRITERIA.SUBMITTED_BY,
                            name: 'Submitted By',
                            multiple: false,
                            onChange: this.onChangeSubmittedBy.bind(this),
                            api: this.reviewPortalService.getAllUsers,
                            options: [],
                            menuItemValue: 'sso',
                            menuItemName: 'sso'
                        },
                        {
                            type: 'dropdown',
                            dropdownType: 'static',
                            label: 'duration',
                            name: 'Duration',
                            multiple: false,
                            onChange: this.onChangeDuration.bind(this),
                            options: ['Duration']
                        },
                        {
                            type: 'date',
                            elements: [
                                {
                                    type: 'datePicker',
                                    label: 'fromDate',
                                    name: 'From'
                                },
                                {
                                    type: 'datePicker',
                                    label: 'toDate',
                                    name: 'To'
                                }
                            ]
                        }
                    ]
                }
            ]
        };
        this.updateState = this.updateState.bind(this);
        this.removeCriterion = this.removeCriterion.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.showSelectedDropdownValue = this.showSelectedDropdownValue.bind(this);
        this.populateDropdowns = this.populateDropdowns.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.populateSummaryValues = this.populateSummaryValues.bind(this);
        this.deleteCriteriaFromSummary = this.deleteCriteriaFromSummary.bind(this);
        this.getNameFromId = this.getNameFromId.bind(this);
        this.onDeleteSummaryValue = this.onDeleteSummaryValue.bind(this);
        this.deleteValueFromSearchCriteria = this.deleteValueFromSearchCriteria.bind(this);
        this.deleteValueFromSummary = this.deleteValueFromSummary.bind(this);
        this.getIdFromName = this.getIdFromName.bind(this);
        this.handleExpandOrCollapse = this.handleExpandOrCollapse.bind(this);
    }

    componentDidMount() {
        this.populateDropdowns();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rowsPerPageSize !== this.props.rowsPerPageSize) {
            let searchParams = JSON.parse(JSON.stringify(this.state.searchCriteria));
            FormStore.sharedInstance().filterMyForms(searchParams, this.props.rowsPerPageSize);
        }
    }

    onChangeGroupCategory = (event) => {
        const value = event.target.value;
        this.updateState(ADVANCED_SEARCH_CRITERIA.GROUP, value);
    }

    onChangeSubGroupCategory = (event) => {
        const value = event.target.value;
        if (event.target.value.length !== 0) {
            this.updateState(ADVANCED_SEARCH_CRITERIA.SUB_GROUP, value);
        }
        else {
            this.removeCriterion(ADVANCED_SEARCH_CRITERIA.SUB_GROUP);
        }
    }

    populateDropdowns() {
        let arr = Array.from(this.state.advancedSearchFields);
        arr.forEach(row => {
            row.elements.forEach(element => {
                if (element.api) {
                    element.api()
                        .then(result => {
                            if(element.type==="turbineautocomplete"){
                                element.options = result.data.sort((a, b) => a.properties.shortName.localeCompare(b.properties.shortName));
                            }else if(element.menuItemName==="siteGroupName"){
                                element.options = result.data.sort((a, b) => a.siteGroupName.localeCompare(b.siteGroupName));
                            }else{
                                element.options = result.data;
                            }
                            this.setState({ advancedSearchFields: arr });
                        })
                        .catch((err) => { return err; });
                }
            }
            );
        });
        this.setState({ advancedSearchFields: arr });
    }

    onChangeRegion(event) {
        if(event){
            let arr = [];
            arr.push(event.target.value);
            this.updateState(ADVANCED_SEARCH_CRITERIA.REGION, arr);
        }
    }

    onChangeWindfarm(event, value) {
        if(value){
            this.setState({ selectedWindfarmValues: value });
            this.updateState(ADVANCED_SEARCH_CRITERIA.WINDFARM, value);
        }
        else{
            this.removeCriterion(ADVANCED_SEARCH_CRITERIA.WINDFARM);
        }
    }

    onChangeTurbine(event, value) {
            if(value){
                this.setState({ selectedTurbineValues: value });
                this.updateState(ADVANCED_SEARCH_CRITERIA.TURBINES, value);
        }
        else {
            this.removeCriterion(ADVANCED_SEARCH_CRITERIA.TURBINES);
        }
    }

    onChangeFormName(event, value) {
        if(value){
            this.setState({ selectedFormNames: value });
            this.updateState(ADVANCED_SEARCH_CRITERIA.FORM_NAME, value);
        }
        else{
            this.removeCriterion(ADVANCED_SEARCH_CRITERIA.FORM_NAME);
        }
    }

    onChangeFormStatus(event) {
        if (event.target.value.length !== 0) {
            this.updateState(ADVANCED_SEARCH_CRITERIA.FORM_STATUS, event.target.value);
        }
        else {
            this.removeCriterion(ADVANCED_SEARCH_CRITERIA.FORM_STATUS);
        }
    }

    onChangeSubmittedBy(event, value) {
        if(value){
            this.setState({ selectedSubmittedByValues: value });
            this.updateState(ADVANCED_SEARCH_CRITERIA.SUBMITTED_BY, value);
        }
        else{
            this.removeCriterion(ADVANCED_SEARCH_CRITERIA.SUBMITTED_BY);
        }  
    }

    onChangeDuration(event) {
        this.setState({ selectedDurationValue: event.target.value }, () => {
            this.populateSummaryValues('duration', event.target.value); // this attribute doesn't need to be sent to the backend as a filter criterion yet. It only toggles on/off Date fields at the moment. Hardcoding as 'Duration'
        });
    }

    onChangeFromDate(date) {
        if (date) {
            this.updateState(ADVANCED_SEARCH_CRITERIA.FROM_DATE, new Date(date).getTime());
        }
        else { // deletion
            this.removeCriterion(ADVANCED_SEARCH_CRITERIA.FROM_DATE);
        }
    }

    onChangeToDate(date) {
        if (date) {
            this.updateState(ADVANCED_SEARCH_CRITERIA.TO_DATE, new Date(date).getTime());
        }
        else { // deletion
            this.removeCriterion(ADVANCED_SEARCH_CRITERIA.TO_DATE);
        }
    }

    updateState(attribute, value) {
        let siteId = [];
        let turbineId = [];
        const clone = Object.assign({}, this.state.searchCriteria);
        if (attribute === 'siteId') {
            let siteIds = value.map((value) => value.siteId);
            siteId.push(...siteIds);
            clone[attribute] = siteId;
        } else if (attribute === 'turbineId') {
            let turbineIds = value.map((value) => value.windTurbineId);
            turbineId.push(...turbineIds);
            clone[attribute] = turbineId;
        } else {
            clone[attribute] = value;
        }
        this.setState({ searchCriteria: clone }, () => {
            if(attribute === ADVANCED_SEARCH_CRITERIA.REGION){
                this.formBuilderService.getAllSitesByRegion(value[0])
                    .then((siteList) => {
                        let arr = Array.from(this.state.advancedSearchFields);
                        arr[0].elements[1].options = siteList.data.sort((a, b) => a.properties.name.localeCompare(b.properties.name));;
                        this.setState({ advancedSearchFields: arr });
                    })
                    .catch((err) => { return err });
                // On change of regions, clear previously selected turbines and windfarms
                                  
                this.setState({selectedTurbineValues: []});
                this.setState({selectedWindfarmValues: []});
                this.removeCriterion(ADVANCED_SEARCH_CRITERIA.WINDFARM, () => {
                    this.removeCriterion(ADVANCED_SEARCH_CRITERIA.TURBINES, () => {
                        this.populateSummaryValues(attribute, value); // populate summary here to avoid state update clashes
                    });
                });
            }
            else if (attribute === ADVANCED_SEARCH_CRITERIA.WINDFARM) {
                if (siteId.length) {
                    this.reviewPortalService.getTurbinesForSite(siteId)
                    .then((turbinesList) => {
                        let arr = Array.from(this.state.advancedSearchFields);
                        arr[0].elements[2].options = turbinesList.data.turbines;
                        this.setState({ advancedSearchFields: arr });
                    })
                    .catch((err) => { return err });
                }
                // if there are no windfarms selected, then turbines also needed to be cleared
                // if more than 1 windfarm is selected, turbine needes to be disabled
                let selectedWindfarmCount = this.state.selectedWindfarmValues.length;            
                if(selectedWindfarmCount === 0 || selectedWindfarmCount > 1) {
                    this.setState({selectedTurbineValues: []});
                }
                // Clear previously selected turbines
                this.removeCriterion(ADVANCED_SEARCH_CRITERIA.TURBINES, () => {
                    this.populateSummaryValues(attribute, value); // populate summary here to avoid state update clashes
                });
            } else if (attribute === ADVANCED_SEARCH_CRITERIA.GROUP) {
                const apiURL = this.formattedCategoryURL(ADVANCED_SEARCH_CRITERIA.GROUP, value);
                let accessToken = localStorage.getItem('access_token') ? 
                	localStorage.getItem('access_token') : '';
                let config = {};
                if(accessToken && accessToken.length > 0) 
                    config = {
                	  headers:{ 'Authorization': 'Bearer ' + accessToken + '' }
                	};
                API.get(apiURL, config).then((res) => {
                    var data = res.data;
                    if (data) {
                        var subCategories;
                        data.forEach((item) => {
                            if (item.categoryName === value) {
                                subCategories = item.subCategories;
                                let arr = Array.from(this.state.advancedSearchFields);
                                arr[1].elements[2].options = subCategories;
                                this.setState({ advancedSearchFields: arr });
                            }
                        });
                    }
                }).catch((err) => { return err });
                this.removeCriterion(ADVANCED_SEARCH_CRITERIA.SUB_GROUP, () => {
                    this.populateSummaryValues(attribute, value);
                });
            } else {
                this.populateSummaryValues(attribute, value);
            }
        });
    }

    formattedCategoryURL(category, name) {
        const endPoint = "category?categoryType=";
        const categoryName = "&categoryName=";
        var apiEndPoint = endPoint + category;
        if (name && name.length > 0) {
            apiEndPoint = apiEndPoint + categoryName + name;
        }
        return apiEndPoint;
    }

    removeCriterion(attribute, callback) {
        const clone = Object.assign({}, this.state.searchCriteria);
        delete clone[attribute];
        this.setState({ searchCriteria: clone }, () => {
            this.deleteCriteriaFromSummary(attribute, () => {
                if (typeof callback == "function") {
                    callback();
                }
            });
        });
    }

    getNameFromId(attribute, id) {
        let name = '';
        this.state.advancedSearchFields.forEach((row) => {
            row.elements.forEach((field) => {
                if (field.label === attribute) {
                    let selectedOption = field.options.filter((option) => option.id === id);
                    name = selectedOption[0][field.menuItemName];
                }
            });
        });
        return name;
    }

    getIdFromName(attribute, name) {
        let id;
        this.state.advancedSearchFields.forEach((row) => {
            row.elements.forEach((field) => {
                if (field.label === attribute) {
                    let selectedOption = field.options.filter((option) => option[field.menuItemName] === name);
                    id = selectedOption[0][field.menuItemValue];
                }
            });
        });
        return id;
    }

    populateSummaryValues(attribute, value) {
        this.deleteCriteriaFromSummary(attribute, () => {
            let arr = [];
            arr = Array.from(this.state.summaryValues);
            if (Array.isArray(value)) {
                value.forEach((selectedValue) => {
                    let summaryValue = {};
                    summaryValue['label'] = attribute;
                    if (typeof selectedValue === 'string') {
                            summaryValue['value'] = selectedValue;
                    }
                    else {// converting ids into names
                        if(attribute === 'siteId'){
                            summaryValue['value'] = selectedValue.properties.name;
                        }   
                        else if(attribute === 'turbineId'){
                                summaryValue['value'] = selectedValue.properties.shortName;
                        }
                        else{
                            summaryValue['value'] = this.getNameFromId(attribute, selectedValue);
                        } 
                    }
                    arr.push(summaryValue);
                });
            } else {// string values
                let summaryValue = {};
                summaryValue['label'] = attribute;
                if ((attribute === 'fromDate' || attribute === 'toDate')) {
                    summaryValue['value'] = new Date(value).toISOString().slice(0, 10);
                } else {
                    summaryValue['value'] = value;
                }
                arr.push(summaryValue);
            }
            this.setState({ summaryValues: arr });
        });
    }

    deleteCriteriaFromSummary(attribute, callback) {
        let updatedSummary = this.state.summaryValues.filter(criterion => criterion.label !== attribute);
        this.setState({ summaryValues: updatedSummary }, () => {
            if (typeof callback == "function") {
                callback();
            }
        });
    }

    showSelectedDropdownValue(element) {
        if (element.label !== 'duration' && !element.multiple) {
            if (this.state.searchCriteria[element.label]) {
                return Array.isArray(this.state.searchCriteria[element.label]) ? this.state.searchCriteria[element.label][0] : this.state.searchCriteria[element.label];
            }
            else {
                return '';
            }
        }
        else if (element.label !== 'duration' && element.multiple) {
            if (this.state.searchCriteria[element.label]) {
                return this.state.searchCriteria[element.label];
            }
            else {
                return [];
            }
        }
        else {
            return this.state.selectedDurationValue;
        }
    }

    onCancel() {
        this.setState({ searchCriteria: {}, summaryValues: [], selectedDurationValue: '', selectedWindfarmValues: [], selectedTurbineValues: [], selectedFormNames: [], selectedSubmittedByValues: [] }, () => {
            FormStore.sharedInstance().loadMyForms(); // reverting to initial state of form list
        });
        this.props.parentCallBack(false);
    }

    onSearch() {
        let searchParams = JSON.parse(JSON.stringify(this.state.searchCriteria));
        let formCategories = [];
        if (searchParams['Group']) {
            formCategories.push(searchParams['Group']);
            delete searchParams['Group'];
        } 
        if (searchParams['Sub-Group']) {
            formCategories.push(searchParams['Sub-Group']);
            delete searchParams['Sub-Group'];
        }
        if(formCategories.length){
            searchParams.formCategories = formCategories;
        }
        FormStore.sharedInstance().filterMyForms(searchParams, this.props.rowsPerPageSize)
        .then((response) => {
            if (response.status === 200) {
                this.setState({ expanded: false });
            }
        })
        .catch((err) => {
            console.error("Search failed:", err);
        });
        this.props.parentCallBack(true);
    }

    clearAutoComplete(id) {
    	let autoC = document.getElementById(id);
		const ele = autoC.parentElement.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
		if (ele) ele.click();
    }
    
    onDeleteSummaryValue(selection) {
        let value = this.state.searchCriteria[selection.label];
        if (Array.isArray(value)) {
            if (value.length === 1 && selection.label === ADVANCED_SEARCH_CRITERIA.GROUP) {
                this.removeCriterion(selection.label, () => {
                    //If Windfarm is deselected, delete any previously selected Turbines from underlying data  because they are not relevant anymore:
                    
                        //If Group is deselected, delete any previously selected Subgroup from underlying data  because they are not relevant anymore:
                        this.removeCriterion(ADVANCED_SEARCH_CRITERIA.SUB_GROUP, () => {
                            // Clear out the options of the Subgroup dropdown as well
                            let arr = Array.from(this.state.advancedSearchFields);
                            arr[1].elements[2].options = [];
                            this.setState({ advancedSearchFields: arr });
                        })
               });
            } else if (selection.label === ADVANCED_SEARCH_CRITERIA.WINDFARM) {
                this.removeCriterion(ADVANCED_SEARCH_CRITERIA.TURBINES, () => {
                  this.clearAutoComplete("turbineautocomplete");
                  // Clear out the options of the Turbines dropdown as well
                  let arr = Array.from(this.state.advancedSearchFields);
                  let siteId = arr[0].elements[1].options.filter(
                    (ele) => ele.properties.name === selection.value
                  )[0]?.siteId;
                  arr[0].elements[2].options =
                    arr[0].elements[2].options.filter(
                      (option) => option.connections.siteId !== siteId
                    );
                  arr[0].elements[2].value = "";
                  this.setState({ advancedSearchFields: arr });
                  this.deleteValueFromSearchCriteria(selection, siteId);
                });
            } else if (selection.label === ADVANCED_SEARCH_CRITERIA.TURBINES) {
                let arr = Array.from(this.state.advancedSearchFields);
                let turbineId = arr[0].elements[2].options.filter(
                    (ele) => ele.properties.shortName === selection.value
                  )[0]?.windTurbineId;
                this.deleteValueFromSearchCriteria(selection,'', turbineId);
            } else {// multiselect dropdowns with multiple options selected
                this.deleteValueFromSearchCriteria(selection);
            }
        }
        else { // String values
            if (selection.label !== 'duration') {
                this.removeCriterion(selection.label);
            }
            else { // Duration dropdown
                this.deleteCriteriaFromSummary('duration', () => {
                    this.setState({ selectedDurationValue: '' }, () => {
                        // clearing out selected date range if any
                        this.removeCriterion(ADVANCED_SEARCH_CRITERIA.FROM_DATE, () => {
                            this.removeCriterion(ADVANCED_SEARCH_CRITERIA.TO_DATE);
                        })
                    });
                });
            }
        }
        if (Object.keys(this.state.searchCriteria).length === 1) {
            FormStore.sharedInstance().loadMyForms();
            this.props.parentCallBack(false);
        }
    }

    deleteValueFromSearchCriteria(summarySelection, siteId, turbineId) {
        let obj = Object.assign({}, this.state.searchCriteria);
        let values = obj[summarySelection.label];
        let updatedValues = [];
        let updatedselectedWindfarmValues = [];
        let updatedselectedTurbineValues = [];
        if (typeof values[0] !== typeof summarySelection.value) { // need to convert name to id
            let selectedId = this.getIdFromName(summarySelection.label, summarySelection.value);
            updatedValues = values.filter((value) => value !== selectedId);
        }
        else if(siteId) {
            updatedValues = values.filter((value) => value !== siteId);
            updatedselectedWindfarmValues = this.state.selectedWindfarmValues.filter((value) => value.siteId !== siteId);
            this.setState({ selectedWindfarmValues: updatedselectedWindfarmValues })

        }
        else if(turbineId) {
            updatedValues = values.filter((value) => value !== turbineId);
            updatedselectedTurbineValues = this.state.selectedTurbineValues.filter((value) => value.windTurbineId !== turbineId);
            this.setState({ selectedTurbineValues: updatedselectedTurbineValues })
        }
        else {
            updatedValues = values.filter((value) => value !== summarySelection.value);            
        }
        if(summarySelection.label === "formName") {
            this.setState({ selectedFormNames: updatedValues });
        }
        if(summarySelection.label=== "userId") {
            this.setState({ selectedSubmittedByValues: updatedValues });
        }
        obj[summarySelection.label] = updatedValues;
        this.setState({ searchCriteria: obj }, () => {
            this.deleteValueFromSummary(summarySelection.value);
        });
    }

    deleteValueFromSummary(summaryValue) {
        let updatedSummary = this.state.summaryValues.filter((summaryObj) => summaryObj.value !== summaryValue);
        this.setState({ summaryValues: updatedSummary });
    }

    handleExpandOrCollapse(expand) {
        this.setState({ expanded: expand });
    }

    onEnterSearch(event){
        if (event.key === 'Enter') {
            event.stopPropagation();
        }
    }

    sitefilterOptions = (options, state) => {
        const { inputValue } = state;
        return options?.filter((option) =>
            option.properties.name.toLowerCase().includes(inputValue.toLowerCase())
          );
      };

    render() {
        const { classes } = this.props;
        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;
        return (
            <div>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} >
                            <Card className={classes.container}>
                                <Typography variant="h6" className={classes.heading}>
                                    ADVANCED SEARCH
                                </Typography>
                                <AdvancedSearchSummary selectedValues={this.state.summaryValues} advancedSearchFields={this.state.advancedSearchFields} onDelete={this.onDeleteSummaryValue} />
                                {this.state.expanded &&
                                    <ExpandLessOutlined className={classes.collapseIcon} onClick={() => this.handleExpandOrCollapse(false)} />
                                }
                                {!this.state.expanded &&
                                    <ExpandMoreOutlined className={classes.collapseIcon} onClick={() => this.handleExpandOrCollapse(true)} />
                                }
                                {this.state.expanded &&
                                    <div>
                                        <Divider className={classes.divider} />
                                        <Box className={classes.criteriaView}>
                                            {this.state.advancedSearchFields.map((row, fIndex) => (
                                                <Grid container spacing={9} key={fIndex}>
                                                    {row.elements.map((element, index) => (
                                                        <Grid item md={4} xs={12} key={index}>
                                                            <FormControl fullWidth variant="outlined">
                                                                {element.type === 'dropdown' &&
                                                                    <div>
                                                                        <InputLabel htmlFor={element.label}>{element.name}</InputLabel>
                                                                        <Select label={element.name}
                                                                            style={{ width: '100%' }}
                                                                            onChange={element.onChange}
                                                                            multiple={element.multiple}
                                                                            value={this.showSelectedDropdownValue(element)}>
                                                                            {(element.options && element.options.length > 0) &&
                                                                                element.options.map((option, oIndex) => (
                                                                                    <MenuItem key={oIndex} value={element.dropdownType && element.dropdownType === 'static' ? option : option[element.menuItemValue]}>
                                                                                        {element.dropdownType && element.dropdownType === 'static' ? option : option[element.menuItemName]}
                                                                                    </MenuItem>))
                                                                            }
                                                                        </Select>
                                                                    </div>
                                                                }
                                                                {this.state.selectedDurationValue === 'Duration' && element.type === 'date' &&
                                                                    <Grid container>
                                                                        {element.elements && element.elements.map((datePicker, eeIndex) => (
                                                                            <Grid key={eeIndex} item md={6} xs={12} >
                                                                                <KeyboardDatePicker
                                                                                    label={datePicker.name}
                                                                                    variant="dialog"
                                                                                    InputLabelProps={{ shrink: true }}
                                                                                    InputProps={{ readOnly: true }}
                                                                                    maxDate={datePicker.label === 'fromDate' && this.state.searchCriteria.toDate ? this.state.searchCriteria.toDate : 4102444800000}// default max date set as 2100-1-1
                                                                                    minDate={datePicker.label === 'toDate' && this.state.searchCriteria.fromDate ? this.state.searchCriteria.fromDate : -2208988800000}// default min date set as 1900-1-1
                                                                                    minDateMessage=''
                                                                                    maxDateMessage=''
                                                                                    invalidDateMessage=''
                                                                                    inputVariant="outlined"
                                                                                    value={this.state.searchCriteria[datePicker.label] ? this.state.searchCriteria[datePicker.label] : null}
                                                                                    onChange={datePicker.label === 'fromDate' ? ((date) => this.onChangeFromDate(date)) : ((date) => this.onChangeToDate(date))}
                                                                                    format="yyyy/MM/dd"
                                                                                />
                                                                            </Grid>))
                                                                        }
                                                                    </Grid>
                                                                }
                                                                {element.type === 'autocomplete' &&
                                                                    <div>
                                                                        <Autocomplete
                                                                            id='autocompleteSelect'
                                                                            multiple
                                                                            options={element?.options?.map(option => option[element.menuItemName])} 
                                                                            disableCloseOnSelect
                                                                            getOptionLabel = {(option) => option ? option : ''}                                                                            
                                                                            onChange={element.onChange}
                                                                            value={element.name === "Form Name" ? this.state.selectedFormNames : this.state.selectedSubmittedByValues}
                                                                            style={{ width: "100%" }}
                                                                            renderTags={(value, getTagProps) =>
                                                                                value.map((option, index) => (
                                                                                  <Chip
                                                                                    id="chip"
                                                                                    size="small"
                                                                                    label={option ? option : ""}
                                                                                    {...getTagProps({ index })}   
                                                                                  />
                                                                                ))
                                                                              }
                                                                              
                                                                            renderOption={(option, { selected }) => (
                                                                                <React.Fragment>
                                                                                <Checkbox
                                                                                  id="checkboxIcon"
                                                                                  icon={icon}
                                                                                  checkedIcon={checkedIcon}
                                                                                  style={{ marginRight: 8 }}
                                                                                  checked={selected}
                                                                                />
                                                                                {option ? option : ''}
                                                                              </React.Fragment>
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    id="autocompleteID"
                                                                                    variant="outlined"
                                                                                    onKeyDown={this.onEnterSearch}
                                                                                    label={element.name}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                }
                                                                {element.type === 'siteautocomplete' &&
                                                                    <div>
                                                                        <Autocomplete
                                                                            multiple
                                                                            id="siteautocomplete"
                                                                            options={element.options}
                                                                            onChange={element.onChange}
                                                                            disableCloseOnSelect
                                                                            value={this.state.selectedWindfarmValues}
                                                                            filterOptions={this.sitefilterOptions}
                                                                            getOptionLabel={(option) => option?.properties?.name || ""}
                                                                            renderTags={(value, getTagProps) =>
                                                                              value.map((option, index) => (
                                                                                <Chip
                                                                                  id="chip"
                                                                                  size="small"
                                                                                  label={option?.properties?.name || ""}
                                                                                  {...getTagProps({ index })}   
                                                                                />
                                                                              ))
                                                                            }
                                                                            renderOption={(
                                                                              option,
                                                                              { selected }
                                                                            ) => (
                                                                              <React.Fragment>
                                                                                <Checkbox
                                                                                  id="checkboxIcon"
                                                                                  icon={icon}
                                                                                  checkedIcon={checkedIcon}
                                                                                  style={{ marginRight: 8 }}
                                                                                  checked={selected}
                                                                                />
                                                                                {option?.properties?.name || ""}
                                                                              </React.Fragment>
                                                                            )}
                                                                            style={{ width: "100%" }}
                                                                            renderInput={(params) => (
                                                                              <TextField
                                                                                {...params}
                                                                                label={element.name}
                                                                                id="autocompleteID"
                                                                                variant="outlined"
                                                                              />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                }
                                                                {element.type === 'turbineautocomplete' &&
                                                                    <div>
                                                                        <Autocomplete
                                                                            multiple
                                                                            disabled={this.state.selectedWindfarmValues.length > 1}
                                                                            id="turbineautocomplete"
                                                                            options={element.options}
                                                                            onChange={element.onChange}
                                                                            disableCloseOnSelect
                                                                            value={this.state.selectedTurbineValues}
                                                                            getOptionLabel={(option) => option?.properties?.shortName || ""}
                                                                            renderTags={(value, getTagProps) =>
                                                                              value.map((option, index) => (
                                                                                <Chip
                                                                                  id="chip"
                                                                                  size="small"
                                                                                  label={option?.properties?.shortName || ""}
                                                                                  {...getTagProps({ index })}   
                                                                                />
                                                                              ))
                                                                            }
                                                                            renderOption={(
                                                                              option,
                                                                              { selected }
                                                                            ) => (
                                                                              <React.Fragment>
                                                                                <Checkbox
                                                                                  id="checkboxIcon"
                                                                                  icon={icon}
                                                                                  checkedIcon={checkedIcon}
                                                                                  style={{ marginRight: 8 }}
                                                                                  checked={selected}
                                                                                />
                                                                                {option?.properties?.shortName || ""}
                                                                              </React.Fragment>
                                                                            )}
                                                                            style={{ width: "100%" }}
                                                                            renderInput={(params) => (
                                                                              <TextField
                                                                                {...params}
                                                                                label={element.name}
                                                                                id="autocompleteID"
                                                                                variant="outlined"
                                                                              />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                }
                                                            </FormControl>
                                                        </Grid>))
                                                    }
                                                </Grid>
                                            ))
                                            }
                                        </Box>
                                        <Divider className={classes.divider} />
                                        <div className={classes.buttonsView}>
                                            <Button variant="text" className={classes.button} onClick={this.onCancel}>
                                                CLEAR
                                            </Button>
                                            <Button variant="outlined" className={classes.applyButton} onClick={this.onSearch} disabled={Object.keys(this.state.searchCriteria).length === 0 ? true : false} >
                                                APPLY
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}

export default withStyles(styles)(AdvancedSearch);
