import React, { Component } from "react";
import SortIcon from '@material-ui/icons/Sort';
import { Divider, IconButton, ListItemText, ListSubheader, Menu, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { SORT_MENU_OPTIONS } from "../../services/review-portal-services/review-form.helper.service";
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import { colors } from "../utilities/Constants";

const styles = theme => ({
    sortIcon: {
        float: 'right'
    },
    menuItem: {
        width: "200px",
        "&:hover": {
            color: colors.sortMenuItemColor,
            backgroundColor: colors.sortMenuBgColor

        }
    },
    selected: {
        color: colors.sortMenuItemColor,
        backgroundColor: colors.sortMenuSelectedBgColor
    },
    menuItemText: {
        "&:hover": {
            color: colors.sortMenuItemColor
        }
    },
    labelButton:{
        borderRadius:'0%'
    },
    checkIcon:{
        marginBottom:'-4px',
        opacity:0.4
    },
    sortLabel:{
        margin: '-4px'
    }
});

class SortMenu extends Component {
    constructor(props) {
        super();
        this.sortMenuitems = SORT_MENU_OPTIONS;
        this.state = {
            anchorEl: null,
            sortParameter: props.sortParam,
            showSortIcon: true
        };
        this.onMenuItemClicked = this.onMenuItemClicked.bind(this);
        this.clearSorting = this.clearSorting.bind(this);
        this.parameter = '';
    }

    handleClose = (event) => {
        this.setState({ anchorEl: null });
    }

    onMenuItemClicked(parameter) {
        this.parameter = parameter;
        this.setState({
            showSortIcon: false
        });
        if (this.state.sortParameter.label !== parameter.label) { // different from current selection
            this.props.onMenuClicked({ label: parameter.label, value: parameter.value, order: "desc" });
        }
        else { // toggling
            this.props.onMenuClicked({ label: parameter.label, value: parameter.value, order: this.state.sortParameter.order === "asc" ? "desc" : "asc" });
        }
    }

    clearSorting() {
        this.props.onMenuClicked({ label: "", value: "", order: "" });
        this.setState({
            showSortIcon: true
        });
    }

    changeSortingOrder = () => {
        this.props.onMenuClicked({ label: this.state.sortParameter.label, value: this.state.sortParameter.value, order: this.state.sortParameter.order === "asc" ? "desc" : "asc" });
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.sortParameter !== props.sortParam) {
            return {
                sortParameter: props.sortParam
            }
        }
        return null
    }

    render() {
        const { classes } = this.props;
        const { label } = this.state.sortParameter;
        return <>
            <span className={classes.sortIcon}>
                <IconButton className={classes.labelButton}
                    onClick={(event) => { this.setState({ anchorEl: event.currentTarget }); }}>
                    {this.state.showSortIcon ?
                        <SortIcon /> :
                        <Typography className={classes.sortLabel}>{this.state.sortParameter.label}</Typography>}
                </IconButton>
                {this.state.sortParameter.order === "asc" ?
                    <IconButton edge="end" onClick={this.changeSortingOrder}> <ArrowUpwardOutlinedIcon /> </IconButton> : null}
                {this.state.sortParameter.order === "desc" ?
                    <IconButton edge="end" onClick={this.changeSortingOrder}><ArrowDownwardOutlinedIcon /> </IconButton> : null}
            </span>
            <Menu elevation={3} getContentAnchorEl={null} keepMounted open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 240, left: 570 }}>
                <ListSubheader>
                    <Typography variant="overline" component="span" >
                        SORT BY
                    </Typography>
                    <Typography variant="caption" component="span" style={{ marginTop: "15px", float: "right",  marginRight: "2px"}}>
                        <Link
                            id="clear"
                            variant="body2"
                            style={{ color: "#FF0000" }}
                            onClick={label?.length && this.clearSorting}>
                            Clear
                        </Link>
                    </Typography>
                </ListSubheader>
                <Divider />
                {this.sortMenuitems.map((parameter, index) => (
                    <MenuItem id={parameter.label} key={index} className={classes.menuItem} value={parameter.value} classes={{ selected: classes.selected }}
                        selected={this.state.sortParameter.label === parameter.label} onClick={() => { this.onMenuItemClicked(parameter) }}>
                        <ListItemText className={classes.menuItemText} primary={<Typography><CheckIcon className={classes.checkIcon}/> {parameter.label}</Typography>}/>
                        {this.state.sortParameter.label === parameter.label && this.state.sortParameter.order === "desc" &&
                            <ArrowDownwardOutlinedIcon />}
                        {this.state.sortParameter.label === parameter.label && this.state.sortParameter.order === "asc" &&
                            <ArrowUpwardOutlinedIcon />}
                    </MenuItem>
                ))}
            </Menu>
        </>
    }
}

export default withStyles(styles)(SortMenu);
