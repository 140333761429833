
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Toolbar,
  Switch,
  FormControlLabel,
  Grid,
  TextField } from '@material-ui/core';

const styles = theme => ({
  root: {
    borderTop: '1px solid rgb(91, 195, 115)',
    paddingLeft: "40px"
  },
  flexGrow: {
    flexGrow: 1,
  },
  switchLabel: {
    marginLeft: 0,
    marginRight: 20,
  },
  switch: {
    marginLeft: 3,
  },
  questionButtonStyle: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: 14
  },
  dividerStyle: {
    backgroundColor: 'rgb(190, 190, 190)', width: 1, height: 40
  },
  activeTextField: {
    backgroundColor: 'rgba(255, 255, 255, .75)',
  },
  dropdownArrowStyle: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: 14,
    color: 'black',
    marginLeft: 0,
    marginRight: 0,
    textAlign: 'center',
    "&:hover": {
        backgroundColor: "transparent",
        boxShadow: 'none'
    }
  },
});

class SectionFooter extends Component {

  constructor(props) {
    super();
    const description = (props.element.description !== undefined && props.element.description.length > 0) ? props.element.description : '';
    const compType = props.element.componentType;

    this.state = {
      description: description.length > 0,
      descriptionText: description,
      title: (((compType === 'section') ? 'Section ' : 'Sub-Section ') + 'Description')
    }
  }

  toggleChecked = (event) => {
    const newValue = event.target.checked;
    this.setState({ description: newValue });
    if (!newValue) {
      this.updateDescription('');
    }
  }

  onDescriptionChange = (event) => {
    const newValue = event.target.value;
    this.updateDescription(newValue);
  }

  updateDescription = (description) => {
    this.props.element.description = description;
    this.setState({ descriptionText: description });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
         { this.state.description ?
        <Grid item style={{ width: 'calc(100% - 240px)', paddingLeft:"40px", paddingRight:"40px", paddingBottom: "10px"}}>
        <TextField fullWidth multiline variant="outlined"
                   className={classes.activeTextField}
                   label={this.state.title}
                   rows={4}
                   value={this.state.descriptionText}
                   onChange={this.onDescriptionChange}/>
        </Grid> :
        null }
        <Toolbar className={classes.root}>
          <FormControlLabel label="Description" labelPlacement="start" className={classes.switchLabel}
          control={
            <Switch size="small" checked={this.state.description} onChange={this.toggleChecked} name="req" color="primary" className={classes.switch} />
          } />
        </Toolbar>
      </Fragment>
    )
  }

}

export default withStyles(styles)(SectionFooter);
