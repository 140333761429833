import React, { Component, Fragment } from 'react';
import { parentDropDownId } from '../../store/form.store';
import { DependentDropdownService } from '../../../services/review-portal-services/dependentDropdown.service';
import { Typography } from '@material-ui/core';

class DependentDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownOption: [],
            selectedValues: [{ name: '', id: '' }],
            disabled: true,
        };
    }

    componentDidMount() {
        this.formObservable = parentDropDownId.subscribe((response) => {
            if (this.props.element.motherId === response.parentId) {
                if (response.parentSelectedValue[0].name === 'Select Option') {
                    this.setState({ disabled: true });
                } else {
                    const params = {
                        dropdownType: 'child',
                        filterColumn: this.props.element.filterColumn,
                        name: this.props.element.name,
                        tableName: this.props.element.tableName,
                        tableColumn: this.props.element.tableColumn || '',
                        mappingTableColumn: this.props.element.mappingTableColumn || '',
                        mappingTableName: this.props.element.mappingTableName || '',
                        parentSelectedId: response.parentSelectedValue[0].id || ''
                    };
                    DependentDropdownService(params).then((value) => {
                        this.setState({ selectedValues: [value[0]] });
                    });
                }
            }
        });
        if (this.props.element.motherId === null) {
            const params = {
                dropdownType: 'parent',
                motherId: this.props.element.motherId,
                filterColumn: this.props.element.filterColumn || '',
                name: this.props.element.name,
                tableColumn: this.props.element.tableColumn || '',
                tableName: this.props.element.tableName,
            };
            DependentDropdownService(params).then((result) => {
                this.setState({ selectedValues: [result[0]] });
            });
        }
    }

    componentWillUnmount() {
        this.formObservable.unsubscribe();
    }

    render() {
        return (
            <Fragment>
                {this.props.element.answer?.map((ans) => 
                    ans?.vendorId && ans?.vendorName ? (
                        <Typography>{ans?.vendorName}</Typography>
                    ) : ans?.regionId && ans?.regionName ? (
                        <>
                            <Typography>{ans?.regionName}</Typography>
                            {(ans?.siteId && ans?.siteName) && (
                                <Typography>{ans?.siteName}</Typography>
                            )}
                            {ans?.turbineId && ans?.turbineName && (
                                <Typography>{ans?.turbineName}</Typography>
                            )}
                        </>
                    ) : null
                )}
            </Fragment>)
    }
}
export default DependentDropdown;