import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles';
import React from 'react'

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
    },
    error: {
        color: '#f44336'
    }
});

function FormErrorView(props) {
    const { classes } = props;
    return (
        <div>
            <Typography className={classes.error}>{props.errorMessage}</Typography>
        </div>
    )
}

export default withStyles(styles)(FormErrorView)
