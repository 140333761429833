import React, { Component, Fragment } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Toolbar, Box, Grid, Collapse, IconButton, Typography, Card, 
	Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormBuilderService from '../services/form-builder.service';
import AppBar from '@material-ui/core/AppBar';
import { FormStore, formState } from '../services/form.store';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import DialogFooter from './form-element/DialogFooter'

const styles = theme => ({
    flexGrow: { flexGrow: 1 },
    ptag: { padding: "0px 12px" },
    formLabel: { padding: "0px 5px", color: "#747a84" },
    appBar: { top: 'auto', bottom: 0 },
    toolBar: {
        backgroundColor: '#242D3E', bottom: 0, top: 'auto',
        position: 'relative', width: '100%'
    },
    resetButton: { border: '1px solid white', color: 'white' },
    formControl: {
        margin: theme.spacing(1), borderRadius: 4, border: '1px solid grey',
        padding: '10px', width: '83%'
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }
});

class UserGroupRestriction extends Component {
	constructor(props) {
        super();
        let formType = "updateForm";
        this.state = {
            restricted: 'Non-Restricted',
            userGroups: [],
            groupIds: [],
            valid: false,
            showAlert: false,
            checkedAll: false,
            formType: formType,
        }
        this.formBuilderService = new FormBuilderService();
    }

    componentDidMount() {
        this.formBuilderService.getUserGroups().then(response => {
            this.setState({ userGroups: response.data });
        });
        if (this.props.updateForm || this.props.copyForm) {
            this.formSubscription = formState.subscribe(response => {
                if (response.groupIds && response.groupIds.length) {
                    this.setState({ groupIds:response.groupIds });
                    this.setState({ restricted:"User Restricted" });  
                }
            });
        }
    }

    positionClick = (e, restricted) => {
        restricted && this.setState({ restricted: restricted });
        if(restricted === "Non-Restricted"){
            this.setState({ groupIds: [] });
            FormStore.sharedInstance().updateFormDetails("groupIds",[]);
        }
    }

    handleChange = (event) => {
        if (event.target.value.includes("all")) {
            if (this.state.checkedAll) {
                this.setState({
                    checkedAll: false
                })
                this.setState({
                    groupIds: []
                })
                FormStore.sharedInstance().getFormDetails("groupIds", []);
                if (this.props.updateForm || this.props.copyForm){
                    FormStore.sharedInstance().updateFormDetails("groupIds", []);
                }
            } else {
                this.setState({
                    checkedAll: true
                })
                this.setState({
                    groupIds: this.state.userGroups.map(item => item.id)
                })
                FormStore.sharedInstance().getFormDetails("groupIds", this.state.userGroups.map(item => item.id));
                if (this.props.updateForm || this.props.copyForm) {
                    FormStore.sharedInstance().updateFormDetails("groupIds", this.state.userGroups.map(item => item.id));
                }
            }
        }
        else {
            this.setState({
                groupIds: event.target.value
            })
            this.setState({
                checkedAll: false
            })
            FormStore.sharedInstance().getFormDetails("groupIds", event.target.value);
            if (this.props.updateForm || this.props.copyForm) {
                FormStore.sharedInstance().updateFormDetails("groupIds", event.target.value);
            }
        }    
     };

    alertClose = () => {
        this.setState({
            showAlert: false,
        })
    };

    /*selectedValues = (value) => {
        let newArr = this.state.userGroups.filter(item => value.includes(item.id)).map(item => item.groupName);
        return newArr.join(", ")
    }*/

    formResetHandler = () => {
        this.setState({
            groupIds: [],
            showAlert: false,
            checkedAll: false,
        });
    }

    formSubmitHandler = () => {
        let isValid = this.props.validate.validateForm();
        this.setState({ valid: isValid })
        this.setState({ showAlert: !isValid })
        FormStore.sharedInstance().checkFormValid(isValid);
        if (isValid === true) {
            const formValues = FormStore.sharedInstance().sendFormDetails();
            this.formBuilderService.createForm(formValues).then((res) => {
                if (res.data) {
                    const formId = res.data;
                    this.props.history.push('/editForm/' + formId);
                    toast.success("Form created successfuly!");
                } else {
                    toast.error("Form could not be created due to errors!")
                }
            });

        }

    }

    closeDialog = () => {
        FormStore.sharedInstance().showDialog(false)
    }

    formChangeHandler = () => {
        let isValid = this.props.validate.validateForm();
        this.setState({ valid: isValid })
        this.setState({ showAlert: !isValid })
        FormStore.sharedInstance().checkFormValid(isValid);
        if (isValid) {
            if (this.props.updateForm) {
                FormStore.sharedInstance().updateFormMetaData(this.state.formType);
                return;
            } else if (this.props.copyForm) {
                this.setState({
                    formType: "copyForm"
                }, () => {
                    FormStore.sharedInstance().updateFormMetaData(this.state.formType);
                });
            }
            else {
                return;
            }
        }
    }

    selectedValues = (value) =>{
        let newArr = this.state.userGroups.filter(item => value.includes(item.id)).map(item=>item.groupName);
        return newArr.join(", ");
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Fragment>
                    <Card square variant="outlined" style={{ width: "96%", margin: "2%" }}>
                        <Toolbar  >
                            <Typography id="title" variant="h6">User Restriction</Typography>
                            <div className={classes.flexGrow} />
                            <ToggleButtonGroup size="small" exclusive aria-label="text alignment" value={this.state.restricted} onChange={this.positionClick}>
                                <ToggleButton value="Non-Restricted" aria-label="Non-Restricted">Non-Restricted</ToggleButton>
                                <ToggleButton value="User Restricted" aria-label="User Restricted">User Restricted</ToggleButton>
                            </ToggleButtonGroup>
                        </Toolbar>
                        <p className={classes.ptag}>If this form is to be restricted with specific group, please select User Restricted. </p>
                        {(this.state.restricted === "User Restricted") ?
                            <Box p={3}>
                                <Grid container spacing={3} >
                                    <Grid item md={6} xs={12} >
                                        <Typography className={classes.formLabel} >SELECT USER GROUP</Typography>
                                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                                            <Select 
                                                className={classes.underline}
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={this.state.groupIds}
                                                onChange={this.handleChange}
                                                input={<Input />}
                                                renderValue={(selected) => this.selectedValues(selected)}>
                                                {(this.state.userGroups.length > 0) ?
                                                    <MenuItem key="all" value="all">
                                                        <Checkbox checked={this.state.checkedAll}/>
                                                        <ListItemText primary="Select All" />
                                                    </MenuItem> : null}
                                                {this.state.userGroups.map((item) => (
                                                    <MenuItem key={item.groupName} value={item.id}>
                                                        <Checkbox checked={this.state.groupIds.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.groupName} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box> : null}
                        <br></br>
                        <Collapse in={this.state.showAlert}>
                            <Alert variant="filled" severity="error"
                                action={<IconButton aria-label="close" color="inherit" size="small" onClick={this.alertClose}>
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>}>
                                You have missed out few validations! Please check the FormDetails page and fill the fields with proper values!
                                    </Alert>
                        </Collapse>
                        {(this.props.updateForm || this.props.copyForm) ?
                            <DialogFooter
                                cancel={this.closeDialog}
                                save={this.formChangeHandler}
                                copyForm={this.props.copyForm}
                            />:
                            <AppBar className={classes.appBar}>
                                <Toolbar className={classes.toolBar} >
                                    <span className={classes.flexGrow} />
                                    <Button id="reset" className={classes.resetButton} variant="outlined" color='inherit' disableElevation onClick={this.formResetHandler}>Reset</Button>
                                    <Button variant="contained" disableElevation onClick={this.formSubmitHandler}>Create Form</Button>
                                </Toolbar>
                            </AppBar>}
                    </Card>
                </Fragment>
            </div>
        );
    }
}

export default withStyles(styles)(UserGroupRestriction);
