import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  activeTextField: {
    backgroundColor: "rgba(255, 255, 255, .75)",
    
  },
  maxLenthStyle: {
    width:"256px",
    
  },
});

class TextArea extends Component {
  constructor() {
    super();
    this.state = {
      maxLengthVal: 2000,
      text: "Anwer label",
    };
  }

  render() {
    return (
      <Fragment>
        {/* <Box display="flex" pt={1} pb={1} pl={1} pr={1} flexDirection="row">
          <Grid container spacing={3}>
            <Box display="flex" flex={11.0}>
              <Grid item style={{ width: "97%" }}>
                <TextField
                  id="question_text"
                  fullWidth
                  required
                  multiline
                  variant="outlined"
                  label="Answer Label"
                  inputProps={{
                    maxlength: this.state.maxLengthVal,
                  }}
                  className={classes.activeTextField}
                />
              </Grid>
            </Box>
            <Box display="flex" justifyContent="flex-end" flex={0.3}>
              <Grid item style={{ width: 252 }}>
                <TextField
                  id="max-length"
                  value={this.state.maxLengthVal}
                  label="Max Length"
                  variant="outlined"
                  color="white"
                  className={classes.activeTextField}
                  className={classes.maxLenthStyle}
                />
              </Grid>
            </Box>
          </Grid>
        </Box> */}
      </Fragment>
    );
  }
}

export default withStyles(styles)(TextArea);
