import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Grid } from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Titlebar from '../../shared/component/Titlebar';
import {
    FormStore,
} from '../../services/form.store';

class FormDialog extends Component {
    constructor(props) {
        super();
        this.state = {
            question: props.element,
            sectionvalue: '',
            subsectionvalue: '',
            questionvalue: '',
            filteredSections: this.getSectionsFromFormStore(),
            filteredSubsections: [],
            filteredQuestions: [],
            position: 'before',
            showError: false,
            isValid: false,
            active: 'before'
        };
    }

    sectionHandler = (e) => {
        this.setState({
            sectionvalue: e.target.value
        });
        this.setState({
            filteredSubsections: FormStore.sharedInstance().getSubSections(e.target.value)
        });
        if (this.props.type === 'copy') {
            this.setState({
                filteredQuestions: FormStore.sharedInstance().getQuestionElements()
            });
        } else {
            let filteredQuestions = FormStore.sharedInstance().getQuestionElements();
            let selectedQuestion = filteredQuestions.filter((question) => question.elementId.includes(this.props.element.elementId));
            let selectedQuestionIndex = filteredQuestions.findIndex(question => question.elementId === this.props.element.elementId);
            if (selectedQuestion.length) {
                filteredQuestions.splice(selectedQuestionIndex, 1);
            }
            this.setState({
                filteredQuestions: filteredQuestions
            });
        }
        this.setState({ subsectionvalue: null });
        this.setState({ questionvalue: null });
    };
    subsectionHandler = (e) => {
        this.setState({ subsectionvalue: e.target.value });
        if (this.props.type === 'copy') {
            this.setState({
                filteredQuestions: FormStore.sharedInstance().getQuestionElements(e.target.value.elementId)
            });
        } else {
            let filteredQuestions = FormStore.sharedInstance().getQuestionElements(e.target.value.elementId);
            if(filteredQuestions !== undefined) {
                let selectedQuestion = filteredQuestions.filter((question) => question.elementId.includes(this.props.element.elementId));
                let selectedQuestionIndex = filteredQuestions.findIndex(question => question.elementId === this.props.element.elementId);
                if (selectedQuestion.length) {
                    filteredQuestions.splice(selectedQuestionIndex, 1);
                }
                this.setState({
                    filteredQuestions: filteredQuestions
                });
            }
        }
        this.setState({ questionvalue: null });
    }

    questionHandler = (e) => {
        this.setState({ questionvalue: e.target.value });
    }

    positionClick = (e, position) => {
        this.setState({ position: position });
    }

    copySubSection = () => {
        FormStore.sharedInstance().copySubSection(this.state.sectionvalue, this.state.subsectionvalue, this.state.questionvalue, this.props.element, this.state.position);
        this.props.onClose();
    }
    copyElement = () => {
        FormStore.sharedInstance().copyQuestion(this.state.sectionvalue, this.state.subsectionvalue, this.state.questionvalue, this.props.element, this.state.position,this.props.type);
        this.props.onClose();
    }

    moveElement = () => {
        FormStore.sharedInstance().moveQuestion(this.state.sectionvalue, this.state.subsectionvalue, this.state.questionvalue, this.props.element, this.state.position,this.props.type);
        this.props.onClose();
    }

    formSubmitHandler = () => {
        const isValid = this.validatePopup();
        if (isValid) {
            if(this.props.type === 'copy'){
                this.copyElement();
            }
            else{
                this.moveElement();
            }
            this.setState({ sectionvalue: null });
            this.setState({ subsectionvalue: null });
            this.setState({ questionvalue: null });
            return;
        }
        this.setState({ showError: true });
    }

    validatePopup = () => {
        var valid = false;
        const questionvalue = this.state.questionvalue;
        if (this.validateRequiredFields(questionvalue)) {
            valid = true;
        }
        this.setState({ isValid: valid });
        return valid;
    }


    validateRequiredFields(value) {
        return ((this.requiredFieldValidator(value)) ? true : false);
    }

    requiredFieldValidator(value) {
        return ((value !== '' && value !== null) ? true : false);
    }

    getSectionsFromFormStore = () => {
        return FormStore.sharedInstance().getSections();
    }

    getHelperTextForQuestion(value) {
        let helperText = "";
        if (this.state.showError) {
            if (!this.requiredFieldValidator(value)) {
                helperText = "Question is Required!";
            }
        }
        return helperText;
    }

    toggle(position) {
        if (this.state.active === position) {
            this.setState({ active: null })
        } else {
            this.setState({ active: position })
        }
    }

    myColor(position) {
        if (this.state.active === position) {
            return "#419BAC";
        }
        return "";
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="dialog-title">Select Position</DialogTitle>
                    <DialogContent>
                        <Box p={3}>
                            <Grid container spacing={3} >
                                <Grid item md={12} xs={12} >
                                    <TextField
                                        select
                                        fullWidth
                                        label="Section"
                                        name="title"
                                        id="Section"
                                        variant="outlined"
                                        value={this.state.sectionvalue}
                                        onChange={this.sectionHandler}>
                                      {(this.state.filteredSections) ?
                                        this.state.filteredSections.map((section) => (
                                            <MenuItem key={section.elementId} value={section.elementId}>
                                                {section.label}
                                            </MenuItem>
                                        )): null}
                                    </TextField>
                                </Grid>
                                <Grid item md={12} xs={12} >
                                    <TextField select fullWidth
                                        label="Sub-Section" name="title"
                                        variant="outlined" id="Sub-Section"
                                        value={this.state.subsectionvalue}
                                        onChange={this.subsectionHandler}
                                        disabled={!this.state.filteredSubsections.length}>
                                        {(this.state.filteredSubsections.length > 0) ?
                                            this.state.filteredSubsections.map((subsection) => (
                                                <MenuItem key={subsection.elementId} value={subsection}>
                                                    {subsection.label}
                                                </MenuItem>
                                            )) : null}
                                    </TextField>
                                </Grid>     
                                {((this.props.element && this.props.element.componentType !== 'subsection')|| this.state.subsectionvalue === ''  || this.state.subsectionvalue === null)?                          
                                    <Grid item md={12} xs={12} >
                                        <TextField select fullWidth
                                            label="Question" name="title"
                                            variant="outlined" id="Question"
                                            value={this.state.questionvalue}
                                            error={this.state.showError && !this.requiredFieldValidator(this.state.questionvalue)}
                                            helperText={this.getHelperTextForQuestion(this.state.questionvalue)}
                                            onChange={this.questionHandler}
                                            disabled={!this.state.filteredQuestions.length}>
                                            {this.state.filteredQuestions.length > 0 ?
                                                this.state.filteredQuestions.map((question) => (
                                                    <MenuItem key={question.elementId} value={question}>
                                                        <span dangerouslySetInnerHTML={{ __html: question.label }}></span>
                                                    </MenuItem>
                                                )) : null}
                                        </TextField>
                                    </Grid> : null}
                                <Grid item md={12} xs={12} >
                                    <Titlebar title="Position" >
                                        <ToggleButtonGroup size="small" selected exclusive value={this.state.active} onChange={this.positionClick} aria-label="text alignment">
                                            <ToggleButton value="before" aria-label="before" style={{ background: this.myColor(0) }} onClick={() => { this.toggle(0) }}>Before </ToggleButton>
                                            <ToggleButton value="after" aria-label="after" style={{ background: this.myColor(1) }} onClick={() => { this.toggle(1) }}> After </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Titlebar>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} variant="outlined" id="cancel" color='inherit'>Cancel</Button>    
                        {(this.props.component === 'subsection') ? <Button onClick={this.copySubSection} variant="contained" id="copy" color="primary">Copy</Button> :
                        <Button onClick={this.formSubmitHandler} variant="contained" id="copy" color="primary"> {(this.props.type === 'copy') ? "Copy" : "Move"}</Button> }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
export default FormDialog;
