export const ADVANCED_SEARCH_CRITERIA = {
    REGION: 'regionId', WINDFARM: 'siteId', TURBINES: 'turbineId',
    FORM_NAME: 'formName', GROUP: 'Group', SUB_GROUP: 'Sub-Group',
    FORM_STATUS: 'formStatus', SUBMITTED_BY: 'userId', FROM_DATE: 'fromDate',
    TO_DATE: 'toDate'
};

export const FORM_STATUS = {
    SUBMITTED: 'Submitted', REOPEN: 'Re-Open', RESUBMITTED: 'Re-Submitted',
    APPROVED: 'Approved', COMPLETED: 'Completed', INPROGRESS: 'In-Progress',
    REJECTED: 'Rejected'
};

export const colors = {
    draftBorderColor: '#C86A00', draftTextColor: '#C86A00',
    draftBgColor: 'rgb(248 223 196)', completedBorderColor: '#001096',
    completedTextColor: '#001096', completedBgColor: 'rgb(186 190 226)',
    approvedBorderColor: '#00865C', approvedTextColor: '#00865C', 
    approvedBgColor: 'rgb(229 242 238)', rejectedBorderColor: '#E10000', 
    rejectedTextColor: '#E10000', rejectedBgColor: 'rgb(252 229 229)', 
    sortMenuItemColor: '#419BAC', sortMenuBgColor: '#eff7f8', 
    sortMenuSelectedBgColor: '#eff7f8 !important'
}

export const labelStyle = {
    draft: {
        backgroundColor: colors.draftBgColor,
        borderColor:colors.draftBorderColor, color: colors.draftTextColor
    },
    completed: {
        backgroundColor: colors.completedBgColor,
        borderColor:colors.completedBorderColor,
        color: colors.completedTextColor
    },
    approved: {
        backgroundColor: colors.approvedBgColor,
        borderColor:colors.approvedBorderColor, color: colors.approvedTextColor
    },
    rejected: {
        backgroundColor: colors.rejectedBgColor,
        borderColor:colors.rejectedBorderColor, color: colors.rejectedTextColor
    },
};

export const BASEURLDETAILS = {
    DEPENDENT_DROPDOWN_URL: 'https://apis-dev.rendigital.apps.ge.com/manage/fw-service/v2/dynamicDropdown/details'
};

export const MY_FORMS = Object.freeze({ SIZE: 20 });
export const FormDeleteMessage = 'Are you sure you want to delete this form?';
export const FormApproveMessage = 'Are you sure you want to approve this form?';
export const debugState = true;
export const ANSWER_VALUE = 'answerValue';
export const ANSWER_DESCRIPTION = 'answerDescription';
export const IMAGE = 'image';
export const TRANSFER_TEXT ="Transfer"

