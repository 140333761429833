import React, { Component, Fragment } from "react";
import {
  Card,
  Box,
  Typography,
  Checkbox,
  IconButton,
  Button,
  InputBase,
  Grid
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { withStyles } from "@material-ui/styles";
import { FormStore } from '../../services/form.store';
import {FormHelperService, ACTIONS, ELEMENT_TYPE } from '../../services/form.helper.service';
import ActiveQuestionLogicOptions from './question/ActiveQuestionLogicOptions';

const styles = (theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {},
  checkboxIcon: {
    borderRadius: 2,
    width: 22,
    height: 22,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#F2F2F2",
  },
  dropBoxIcon: {
    borderRadius: 2,
    width: 22,
    height: 22,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#F2F2F2",
  },
  selectedIcon: {
    backgroundColor: "#419BAC",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#419BAC",
    },
  },
  optionDiv: {
    borderRadius: 2,
    border: "0.8px solid rgba(16,22,26,.2)",
    padding: "5px"
  },
  deleteOptionButton: {
    float: 'right'
  },
  addOptionButton: {
    marginTop: "10px",
    color: "#419BAC",
    marginLeft: "-7px",
  }
});

export class DropdownList extends Component {
  constructor(props) {
    super();
    if (props.elementPopup) {
      let options = [];
      if (props.elementPopup.options && props.elementPopup.options.length > 0) {
        props.elementPopup.options.forEach((item) => {
          options.push({ "value": item.value, id: item.id});
        });
      }
      this.state = {
        confirmButtonPopup: false,
        optionsPopup: options
      };
      this.editPopupOptionLabel = this.editPopupOptionLabel.bind(this);
      this.updatePopupState = this.updatePopupState.bind(this);
    }
    else {
      let options = [];
      if (props.element.options && props.element.options.length > 0) {
        props.element.options.forEach((item) => {
            options.push({"value": item.value, id: item.id });
        });
    }
    this.state = {
      confirmButton: false,
      options: options,
    };
    this.addOption = this.addOption.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.editOptionLabel = this.editOptionLabel.bind(this);
    this.handleChangeDropdrow = this.handleChangeDropdrow.bind(this);
    this.disableDelete = this.disableDelete.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.updateState = this.updateState.bind(this);
    }
  }

  updateState(updatedOptions) {
    let array = [];
    this.setState({ options: updatedOptions }, () => {
      if (this.state.options.length > 0) {
        this.state.options.forEach((item) => {
          array.push(item);
        });
      }
      FormStore.sharedInstance().addOptions(array);
    });
  }

  updatePopupState(updatedOptions) {
    let array = [];
    this.setState({ optionsPopup: updatedOptions }, () => {
      if (this.state.optionsPopup.length > 0) {
        this.state.optionsPopup.forEach((item) => {
          array.push(item);
        });
      }
      FormStore.sharedInstance().addPopupOptions(array);
    });
  }

  addOption() {
    let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.ADD, undefined, undefined, ELEMENT_TYPE.DROPDOWN);
    this.updateState(updatedOptions);
  }

  deleteOption(index) {
    let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.DELETE, index);
    this.updateState(updatedOptions);
  }

  editOptionLabel(index, value) {
    let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.EDIT, index, value, ELEMENT_TYPE.DROPDOWN);
    this.updateState(updatedOptions);
  }

  editPopupOptionLabel(index, value) {
    let updatedOptions = FormHelperService.performAction(this.state.optionsPopup, ACTIONS.EDIT, index, value, ELEMENT_TYPE.DROPDOWN);
    this.updatePopupState(updatedOptions);
  }

  handleDropdownChange(index, value) {
    let arr = Array.from(this.state.options);
    arr[index].value = value;
    this.setState({ options: arr });
    this.updateState(arr);
  }

  handleChangeDropdrow(event) {
    this.setState({ value: event.target.value });
  }

  disableDelete() {
    return (this.state.options.length <= 1) ? true : false;
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
      {this.props.element ?
      <Grid container spacing={3}>
        <Grid item style={{ width: 'calc(100% - 290px)' }} >
        <Typography component="div">
          <Card square >
            <Box alignItems="center">
              {this.state.options.map((dataItem, index) => (
                <div
                  key={index}
                  id="dropdownValue"
                  className={classes.optionDiv}>
                    {this.state.confirmButton ?
                    <Checkbox checked={dataItem.options} /> : null}
                    <InputBase
                      value={dataItem.value}
                      id="optionLabel"
                      inputProps={{ "aria-label": "naked" }}
                      autoComplete="off"
                      style={{ width: "80%", padding: "5px"}}
                      onChange={(event) => {
                        this.editOptionLabel(index, event.target.value);
                      }}
                      >
                    </InputBase>
                  <IconButton
                    id="deleteButton"
                    className={classes.deleteOptionButton}
                    disabled={this.disableDelete()}
                    onClick={() => {
                      this.deleteOption(index);
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </div>
              ))}
            </Box>
          </Card>
          <Button
            id="addOptionButton"
            className={classes.addOptionButton}
            variant="text"
            onClick={this.addOption}
          >
            + ADD OPTION
          </Button>
        </Typography>
        </Grid>
        {this.props.element.properties && this.props.element.properties.conditional ?
          <Grid item style={{ width: '290px' }} >
            <Card className={classes.answerLogicMenu}>
              <ActiveQuestionLogicOptions options={this.state.options}/>
            </Card>
          </Grid>
          : null}
        </Grid> : null }

        {this.props.elementPopup ?
          <Grid container spacing={3}>
            <Grid item></Grid>
            <Typography component="div">
              <Card square>
                <Box alignItems="center">
                  {this.state.optionsPopup.map((dataItem, index) => (
                    <div key={index} id="dropdownValuePopup" className={classes.optionDiv}>
                     {this.state.confirmButton ?
                        <Checkbox checked={dataItem.options} /> : null}
                        <InputBase
                          value={dataItem.value}
                          id="optionLabel"
                          inputProps={{ "aria-label": "naked" }}
                          autoComplete="off"
                          style={{ width: "80%", padding: "5px" }}
                          onChange={(event) => {
                            this.editPopupOptionLabel(index, event.target.value);
                          }}>
                        </InputBase>
                    </div>))}
                </Box>
              </Card>
            </Typography>
          </Grid> : null}
      </Fragment>
    );
  }
}

export default withStyles(styles)(DropdownList);