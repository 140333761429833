import { Avatar, Typography } from '@material-ui/core';
import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { InstructionHelpService } from '../../../services/review-portal-services/instructionHelp.service';
import ImagePopup from './ImagePopup';

const InstructionHelp = (props) => {
    const [imageUrl, setImageUrl] = useState('');
    const[image,setImage]=useState('');
    const[imageName,setImageName]=useState('');
    const [imageOpen, setImageOpen] = React.useState(false);

    useEffect(() => {
        if (props.element) {
            const image = props.element?.imagesData;
            if (image) {
                setImageUrl(image);
            }
        }
        }, [props.element]);


    const onClickAvatar = (image) => {
        setImage(image.imageUrl);
        setImageOpen(true);
        setImageName(image.imageName);
    }

    const onClose = () => {
        setImageOpen(false);
    }

    return (
        <Fragment>
            <Typography>{props.element.properties.description} </Typography>
            <div>{props.element.properties.hasHelpImage === true && imageUrl.length ? imageUrl.map((image) => ( (
                <Avatar alt="instruction" src={image.imageUrl} variant="square" style={{ height: '75px', width: '100px' }}
                    onClick={()=>onClickAvatar(image)} />) )): null}
            </div>
            <ImagePopup open={imageOpen} onClose={onClose} imageUrl={image} title={imageName}></ImagePopup>
        </Fragment>
    );
};
export default InstructionHelp;
