import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import overrides from './overrides';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#67afbc',
      main: '#419BAC',
      dark: '#2d6c78',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#4f5764',
      main: '#242D3E',
      dark: '#191f2b',
      contrastText: '#ffffff',
    },
    // Used by `getContrastText()` to maximize the contrast between the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately two indexes within its tonal palette. 
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides,
  typography,
  spacing: 4,
});

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  gridCard: {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      border: 'none',
    }
  },
  new: {
    fontSize: '.6rem',
    padding: '1px 3px',
    lineHeight: '12px',
    marginLeft: '8px',
    minWidth: 'auto',
    marginTop: '-3px',
    border: '1px solid #C935AD',
    color: '#C935AD',
    verticalAlign: 'middle',
    display: 'inline-flex',
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: '1px',
  },
  actionBar: {
    backgroundColor: '#FCFCFC',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  flexGrow: {
    flexGrow: 1,
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

export default theme;
 