import React, { Component, Fragment } from 'react';
import { makeStyles, Box, Menu, Card, Typography, Collapse, Button, IconButton, 
	Divider, Icon } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import hiddenBase from '../../shared/imgs/hiddenBack.png';
import { withStyles } from '@material-ui/styles';
import FormElementsList from '../FormElementsList';
import Components from './CompMap';
import { FormStore, currentSubsectionState, activeElementState,
  translateSubsectionState, activeTranslateElementState } from '../../services/form.store';
import ToolPallete from './ToolPallete';
import FormDialog from './SubSectionPopup';
import ActiveSection from './question/ActiveSection';
import ConfirmationDialog from '../form-element/ConfirmationDialog';
import { SubsectionDeleteMsg } from '../../utility/Constant';

const styles = theme => ({
  root: { padding: theme.spacing(3), paddingBottom: '0px' },
  flexGrow: { flexGrow: 1 },
  fixedFooter: { top: 'auto', bottom: 0, width: 'calc(100% - 300px)' },
  sectionTitle: { padding: theme.spacing(1, 3) },
  sectionBody: {
    padding: theme.spacing(2), borderTop: '1px solid rgba(0, 0, 0, .08)'
  },
  hiddenQ: { backgroundImage: `url(${hiddenBase})` },
  expand: {
    transform: 'rotate(0deg)', marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: { transform: 'rotate(180deg)' }
});

const questionStyles = makeStyles(theme => ({
  root: {
    borderTop: '1px solid rgb(91, 195, 115)',
    backgroundColor: 'rgba(91,195,115,0.1)'
  },
  flexGrow: {
    flexGrow: 1,
  },
  switchLabel: {
    marginLeft: 0,
    marginRight: 20,
  },
  switch: {
    marginLeft: 3,
  },
  questionButtonStyle: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: 14
  },
  dividerStyle: {
    backgroundColor: 'rgb(190, 190, 190)', width: 1, height: 40
  },
  dropdownArrowStyle: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: 14,
    color: 'black',
    marginLeft: 0,
    marginRight: 0,
    textAlign: 'center',
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: 'none'
    }
  },
}));

class SubSection extends Component {

  constructor(props) {
    let componentType = 'subsection';
    super();
    this.state = {
      expanded: false,
      showPopup: false,
      active: false,
      anchorEl:null,
      component : componentType,
      isEnabled:false,
      expandedSubsection: false,
      activeSubsection: false,
      isEnabledSubsection: false
    }
  }

  componentDidMount() {
    if(this.props.elementPopup){
      this.sectionStateSubscription = translateSubsectionState.subscribe(newState => {
        this.setState({ expandedSubsection: newState === this.props.elementPopup.elementId });
      });
      this.activeStateSubscription = activeTranslateElementState.subscribe(newState => {
        this.setState({ activeSubsection : newState === this.props.elementPopup.elementId })
      });
    }
    else{
      this.sectionStateSubscription = currentSubsectionState.subscribe(newState => {
        this.setState({ expanded: newState === this.props.element.elementId });
      });
      this.activeStateSubscription = activeElementState.subscribe(newState => {
        this.setState({ active: newState === this.props.element.elementId });
      });
    }
  }

  componentWillUnmount() {
    this.sectionStateSubscription.unsubscribe();
    this.activeStateSubscription.unsubscribe();
  }

  handleExpandClick = (event) => {
    event.preventDefault();
    FormStore.sharedInstance().openSubSection(this.props.element.elementId);
  };

  handleSubsectionExpandClick = (event) => {
    event.preventDefault();
    console.log("event",event);
    FormStore.sharedInstance().openTranslateSubsection(this.props.elementPopup.elementId);
  }

  copySubSection = (value, event) => {
    if (value === 'TYPE_COPY') {
      this.setState({
        showPopup: true,
      })
    }
    else if(value === 'TYPE_DELETE'){
      this.setState({ anchorEl: event.currentTarget})
    }
    else{
      this.setState({
        showPopup: false,
      })
    }
  }

  handleClose = () => {
    this.setState({
      showPopup: false,
    })
  };

  onTitleClick = (event) => {
    event.preventDefault();
    this.setState({ isEnabled : true });
    FormStore.sharedInstance().makeActive(this.props.element.elementId);
  }

  onSubsectionTitleClick = (event) => {
    event.preventDefault();
    this.setState({ isEnabledSubsection : true});
    FormStore.sharedInstance().makeActiveTranslateSection(this.props.elementPopup.elementId);
  }

  handleDeleteClose = () => {
    this.setState({ anchorEl: null})
  };
  
  deleteSubsection = () => {
    FormStore.sharedInstance().deleteSubsection(this.props.element);
    this.handleDeleteClose();
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {this.props.element ? 
        <Typography component="div" className={classes.root}>
        <Card square>
        {!this.state.isEnabled || !this.state.active ?
            <Box display="flex" alignItems="center"
            className={classes.sectionTitle}
            style={{ color: (this.props.element.label.length <= 0) ? 'red' : 'black' }}>
              <Typography variant="h6" onClick={this.onTitleClick}>
              {(this.props.element.label.length) > 0 ? this.props.element.label : "Sub-Section name is required"}
              </Typography>
              <div className={classes.flexGrow} />
              {this.state.expanded ? <ToolPallete type={ToolPallete.PalleteType.SUBSECTION_TYPE} id="copyButton"  onClick={this.copySubSection} /> : null}
              <ConfirmationDialog  
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleDeleteClose}
                  title="Delete Subsection"
                  description={SubsectionDeleteMsg}
                  onDelete={this.deleteSubsection} 
                  anchorEl={this.state.anchorEl}
              />
              <IconButton edge="end" id="iconButton"
                onClick={this.handleExpandClick}>
                <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.expanded })} />
              </IconButton>
            </Box> : 
            <ActiveSection element={this.props.element}/>}
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
              <RenderChildren section={this.props.element} />
            </Collapse>
          </Card> 
          {this.state.expanded ? <AddQuestionBlock sectionid={this.props.element.elementId} /> : null}
          {(this.state.showPopup) ? <FormDialog
            id="ringtone-menu"
            keepMounted element={this.props.element}
            open={this.state.showPopup} type={"copy"}
            onClose={this.handleClose} component={this.state.component}
          /> : null}
        </Typography> : null }

        {this.props.elementPopup ?       
        <Typography component="div" className={classes.root}>
          {!this.state.isEnabledSubsection || !this.state.activeSubsection ? 
            <Box display="flex" alignItems="center"
              className={classes.sectionTitle}
              style={{ color: (this.props.elementPopup.label.length <= 0) ? 'red' : 'black' }}>
              <Typography onClick={this.onSubsectionTitleClick}>
                {(this.props.elementPopup.label.length) > 0 ? this.props.elementPopup.label : null}
              </Typography>
              <div className={classes.flexGrow} />
              <IconButton edge="end" id="iconButton"
                onClick={this.handleSubsectionExpandClick}>
                <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.expandedSubsection })} />
              </IconButton>
            </Box> : <ActiveSection elementPopup={this.props.elementPopup} />}
          <Collapse in={this.state.expandedSubsection} timeout="auto" unmountOnExit>
            <RenderSubsectionChildren elementPopup={this.props.elementPopup} classes={this.props}/>
          </Collapse>
        </Typography> : null}
      </Fragment>
    )
  }
}

function RenderChildren(props) {
  if (props.section !== undefined) {
    const TagName = Components['question'];
    const items = props.section.children.map((item, key) =>
      <TagName element={item} subsectionId={props.section.elementId} key={item.id}/>
    );
    return items;
  }

  return (<div> Empty Children </div>);
}

function RenderSubsectionChildren(props) {
  if (props.elementPopup !== undefined) {
    const TagName = Components['question'];
    const items = props.elementPopup.children.map((item, key) =>
      <TagName elementPopup={item}/>
    );
    return items;
  }

  return (<div> Empty Children </div>);
}

const AddQuestionBlock = (props) => {
  const classes = questionStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    setAnchorEl(null);
    const newType = value.component;
    if (newType) {
      addQuestionOfType(newType);
      return;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddQuestionClick = () => {
    addQuestionOfType('text');
  }

  const addQuestionOfType = (type) => {
    FormStore.sharedInstance().addElement(type, props.sectionid);
  }

  return (
    <Box display="flex"
      borderTop={0} pt={1} pb={1} pr={1} bgcolor="#fcfcfc">
      <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end" >
        <Box display="flex" border={1} borderColor="#191919" alignSelf="center">
          <Button
            id="add_question_btn"
            className={classes.questionButtonStyle}
            startIcon={<Icon>add</Icon>}
            onClick={handleAddQuestionClick}>
            Question
                </Button>
          <Divider flex={1} orientation="vertical" className={classes.dividerStyle} />
          <Button
            className={classes.dropdownArrowStyle}
            variant="contained"
            endIcon={<Icon>arrow_drop_down</Icon>}
            onClick={handleClickListItem} />
        </Box>
        <Menu keepMounted id="lock-menu"
          className={classes.categoryNav}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <FormElementsList onItemClick={handleMenuItemClick} />
        </Menu>
      </Box>
    </Box>
  )
}

export default withStyles(styles)(SubSection);
