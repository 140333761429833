import React, { Component, Fragment } from 'react';
import { extractBarcodeValue } from '../../store/form.answer.extract';
import { Avatar, Box, Card, FormHelperText, Grid, TextField } from '@material-ui/core';
import ImagePopup from './ImagePopup';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { currentSectionState, openSectionState } from '../../store/form.store';
import { textAnswer } from '../../store/form.answer.fill';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
    },
});

class BarcodeScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            imageOpen: false,
            imageValue: [],
            imageUrl: '',
            imageName: '',
            editable: false
        };
    }
    componentDidMount() {
        let expanded = ''
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => {expanded = sectionId});
        this.currentSectionSubscription = currentSectionState.subscribe(section => {
            this.setState({ editable: section === expanded})
        });
        this.onLoadPage(this.props);
        if (this.props.element.properties.allowFileUpload) {
            this.onLoadImage(this.props);
        }
    }

    onLoadPage(props) {
        if (props.element) {
            let answer = props.element.answer;
            let userResponse = extractBarcodeValue(answer);
            if (userResponse) {
                this.setState({
                    value: userResponse
                });
            }
        }
    }

    onLoadImage(props) {
        if (props.element) {
            const image = props.element.answer.image;
            if (image) {
                this.setState({ imageValue: image });
            }
        }
    }

    onClickAvatar = (image) => {
        this.setState({
            imageOpen: true,
            imageUrl: image.imageUrl,
            imageName: image.imageName
        });
    }

    onClose = () => {
        this.setState({ imageOpen: false });
    }

    onChange = (event) => {
        this.setState({ value: event.target.value})
        let answerNode = textAnswer(event.target.value);
        if (this.props.onChange !== undefined) {
            this.props.onChange(answerNode, this.props.element);
        }
    }

    render() {
        const { classes } = this.props;
        return (<Fragment>
            {this.state.editable ? (
                <>
                    <TextField
                        id="scannerTextField" fullWidth required variant="outlined"
                        error={this.props.hasError}
                        value={this.state.value}
                        onChange={this.onChange}
                    />
                {
                this.props.element.properties.answerLabel && 
                    <FormHelperText>{this.props.element.properties.answerLabel}</FormHelperText>
                }
                </>
                ) : (<Typography>{this.state.value}</Typography>)
            }
            {(this.props.element.properties.allowFileUpload && this.state.imageValue.length) ? this.state.imageValue.map((image) => (
                <Card className={classes.root} >
                    <Box>
                        <Grid container spacing={1}>
                            <Avatar alt={image.imageName} src={image.imageUrl} variant="square" style={{ height: '75px', width: '100px', cursor: 'pointer' }}
                                onClick={() => this.onClickAvatar(image)} />
                        </Grid>
                    </Box>
                </Card>
            )) : null}
            <ImagePopup open={this.state.imageOpen} onClose={this.onClose} imageUrl={this.state.imageUrl} title={this.state.imageName}></ImagePopup>
        </Fragment>);
    }
}
export default withStyles(styles)(BarcodeScanner);