import { ATTRIBUTES } from '../services/form.helper.service';

export class FormInputTypesValidationService {

    static validateCommonAttributes(attribute, value) {
        let error = '';
        if (attribute === ATTRIBUTES.ANSWER_HINT) {
            if (value === '') {
                error = 'Help Text is required!';
            }
        }
        else if (attribute === ATTRIBUTES.MAX_LENGTH) {
            if (value === '') {
                error = 'Max Length is required!';
            }
            else if (value <= 0) {
                error = 'Max Length should be an integer greater than 0!';
            }
            else if (value % 1 !== 0) {
                error = 'Max Length should not be a decimal!';
            }
        }
        else if (attribute === ATTRIBUTES.HOURS_WORKED_VALUE) {
            if (value === '') {
                error = 'Hours Worked is required!';
            }
            else if (value <= 0) {
                error = 'Hours Worked should be greater than 0!';
            }
        }
        else if (attribute === ATTRIBUTES.INSTRUCTION_HELP_TEXT) {
            if (value === '') {
                error = 'Instruction/Help is required!';
            }
            else if (value.length >= 5000) {
                error = 'More than 5000 characters are not allowed for Instruction/Help text!';
            }
        }
        else if (attribute === ATTRIBUTES.QUESTION_LABEL){
            if (value === '') {
                error = 'Question Label is Required!';
            }
            else if (value.length >= 1000) {
                error = 'More than 1000 characters are not allowed for Question Label!';
            }
        }
        return error;
    }

}