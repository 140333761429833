import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Components from './CompMap';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import Titlebar from '../../shared/component/Titlebar';
import { withStyles } from '@material-ui/styles';
import { FormStore } from '../../services/form.store';

const styles = theme => ({
  dialogPaper: {
    height: "700px"
  },
})

class FormPopup extends Component {
  constructor(props) {
    super();
    this.state = {
      formTabName: props.selectedLanguage,
    }
  }

  handleSelect = (event) => {
    this.setState({
      formTabName: event
    })
  }

  saveTranslateForm = () =>{
    FormStore.sharedInstance().saveTranslateForm(); 
    this.props.onClose();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onClose} fullWidth='true' classes={{ paper: classes.dialogPaper }}
          aria-labelledby="form-dialog-title">
          <DialogContent>
            <Tabs defaultTab={this.state.formTabName} horizontal="true" style={{ background: '#fcfcfc' }} onChange={(event) => this.handleSelect(event)}>
              <TabList>
                <Titlebar title={this.props.form.title} />
                <Tab tabFor={this.props.selectedLanguage} style={{ textTransform: 'uppercase' }}>{this.props.selectedLanguage}</Tab>
                <Tab tabFor={this.props.defaultTabName} style={{ textTransform: 'uppercase' }}>{this.props.defaultTabName}</Tab>
              </TabList>

              <TabPanel tabId={this.props.selectedLanguage} style={{ width: '100%' }} >
                <RenderListInternal id="renderListInternal" translatedForm={this.props.translatedForm} />
              </TabPanel>

              <TabPanel tabId={this.props.defaultTabName} style={{ width: '100%' }}>
                <RenderListInternalPopup id="renderListInternalPopup" form={this.props.form} />
              </TabPanel>
            </Tabs>
          </DialogContent>
          {this.state.formTabName === this.props.selectedLanguage ?
            <DialogActions>
              <Button onClick={this.props.onClose} variant="outlined" color='inherit' id="cancel">Cancel</Button>
              <Button onClick={this.saveTranslateForm} variant="contained">SAVE &amp; VIEW</Button>
            </DialogActions> : null}
        </Dialog>
      </div>
    );
  }
}

function RenderListInternal(props) {
  if (props.translatedForm !== undefined) {
    const TagName = Components['section'];
    const items = props.translatedForm.children.map((item, key) =>
      <TagName sectionPopup={item} />
    );
    return items;
  }
  return (<div> Empty Form </div>);
}

function RenderListInternalPopup(props) {
  if (props.form !== undefined) {
    const TagName = Components['section'];
    const items = props.form.children.map((item, key) =>
      <TagName sectionPopup={item} />
    );
    return items;
  }
  return (<div> Empty Form </div>);
}

export default withStyles(styles, { withTheme: true })(FormPopup);