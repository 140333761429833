import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import { withStyles } from "@material-ui/styles";
import Menu from '@material-ui/core/Menu';
import Link from '@material-ui/core/Link';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpward';
import { SORT_MENU_OPTIONS } from '../services/form.helper.service';

const styles = theme => ({
    menuItem: {
        width: "200px",
        "&:hover": {
            color: "#419BAC",
            backgroundColor: "#eff7f8"

        }
    },
    selected: {
        color: "#419BAC",
        backgroundColor: "#eff7f8 !important"
    },
    menuItemText: {
        "&:hover": {
            color: "#419BAC"
        }
    }
});

class Sort extends React.Component {

    constructor(props) {
        super();
        this.sortMenuItems = SORT_MENU_OPTIONS;
        this.state = {
            anchorEl: null,
            sortParameter: props.sortParam
        }
        this.onMenuItemClicked = this.onMenuItemClicked.bind(this);
        this.clearSorting = this.clearSorting.bind(this);
    }

    handleClose = (event) => {
        this.setState({ anchorEl: null });
    }

    onMenuItemClicked(parameter) {
        if (this.state.sortParameter.label !== parameter.label) { // different from current selection
            this.props.onMenuClicked({ label: parameter.label, value: parameter.value, order: "desc" });
        }
        else { // toggling
            this.props.onMenuClicked({ label: parameter.label, value: parameter.value, order: this.state.sortParameter.order === "asc" ? "desc" : "asc" });
        }
    }

    clearSorting() {
        this.props.onMenuClicked({ label: "", value: "", order: "" });
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.sortParameter !== props.sortParam) {
            return {
                sortParameter: props.sortParam
            }
        }
        return null
    }

    render() {
        const { classes } = this.props;
        const {label} = this.state.sortParameter;
        return (
            <div>
                <IconButton id="sortButton" color="inherit" onClick={(event) => { this.setState({ anchorEl: event.currentTarget }); }}><SortIcon /></IconButton>
                <Menu elevation={3} getContentAnchorEl={null} keepMounted open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 200, left: 1520 }}
                >
                    <ListSubheader>
                        <Typography variant="overline" component="span" >
                            SORT BY
                        </Typography>
                        <Typography variant="caption" component="span" style={{ marginTop: "15px", float: 'right' }}>
                            <Link
                                id="clear"
                                component="button"
                                variant="body2"
                                style={{ color: "#FF0000" }}
                                onClick={label.length && this.clearSorting}
                            >
                                Clear
                           </Link>
                        </Typography>
                    </ListSubheader>
                    <Divider light className={classes.sideNavDivider} />
                    {this.sortMenuItems.map((parameter, index) => (
                        <MenuItem id={parameter.label} key={index} className={classes.menuItem} value={parameter.value} classes={{ selected: classes.selected }} selected={this.state.sortParameter.label === parameter.label} onClick={() => { this.onMenuItemClicked(parameter) }}>
                            <ListItemText className={classes.menuItemText} primary={parameter.label} />
                            {this.state.sortParameter.label === parameter.label && this.state.sortParameter.order === "desc" &&
                                <ArrowDownwardOutlinedIcon />}
                            {this.state.sortParameter.label === parameter.label && this.state.sortParameter.order === "asc" &&
                                <ArrowUpwardOutlinedIcon />}
                        </MenuItem>
                    ))}
                </Menu >
            </div>
        );
    }
}

export default withStyles(styles)(Sort);
