import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import Titlebar from '../shared/component/Titlebar'
import { Button, IconButton, Typography } from '@material-ui/core';
import CreateUserGroup from '../components/form-element/CreateUserGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormBuilderService from '../services/form-builder.service';
import ToolPallete from '../components/form-element/ToolPallete';
import { Pagination } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Loader } from '../components/Loader';
import EditUserGroup from '../components/form-element/EditUserGroup';
import AlertDialog from '../components/form-element/AlertDialog';
import { ALERT_DIALOGS } from '../services/form.helper.service';
import { toast } from 'react-toastify';

const styles = theme => ({
  root: {
    padding: '12px'
  },
  flexGrow: {
    flexGrow: 1
  },
  underline: {
    '&:before': {
      borderBottom: "1px solid #419BAC !important"
    }
  },
  pagination: {
    paddingTop: 15,
    justifyContent: "center",
    display:"flex"
  },
  alignCenter: {
    textAlign:"center"
  },
  groupName: {
    color: "#419BAC",
    cursor: "pointer",
    '&:hover': {
      textDecoration: "underline"
    }
  }
})

class UserGroup extends Component {
  constructor() {
    super();
    this.state = {
      showDialog: false,
      editDialog: false,
      userGroup: {},
      userGroups: [],
      filteredData: [],
      formsList: [],
      usersList: [],
      isLoading: false,
      rowsPerPage: 10,
      page: 1,
      showDeleteConfirmDialog: false
    }
    this.formBuilderService = new FormBuilderService();
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.formBuilderService.getUserGroups().then(response => {
      this.setState({
          userGroups: response.data,
          filteredData: response.data
        })
    })
    this.formBuilderService.getAllPublishedFormNames().then(res => {
      this.setState({ formsList: res.data })
    });
    this.formBuilderService.getUserDetails().then(res => {
      this.setState({ usersList: res.data })
    })
    this.setState({ isLoading: false })
  }

  handleCreate = () => {
    this.setState({ showDialog: true })
  }

  handleEdit = (group) => {
    this.setState({ userGroup: group, editDialog: true })
  }

  handleClose = () => {
    this.setState({ showDialog: false, editDialog: false, showDeleteConfirmDialog: false })
  }

  handleChange = (event, value) => {
    this.setState({ page: value });
  }

  handleSearch = (e) => {
    let searchValue = e.target.value;
    this.setState({
      filteredData: this.state.userGroups.filter(
        item =>
          (item.groupName.toLowerCase().includes(searchValue.toLowerCase()))
      )
    });
  }

  handleUpdate = () => {
    this.setState({ 
      showDialog: false,
      editDialog: false,
      isLoading: true
    })
    this.formBuilderService.getUserGroups().then(response => {
      this.setState({ 
        isLoading: false,
        userGroups: response.data,
        filteredData: response.data 
      })
    })
  }

  handleDelete = (group) => {
    this.setState({ userGroup: group, showDeleteConfirmDialog: true }); 
  }

  handleConfirmDelete = () => {
    this.setState({ isLoading: true })
    this.formBuilderService.deleteUserGroup(this.state.userGroup.id).then(res => {
      let data = this.state.filteredData.filter((group) => group.id !== this.state.userGroup.id);
      this.setState({
        showDeleteConfirmDialog: false,
        userGroups: data,
        filteredData: data,
        isLoading: false
      })
      if(res.data){
        toast.success(this.state.userGroup.groupName + " group deleted successfuly!");
      } else {
        toast.error(this.state.userGroup.groupName + " group could not be deleted due to errors!")
      }
    })
  }

  render() {
    const { classes } = this.props;
    const { filteredData, page, rowsPerPage, isLoading, showDialog, editDialog, userGroup, usersList, formsList } = this.state;
    filteredData.sort(function(a, b) { 
      return b.id - a.id;
    });
    return (
      <Fragment>
        <Titlebar title={"User Group"}>
          <Button variant="contained" disableElevation onClick={this.handleCreate}>New Group</Button>
        </Titlebar>
        <Paper className={classes.root} component={"div"} elevation="0">
          <TextField
            id="search"
            name="search"
            label="Search Group"
            margin="normal"
            onChange={this.handleSearch}
            InputProps={{
              endAdornment: <InputAdornment position="end">   <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <SearchIcon color="primary" />
              </IconButton></InputAdornment>,
              classes: {
                underline: classes.underline
              }
            }}
          />
          <TableContainer component={"div"}>
            <Table className={classes.table} aria-label="simple table" size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    Group Name
                  </TableCell>
                  <TableCell align="right">No. of Users </TableCell>
                  <TableCell align="right">No. of Forms</TableCell>
                  <TableCell align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.slice((page - 1) * rowsPerPage, ((page - 1) * rowsPerPage) + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.groupName}>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.groupName} onClick={() => {this.handleEdit(row)}}>{row.groupName}</Typography>
                      </TableCell>
                      <TableCell align="right">{(row.users) ? row.users.length : null}</TableCell>
                      <TableCell align="right">{(row.forms) ? row.forms.length : null}</TableCell>
                      <TableCell align="right">
                        <ToolPallete type={ToolPallete.PalleteType.USERGROUP_TYPE} onClick={() => {this.handleDelete(row)}} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {Math.ceil(filteredData.length / rowsPerPage) > 1 ?
          <Pagination className={classes.pagination} count={Math.ceil(filteredData.length / rowsPerPage)} page={page} onChange={this.handleChange} />
          : null}
          <div className={classes.alignCenter}>
            <Loader loading={isLoading} />
          </div>
          {!filteredData.length && !isLoading ?
          <div id="noDataMessage" className={classes.alignCenter}>No Groups are available</div>:null}
        </Paper>
        {showDialog ? <CreateUserGroup open={showDialog} handleClose={this.handleClose} handleUpdate={this.handleUpdate} usersList={usersList} formsList={formsList} /> : null}
        {editDialog ? <EditUserGroup id={userGroup.id} open={editDialog} handleClose={this.handleClose} handleUpdate={this.handleUpdate} usersList={usersList} formsList={formsList} /> : null}
        {(this.state.showDeleteConfirmDialog) ?
            <AlertDialog id='alertDialog'
                open={this.state.showDeleteConfirmDialog}
                onClose={this.handleClose}
                handleOk={this.handleConfirmDelete}
                dialogTitle={ALERT_DIALOGS.DELETE_FORM_TITLE}
                dialogContent={ALERT_DIALOGS.DELETE_GROUP_CONTENT}
                cancelButton={ALERT_DIALOGS.DELETE_FORM_CANCEL}
                confirmButton={ALERT_DIALOGS.DELETE_FORM_CONFIRM}
            /> : null}
      </Fragment>
    )
  }
}

export default withStyles(styles, { withTheme: true })(UserGroup);