import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  titleBar: {
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  flexGrow: {
    flexGrow: 1,
  }
}));

const Titlebar = props => {
  const classes = useStyles();
  const { 
    title = 'Create New Form', 
    children, editBtn,
  } = props;

  return (
    <div className={classes.titleBar}>
      <Toolbar>
    <Typography variant="h6">{title} {editBtn}</Typography>
        <div className={classes.flexGrow} />
        {children}
      </Toolbar>
    </div>
  )

}

Titlebar.propTypes = {
  title: PropTypes.string.isRequired,
  editBtn: PropTypes.element,
  children: PropTypes.element,
};

export default Titlebar;