import React from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  Box,
  IconButton
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import TOCIcon from "@material-ui/icons/TocOutlined";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import SettingIcon from "@material-ui/icons/TuneOutlined";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import ListIcon from "@material-ui/icons/List";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatStrikethroughIcon from "@material-ui/icons/FormatStrikethrough";
import PaletteIcon from "@material-ui/icons/Palette";
import BrushIcon from "@material-ui/icons/Brush";

export const PalleteType = {
  SECTION_TYPE: "SECTION_TYPE",
  SUBSECTION_TYPE: "SUBSECTION_TYPE",
  QUESTION_TYPE: "QUESTION_TYPE",
  FORM_TYPE: "FORM_TYPE",
  IMAGE_TYPE: "IMAGE_TYPE",
};

const SECTION_TYPE_TOOL = [
  { id: "TYPE_COPY", label: "", labelPosition: "start" },
  { id: "TYPE_DELETE", label: "", labelPosition: "start" },
];
const SUBSECTION_TYPE_TOOL = [
  { id: "TYPE_COPY", label: "", labelPosition: "start" },
  { id: "TYPE_DELETE", label: "", labelPosition: "start" },
];
const QUESTION_TYPE_TOOL = [
  { id: "TYPE_MOVE", label: "", labelPosition: "start" },
  { id: "TYPE_COPY", label: "", labelPosition: "start" },
  { id: "TYPE_DELETE", label: "", labelPosition: "start" },
];
const FORM_TYPE_TOOL = [
  {
    id: "TYPE_SETTING",
    label: "",
    iconName: "setting",
    labelPosition: "start",
  },
  { id: "TYPE_TOC", label: "", iconName: "toc", labelPosition: "start" },
];
const IMAGE_TYPE_TOOL = [
  { id: "TYPE_BOLD", label: "", labelPosition: "start" },
  { id: "TYPE_ITALIC", label: "", labelPosition: "start" },
  { id: "TYPE_THROUGHT", label: "", labelPosition: "start" },
  { id: "TYPE_LINK", label: "", labelPosition: "start" },
  { id: "TYPE_ALIGNL", label: "", labelPosition: "start" },
  { id: "TYPE_ALIGNC", label: "", labelPosition: "start" },
  { id: "TYPE_ALIGNR", label: "", labelPosition: "start" },
  { id: "TYPE_LIST", label: "", labelPosition: "start" },
  { id: "TYPE_LISTNUMBER", label: "", labelPosition: "start" },
  { id: "TYPE_PALETTE", label: "", labelPosition: "start" },
  { id: "TYPE_BRUSH", label: "", labelPosition: "start" },
];
const USERGROUP_TYPE = [
  { id: "TYPE_DELETE", label: "", labelPosition: "start" },
];

const getTypeConfig = (palleteType) => {
  let configType = SECTION_TYPE_TOOL;

  switch (palleteType) {
    case PalleteType.SECTION_TYPE:
      configType = SECTION_TYPE_TOOL;
      break;

    case PalleteType.SUBSECTION_TYPE:
      configType = SUBSECTION_TYPE_TOOL;
      break;

    case PalleteType.QUESTION_TYPE:
      configType = QUESTION_TYPE_TOOL;
      break;

    case PalleteType.FORM_TYPE:
      configType = FORM_TYPE_TOOL;
      break;

    case PalleteType.IMAGE_TYPE:
      configType = IMAGE_TYPE_TOOL;
      break;

    case PalleteType.USERGROUP_TYPE:
      configType = USERGROUP_TYPE;
      break;

    default:
      break;
  }

  return configType;
};

/**
 * @props (onClick) will return id to
 * identify which element is clicked
 *
 * @props (type) will take one value from
 *     PalleteType(@const) to decide
 *      which pallete will be rendered
 */
const ToolPallete = (props) => {
  let items = getTypeConfig(props.type).map(function (itemData, index) {
    return (
      <FormControlLabel label={itemData.label} key={index}
        labelPlacement={itemData.labelPosition}
        control={
          <IconButton onClick={(event) => props.onClick(itemData.id, event)}>
            {getIconClassName(itemData.id)}
          </IconButton>
        }
      />
    );
  });

  return (
    <Box display="flex">
      <Box display="flex" flex={0.08} marginLeft="auto">
        {items}
      </Box>
    </Box>
  );
};

const getIconClassName = (icon_type) => {
  let iconClassName;

  switch (icon_type) {

    case "TYPE_MOVE":
      iconClassName = <FlipToBackIcon />;
      break;

    case "TYPE_COPY":
      iconClassName = <FileCopyIcon />;
      break;

    case "TYPE_DELETE":
      iconClassName = <DeleteIcon />;
      break;

    case "TYPE_TOC":
      iconClassName = <TOCIcon />;
      break;
    case "TYPE_SETTING":
      iconClassName = <SettingIcon />;
      break;

    case "TYPE_BOLD":
      iconClassName = <FormatBoldIcon />;
      break;
    case "TYPE_ITALIC":
      iconClassName = <FormatItalicIcon />;
      break;
    case "TYPE_THROUGHT":
      iconClassName = <FormatStrikethroughIcon />;
      break;
    case "TYPE_LINK":
      iconClassName = <InsertLinkIcon />;
      break;
    case "TYPE_ALIGNL":
      iconClassName = <FormatAlignLeftIcon />;
      break;
    case "TYPE_ALIGNC":
      iconClassName = <FormatAlignCenterIcon />;
      break;
    case "TYPE_ALIGNR":
      iconClassName = <FormatAlignRightIcon />;
      break;
    case "TYPE_LIST":
      iconClassName = <ListIcon />;
      break;
    case "TYPE_LISTNUMBER":
      iconClassName = <FormatListNumberedIcon />;
      break;
    case "TYPE_PALETTE":
      iconClassName = <PaletteIcon />;
      break;
    case "TYPE_BRUSH":
      iconClassName = <BrushIcon />;
      break;
    default:
      break;
  }

  return iconClassName;
};

ToolPallete.propTypes = {
  type: PropTypes.oneOf(Object.keys(PalleteType)),
};

ToolPallete.PalleteType = PalleteType;

export default ToolPallete;
