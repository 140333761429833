import React, { Fragment } from 'react';
import { Card, Box, Typography, IconButton, Button, InputBase, Radio, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { withStyles } from '@material-ui/styles';
import { FormStore } from '../../services/form.store';
import {FormHelperService, ACTIONS, ELEMENT_TYPE } from '../../services/form.helper.service';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ActiveQuestionLogicOptions from './question/ActiveQuestionLogicOptions';

const styles = theme => ({
  root: {
    display: "block",
  },
  flexGrow: {
    flexGrow: 1,
  },
  optionDiv: {
    borderRadius: 2,
    border: "0.8px solid rgba(16,22,26,.2)",
    padding: "5px",
  },
  deleteOptionButton: {
    float: 'right'
  },
  addOptionButton: {
    marginTop: "10px",
    color: "#419BAC",
    marginLeft: "-7px",
    "&:hover": {
      backgroundColor: "transparent"
    },
  },
  radioButton: {
    color: "rgba(16,22,26,.2)",
  },
  answerLogicMenu: {
    borderRadius: 0
  }
});

const getListStyle = isDraggingOver => ({
  background: "rgba(16,22,26,.2)",
});

const getItemStyle = (isDragging, draggableStyle, result) => ({
  borderColor: isDragging ? "#419BAC" : "rgba(16,22,26,.2)",
  borderWidth: isDragging ? "2px" : "0.8px",
  borderRadius: isDragging ? 4 : 2,
  background: isDragging ? "rgba(255, 255, 255, 0.75)" : "#FFFFFF",
  ...draggableStyle
});

class RadioButtonsGroup extends React.Component {

  constructor(props) {
    super();
    if (props.elementPopup) {
      let options = [];
      if (props.elementPopup.options && props.elementPopup.options.length > 0) {
        props.elementPopup.options.forEach((item) => {
          options.push({ "selected": false, "optionText": item.value, 
        	  id:item.id });
        });
      }
      this.state = {
        optionsPopup: options
      };
      this.editPopupOptionLabel = this.editPopupOptionLabel.bind(this);
      this.updatePopupState = this.updatePopupState.bind(this);
    }
    else{
    let options = [];
    if (props.element.options && props.element.options.length > 0) {
      props.element.options.forEach((item) => {
        options.push({ "selected": false, id: item.id, "optionText": item.value });
      });
    }
    this.state = { options: options };
    this.addOption = this.addOption.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.editOptionLabel = this.editOptionLabel.bind(this);
    this.updateState = this.updateState.bind(this);
    this.moveOptions = this.moveOptions.bind(this);
    this.disableDelete = this.disableDelete.bind(this);
  }
}

  updateState(updatedOptions) {
    let array = [];
    this.setState({ options: updatedOptions }, () => {
      if (this.state.options.length > 0) {
        this.state.options.forEach((item) => {
          array.push({id: item.id, value:item.optionText});
        });
      }
      let arrayIds = array.map(option => option.id);
      let conditionals = FormStore.sharedInstance().getConditional();
      let filteredConditionals = [];
      if(conditionals !== undefined){
        conditionals.forEach(conditional => {
          if (conditional.id !== undefined) {
            if (arrayIds.includes(conditional.id)) {
              filteredConditionals.push(conditional);
            }
          }
        });
      }
      FormStore.sharedInstance().addRadioGroupOptions(array, filteredConditionals);
    });
  }

  updatePopupState(updatedOptions){
    let array = [];
    this.setState({ optionsPopup: updatedOptions }, () => {
      if (this.state.optionsPopup.length > 0) {
        this.state.optionsPopup.forEach((item) => {
          array.push(item.optionText);
        });
      }
      FormStore.sharedInstance().addPopupOptions(array);
    });
  }

  addOption() {
    let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.ADD, undefined, undefined, ELEMENT_TYPE.RADIO);
    this.updateState(updatedOptions);
  }

  deleteOption(index) {
    let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.DELETE, index);
    this.updateState(updatedOptions);
  }

  editOptionLabel(index, value) {
    let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.EDIT, index, value);
    this.updateState(updatedOptions);
  }

  editPopupOptionLabel(index, value){
    let updatedOptions = FormHelperService.performAction(this.state.optionsPopup, ACTIONS.EDIT, index, value);
    this.updatePopupState(updatedOptions);
  }

  moveOptions(result) {
    if (!result.destination) {
      return;
    }
    let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.MOVE, undefined, undefined, undefined,result.source.index, result.destination.index);
    this.updateState(updatedOptions);
  }

  disableDelete() {
    return (this.state.options.length <= 2) ? true : false;
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {this.props.element ?
        <Typography component="div" id="rootRadioGroup">
        <Grid container spacing={3}>
          <Grid item style={{ width: 'calc(100% - 290px)' }} >
            <DragDropContext onDragEnd={this.moveOptions}>
              <Droppable droppableId="droppableForRadioOptionsReordering">
                {(provided, snapshot) => (
                  <Card id="radioCard" square {...provided.droppableProps} 
                    ref={provided.innerRef} 
                    style={getListStyle(snapshot.isDraggingOver)}>
                    <Box alignItems="center">
                      {this.state.options.map((child, index) => (
                        <Draggable key={index} draggableId={index.toString()} index={index}>
                          {(providedDrag, snapshotDrag) => (
                            <div key={index} id="radioOption" 
                              className={classes.optionDiv} 
                              ref={providedDrag.innerRef}
                              {...providedDrag.draggableProps}
                              {...providedDrag.dragHandleProps}
                              style={getItemStyle(
                                snapshotDrag.isDragging,
                                providedDrag.draggableProps.style
                              )}>
                              <Radio id="radioButton" 
                            	classes={{ root: classes.radioButton }} 
                                value={index} disabled/>
                              <InputBase
                                id={"optionLabel" + index}
                                value={child.optionText}
                                inputProps={{ 'aria-label': 'naked' }}
                                autoComplete='off'
                                style={{ width: "80%", padding: "5px" }}
                                onChange={(event) => { this.editOptionLabel(index, event.target.value) }} ></InputBase>
                              <IconButton id="deleteButton" 
                            	disabled={this.disableDelete()} 
                                className={classes.deleteOptionButton} 
                                onClick={() => this.deleteOption(index)}>
                                <DeleteIcon fontSize="inherit" /></IconButton>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  </Card>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          {this.props.element.properties && this.props.element.properties.conditional ?
            <Grid item style={{ width: '290px' }}>
              <Card className={classes.answerLogicMenu}>
                <ActiveQuestionLogicOptions options={this.state.options}/>
              </Card>
            </Grid>
          : null}
        </Grid>
          <Button id="addOptionButton" className={classes.addOptionButton} 
          	variant="text" onClick={this.addOption}>+ ADD OPTION</Button>
          </Typography>
          : null}
        {this.props.elementPopup ?
          <Typography component="div" id="rootRadioGroupPopup">
            <Grid container spacing={3}>
              <Grid item></Grid>
              <Card id="radioCard" square>
                <Box alignItems="center">
                  {this.state.optionsPopup.map((child, index) => (
                    <div key={index} id="radioOptionPopup" 
                    	className={classes.optionDiv}>
                      <Radio id="radioButtonPopup" 
                    	  classes={{ root: classes.radioButton }} 
                      value={index} disabled />
                      <InputBase id={"optionLabelPopup" + index}
                        value={child.optionText}
                        inputProps={{ 'aria-label': 'naked' }}
                        autoComplete='off'
                        style={{ width: "80%", padding: "5px" }}
                        onChange={(event) => { 
                        	this.editPopupOptionLabel(index, event.target.value) 
                        }}
                      ></InputBase>
                    </div>))}
                </Box>
              </Card>
            </Grid>
          </Typography> : null}
      </Fragment>
    )
  }
}
export default withStyles(styles)(RadioButtonsGroup);