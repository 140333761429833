import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

export function Loader({ loading, position = ""  }) {
  const useStyles = makeStyles((theme) => ({
    center: {
      "position": "absolute",
      "top": "50%",
      "left": "50%",
  }
  }));
  
  const classes = useStyles();

  return loading ? (<CircularProgress className={position ? classes[position] : ''}></CircularProgress>) : null;/* className={classes.progress}*/
}