import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MenuItem, withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { Box, Grid, Typography,TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormBuilderService from '../../services/form-builder.service';
import { FormStore } from '../../services/form.store';
import { ATTRIBUTES } from '../../services/form.helper.service';
import { Autocomplete } from '@material-ui/lab';

const styles = theme => ({
    formLabel: {
        padding: "0px 2px",
    },
    asterisk: {
        color: 'red'
    },
    formControl: {
        margin: theme.spacing(1),
        borderRadius: 4,
        border: '1px solid grey',
        padding: '10px',
        width: '100%',
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    errorText: {
        color: '#f44336'
    },
    MuiPaper: {
        width: '100%',
    }
});


class LinkOptionalEquipment extends Component {
    constructor(props) {
        super();
        this.state = {
            optionalEquipments: [],
            selectedOptionalEquipment: '',
            hasError: false,
        };
        this.formBuilderService = new FormBuilderService();
    }

    componentDidMount() {
        this.formBuilderService.getAllOptionalEquipments().then(response => {
            this.setState({
                optionalEquipments: response.data,
                hasError: false,
            })
        });
    }

    onChangeEquipment = (event,value) => {
        if (event.target.value !== undefined) {
            this.setState({
                selectedOptionalEquipment: value,
            });
        }
    }

    formSubmitHandler = () => {
      const isValid = this.validatePopup();
      if (isValid) {
        FormStore.sharedInstance().toggleQuestionAttr({
          attr: "properties",
          name: ATTRIBUTES.HAS_OPTIONAL_EQUIPMENT_VALUE,
          value: this.state.selectedOptionalEquipment.optionalEquipId
        });
        FormStore.sharedInstance().getOptionalEquipment(
          this.state.selectedOptionalEquipment
        );
        this.props.onClose();
        this.setState({
          selectedOptionalEquipment: "",
          hasError: false,
        });
      } else {
        this.setState({
          hasError: true,
        });
      }
    }

    validatePopup = () => {
        return !!this.state.selectedOptionalEquipment;
    }

    getHelperTextForRequiredField = (value, type) => {
        if (this.state.hasError && !value.length) {
            return 'Optional Equipment is required!';
        }
        return '';
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onClose} classes={{ paper: classes.MuiPaper }}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <Box p={3}>
                            <Grid container spacing={3} >
                                <Grid item md={12} xs={12} >
                                    <Typography className={classes.formLabel}>Optional Equipment<span className={classes.asterisk}>*</span></Typography>
                                    <FormControl fullWidth variant="outlined">
                                        <Autocomplete
                                            id="optionalEquipments"
                                            options={this.state.optionalEquipments}
                                            onChange={this.onChangeEquipment}
                                            value={this.state.selectedOptionalEquipment || null}
                                            getOptionLabel={(option) =>
                                                (option && option.equipmentName) || ""
                                            }
                                            isOptionEqualToValue={(option, value) =>{
                                                return option?.optionalEquipId === value?.optionalEquipId
                                            }
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                {props && props.equipmentName
                                                    ? props.equipmentName
                                                    : "No Name"}
                                                </li>
                                            )}
                                        />
                                    </FormControl>
                                    <FormHelperText className={classes.errorText}>{this.getHelperTextForRequiredField(this.state.selectedOptionalEquipment)}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} variant="contained" id="cancel" color="secondary">Cancel</Button>
                        <Button onClick={this.formSubmitHandler} variant="contained" id="save">Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(LinkOptionalEquipment);



