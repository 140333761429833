import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MenuItem, withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { Box, Grid, Typography, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormBuilderService from '../../services/form-builder.service';
import { FormStore } from '../../services/form.store';
import { Autocomplete } from '@material-ui/lab';

const styles = theme => ({
    formLabel: {
        padding: "0px 2px",
    },
    formControl: {
        margin: theme.spacing(1),
        borderRadius: 4,
        border: '1px solid grey',
        padding: '10px',
        width: '100%',
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    MuiPaper: {
        width: '100%',
    }
});

class AssignUniqueCode extends Component {
    constructor(props) {
        super();
        this.state = {
            uniqueCodes: [],
            selectedUniqueCode: null,
        };
        this.formBuilderService = new FormBuilderService();
    }

    componentDidMount() {
        this.formBuilderService.getAllUniqueCodes()
            .then(response => {
                this.setState({
                    uniqueCodes: response.data,
                });
            })
            .catch((err) => { return err; });
    }

    onChangeUniqueCode = (event,value) => {
        if(event.target.value !== undefined){
        this.setState({
            selectedUniqueCode: value,
        });
    }
    }

    onSubmit = () => {
        if (this.state.selectedUniqueCode) {
            FormStore.sharedInstance().assignUniqueCodeToQuestion(this.state.selectedUniqueCode);
        }
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog open={true} classes={{ paper: classes.MuiPaper }}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="dialog-title">Link Unique ID</DialogTitle>
                    <DialogContent>
                        <Box p={3}>
                            <Grid container spacing={3} >
                                <Grid item md={12} xs={12} >
                                    <Typography className={classes.formLabel}>Unique IDs</Typography>
                                    <FormControl fullWidth variant="outlined">
                                        <Autocomplete
                                            id="uniqueCodes"
                                            options={this.state.uniqueCodes}
                                            onChange={this.onChangeUniqueCode}
                                            value={this.state.selectedUniqueCode || null}
                                            getOptionLabel={(option) =>
                                                option ? option.labelInReport : ""
                                            }
                                            isOptionEqualToValue={(option, value) =>
                                                option.id === value.id
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                variant="outlined"
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                {props ? props.labelInReport : ""}
                                                </li>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} variant="contained" id="cancel" color="secondary">Cancel</Button>
                        <Button onClick={this.onSubmit} variant="contained" id="save">Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(AssignUniqueCode);
