import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import { Box, Grid, TextField, IconButton } from '@material-ui/core';
import { FormStore, openSectionState, openTranslateSectionState } from '../../../services/form.store';
import SectionFooter from '../SectionFooter'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const styles = theme => ({
  root: {
    border: '1px solid rgb(91, 195, 115)',
    backgroundColor: 'rgba(91,195,115,0.1)'
  },
  activeTextField: {
    backgroundColor: 'rgba(255, 255, 255, .75)',
  },
  maxLenthStyle: {
    width: "249px",
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class ActiveSection extends Component {

  constructor(props) {
    super();
    if (props.elementPopup) {
      let seccompType = props.elementPopup.componentType;
      let answer = '';
      this.state = {
        showError: answer.length <= 0,
        secText: props.elementPopup.label,
        secType: seccompType,
        secTitle: (((seccompType === 'section')? 'Section' : 'Sub-Section') + 'Name'),
      }
    }
    else{
    let compType = props.element.componentType;
    let answer = '';
    if (props.element.properties && props.element.properties.answerLabel !== undefined) {
      answer = props.element.properties.answerLabel
    }
    this.state = {
      showError: answer.length <= 0,
      text: props.element.label,
      showAnswerLabel: false,
      answer: answer,
      type: compType,
      title: (((compType === 'section') ? 'Section ' : 'Sub-Section ') + 'Name')
    }
  }
}

  componentDidMount() {
    if (this.props.elementPopup) {
      this.sectionStateSubscription = openTranslateSectionState.subscribe(sectionId => {
        this.setState({ expandedSection: sectionId === this.props.elementPopup.elementId });
      });
    }
    else {
      this.sectionStateSubscription = openSectionState.subscribe(sectionId => {
        this.setState({ expanded: sectionId === this.props.element.elementId });
      });
    }
  }

  componentWillUnmount() {
    this.sectionStateSubscription.unsubscribe();
  }

  onTitleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= 0) {
      this.setState({
        showError: true
      })
    }

    this.props.element.label = newValue;
    this.updateText(newValue);
    FormStore.sharedInstance().shouldNavigate(true);
  }

  onSectionTitleChange = (event) => {
    const newSectionValue = event.target.value;
    if(newSectionValue.length <= 0){
      this.setState({
        showError:true
      })
    }
    this.props.elementPopup.label = newSectionValue;
    this.updateSectionText(newSectionValue);
  }

  updateText = (text) => {
    this.setState({ text: (text.length) > 0 ? text : '' });
  }

  updateSectionText = (secText) => {
    this.setState({ secText: (secText.length) > 0 ? secText : ''});
  }

  requiredFieldValidator(value) {
    return (value.length > 0 ? true : false);
  }

  getHelperTextForName(value) {
    let helperText = "";
    if (this.state.showError) {
      if (!this.requiredFieldValidator(value)) {
        helperText = ((this.state.type === 'section') ? 'Section ' : 'Sub-Section ') + "name is Required!";
      }
    }
    return helperText;
  }

  handleExpandClick = (event) => {
    event.preventDefault();
    FormStore.sharedInstance().openSection(this.props.element.elementId);
  };

  handleSectionExpandClick = (event) => {
    event.preventDefault();
    FormStore.sharedInstance().openTranslateSection(this.props.elementPopup.elementId);
  }

  onTitleBlur = (event) => {
    event.preventDefault();
    FormStore.sharedInstance().makeActive(undefined);
  }

  onSectionTitleBlur = (event) => {
    event.preventDefault();
    FormStore.sharedInstance().makeActiveTranslateSection(undefined);
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {this.props.element ?
        <Box className={classes.root}>
        <Grid container spacing={3} style={{padding:"40px" }}>
          <Grid item style={{ width: 'calc(100% - 240px)'}}>
            <TextField id="textField" fullWidth required variant="outlined" label={this.state.title}
                       error={this.state.showError && (!this.requiredFieldValidator(this.state.text))}
                       helperText={this.getHelperTextForName(this.state.text)}
                       value={this.state.text}
                       onChange={this.onTitleChange}
                       className={classes.activeTextField}
                       onBlur={this.onTitleBlur}
                       />
          </Grid>
          <Grid item style={{ width: 240, textAlign: "right" }}>
            <IconButton id="iconButton" edge="end" onClick={this.handleExpandClick}>
              <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.expanded })} />
            </IconButton>
          </Grid>
        </Grid>
        <SectionFooter element={this.props.element}/>
      </Box> : null}

        {this.props.elementPopup ?
          <Box className={classes.root}>
            <Grid container spacing={3} style={{ padding: "20px" }}>
              <Grid item style={{ width: 'calc(100% - 120px)' }}>
                <TextField id="textFieldPopup" fullWidth required variant="outlined" label={this.state.secTitle}
                  error={this.state.showError && (!this.requiredFieldValidator(this.state.secText))}
                  helperText={this.getHelperTextForName(this.state.secText)}
                  value={this.state.secText}
                  onChange={this.onSectionTitleChange}
                  className={classes.activeTextField}
                  onBlur={this.onSectionTitleBlur}
                />
              </Grid>
              <Grid item style={{ width: 120, textAlign: "right" }}>
                <IconButton edge="end" id="popupIcon" onClick={this.handleSectionExpandClick}>
                  <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.expandedSection })} />
                </IconButton>
              </Grid>
            </Grid>
          </Box> : null}

      </Fragment>
    )
  }
}

export default withStyles(styles)(ActiveSection);
