import AnswerOption from "./menu/AnswerOption";
import DateTimeMenu from "./menu/DateTimeMenu";
import SignatureOptions from "./menu/SignatureOptions";
import RadioGroupMenu from "./menu/RadioGroupMenu";
import CheckboxMenu from "./menu/CheckboxMenu";
import DropDownMenu from "./menu/DropDownMenu";
import BarcodeMenu from "./menu/BarcodeMenu";
import TextAreaMenu from "./menu/TextAreaMenu";
import HelpInstructionMenu from "./menu/HelpInstructionMenu";
import FileUploaderMenu from "./menu/FileUploaderMenu";
import RangeSliderMenu from "./menu/RangeSliderMenu";
import DependentDropdownMenu from "./menu/DependentDropdownMenu";

export const MenuMap = {
  text:     AnswerOption,
  textarea: TextAreaMenu,
  radio: RadioGroupMenu,
  checkbox: CheckboxMenu,
  select: DropDownMenu,
  date: DateTimeMenu,  
  fileInput: FileUploaderMenu,
  range: RangeSliderMenu,
  dependentDropdown: DependentDropdownMenu,
  barcode: BarcodeMenu,
  signature: SignatureOptions,
  helpimage: HelpInstructionMenu
};

export default MenuMap;
