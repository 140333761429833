import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import LinkOptionalEquipment from '../LinkOptionalEquipment';
import { ANSWER_OPTION_LABELS } from '../../../services/form.helper.service';
import AssignUniqueCode from '../AssignUniqueCode';

const useStyles = makeStyles((theme) => ({
  root: {},
  sideNavDivider: {
    marginLeft: 16,
  },
}));

const FileUploaderMenu = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [popup, setPopup] = useState(false);
  const [assignUniqueCodePopup, setAssignUniqueCodePopup] = useState(false);
  const [menuItem] = useState([
    {
      label: 'Actions',
      children: [
        { label: ANSWER_OPTION_LABELS.LINK_OPTIONAL_EQUIPMENT },
        { label: ANSWER_OPTION_LABELS.ASSIGN_UNIQUE_CODE }
      ]
    }
  ]);

  const handleListItem = (event, child) => {
    if (child.label === ANSWER_OPTION_LABELS.LINK_OPTIONAL_EQUIPMENT) {
      setPopup(true);
    }
    else if (child.label === ANSWER_OPTION_LABELS.ASSIGN_UNIQUE_CODE) {
      setAssignUniqueCodePopup(true);
    }
  }

  const onCloseAssignUniqueCodePopup = () => {
    setAssignUniqueCodePopup(false);
  }

  const onClose = () => {
    setPopup(false);
  }


  return (
    <div ref={ref}>
      {menuItem.map(({ ...item }, mainIndex) => (
        <span id="menuSection" key={mainIndex}>
          <ListSubheader>
            <Typography variant="overline" component="div" style={{ marginTop: 5 }}>
              {item.label}
            </Typography>
          </ListSubheader>
          {item.children.map((child, index) => (
            <span id="menuOption" key={child.label}>
              <MenuItem>
                <ListItemText primary={child.label} onClick={(event) => handleListItem(event, child)} />
              </MenuItem>
              <Divider light className={classes.sideNavDivider} />
            </span>
          ))}
        </span>
      ))}
      <LinkOptionalEquipment
        open={popup} onClose={onClose} title="Link Optional Equipment"
      />
      {assignUniqueCodePopup &&
        <AssignUniqueCode
          onClose={onCloseAssignUniqueCodePopup}
        />
      }
    </div>
  );
});

export default FileUploaderMenu;
