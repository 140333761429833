import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import { FormStore } from '../../../services/form.store';
import { ANSWER_OPTION_LABELS, ATTRIBUTES } from '../../../services/form.helper.service';
import LinkOptionalEquipment from '../LinkOptionalEquipment';
import AssignUniqueCode from '../AssignUniqueCode';

const useStyles = makeStyles((theme) => ({
  root: {},
  sideNavDivider: {
    marginLeft: 16,
  },
}));

const DateTimeMenu = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [popup, setPopup] = useState(false);
  const [assignUniqueCodePopup, setAssignUniqueCodePopup] = useState(false);
  const [menuItem, setMenuItem] = useState([
    {
      label: 'Answer Options',
      children: [
        { label: 'Allow File Upload', isChecked: props.element.properties && props.element.properties.allowFileUpload ? props.element.properties.allowFileUpload : false, name: ATTRIBUTES.ALLOW_FILE_UPLOAD },
        { label: 'Pre-filled Date', isChecked: props.element.properties && props.element.properties.preFilledDate ? props.element.properties.preFilledDate : false, name: ATTRIBUTES.PRE_FILLED_DATE },
        { label: 'Hour Worked Input', isChecked: props.element.properties && props.element.properties.hasHoursWorked ? props.element.properties.hasHoursWorked : false, name: ATTRIBUTES.HAS_HOURS_WORKED },
      ]
    },
    {
      label: 'Other Actions',
      children: [
        { label: ANSWER_OPTION_LABELS.LINK_OPTIONAL_EQUIPMENT },
        { label: ANSWER_OPTION_LABELS.ASSIGN_UNIQUE_CODE }
      ]
    }
  ]);

  const handleToggleSwitch = (index, mainIndex) => (event) => {
    let newArr = [...menuItem];
    newArr[mainIndex].children[index].isChecked = event.target.checked;
    setMenuItem(newArr);
    if (event.target.name === ATTRIBUTES.ALLOW_FILE_UPLOAD) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.ALLOW_FILE_UPLOAD, value: event.target.checked });
    }
    else if (event.target.name === ATTRIBUTES.PRE_FILLED_DATE) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.PRE_FILLED_DATE, value: event.target.checked });
    }
    else if (event.target.name === ATTRIBUTES.HAS_HOURS_WORKED) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.HAS_HOURS_WORKED, value: event.target.checked });
      if (event.target.checked) {
        FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.HOURS_WORKED_VALUE, value: 0.5 });
      }
      else {
        FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.HOURS_WORKED_VALUE, value: 0 });
      }
    }
  };

  const handleListItem = (event, child) => {
    if (child.label === ANSWER_OPTION_LABELS.LINK_OPTIONAL_EQUIPMENT) {
      setPopup(true);
    }
    else if (child.label === ANSWER_OPTION_LABELS.ASSIGN_UNIQUE_CODE) {
      setAssignUniqueCodePopup(true);
    }
  }

  const onClose = () => {
    setPopup(false);
  }

  const onCloseAssignUniqueCodePopup = () => {
    setAssignUniqueCodePopup(false);
  }

  return (
    <div ref={ref}>
      {menuItem.map(({ ...item }, mainIndex) => (
        <span id="menuSection" key={mainIndex}>
          <ListSubheader>
            <Typography variant="overline" component="div" style={{ marginTop: 5 }}>
              {item.label}
            </Typography>
          </ListSubheader>
          {item.children.map((child, index) => (
            <span id="menuOption" key={child.label}>
              <MenuItem>
                <ListItemText primary={child.label} onClick={(event) => handleListItem(event, child)} />
                <ListItemSecondaryAction>
                  {("isChecked" in child) ?
                    <Switch edge="end"
                      name={child.name}
                      checked={child.isChecked}
                      size="small"
                      color="primary"
                      onChange={handleToggleSwitch(index, mainIndex)}
                    />
                    : null}
                </ListItemSecondaryAction>
              </MenuItem>
              <Divider light className={classes.sideNavDivider} />
            </span>
          ))}
        </span>
      ))}
      <LinkOptionalEquipment
        open={popup} onClose={onClose} title="Link Optional Equipment"
      />
      {assignUniqueCodePopup &&
        <AssignUniqueCode
          onClose={onCloseAssignUniqueCodePopup}
        />
      }
    </div>
  );
});

export default DateTimeMenu;
