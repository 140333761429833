import { validationMap } from './form.validators.map';
import { extractTextAnswerValue } from '../store/form.answer.extract';

const validate = (element, answer) => {
    let errors = [];
    if (element !== undefined) {
        let validations = extractValidations(element);
        let validators = prepareValidators(validations, element);
        if (validators.length > 0) {
            errors = runValidators(validators, element, answer);
        }
        return errors;
    }
};

const extractValidations = (element) => {
    let validators = [];
    if (element) {
        let validations = element.validations;
        if (validations) {
            let keys = Object.keys(validations);
            keys.forEach((key) => {
                if (validations[key]) {
                    validators.push(key);
                }
            });
        }
        if(element.componentType === "date"){
            if (element.properties.hasHoursWorked) {
                   validators.push('hashoursWorked');
            }
        }
    }
    return validators;
}

const prepareValidators = (validations, element) => {
    let validators = [];
    let componentType = element.componentType;
    let validatorsMap = validationMap(componentType);
    if (validatorsMap) {
        validations.forEach((validation) => {
            let validator = validatorsMap[validation];
            if (validator) {
                validators.push(validator);
            }
        });
    }
   
    return validators;
};

const runValidators = (validators, element, answer) => {
    let errors = [];
    validators.forEach((validator) => {
        let error = validator(answer, element);
        if (error !== true) {
            //If true then no array occured
            if (typeof error === 'string') {
                //Error could be a single string
                errors.push(error);
            } else if (Array.isArray(error)) {
                //Error could be an array of strings.
                errors.push(...error);
            }
        }
    });
    return errors;
};

const defaultRequired = (answer, element) => {
    let value = extractTextAnswerValue(answer);
    if (value) {
        if (value.length > 0) {
            return true;
        }
    }
    return 'This field is required.';
};

const email = (answer, element) => {
    let value = extractTextAnswerValue(answer);
    if (value) {
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; /*This RegEx is safe. No need to fix.*/
        if (pattern.test(value)) {
            return true;
        }
    }
    return 'Please enter a valid email address.';
};

const maxLength = (answer, element) => {
    let value = extractTextAnswerValue(answer);
    let maxAllowedValue = element.validations.maxLength;
	let required = element.validations.required;
    if (value) {
        if (value.length <= maxAllowedValue) {
            return true;
        }
    } else if(!required && value.length <= maxAllowedValue) {
        return true;
    }
    return 'Please do not enter more than ' + maxAllowedValue + ' characters.';
};

const onlyNumber = (answer, element) => {
    let value = extractTextAnswerValue(answer);
    const pattern = /^[0-9\b]+$/; /*This RegEx is safe. No need to fix.*/
    if (value) {
        if (pattern.test(value)) {
            return true;
        }
    }
    return 'Please enter numbers only.';
};

const phoneNumber = (answer, element) => {
    let value = extractTextAnswerValue(answer);
    const pattern = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]{6,13}$/g; /*This RegEx is safe. No need to fix.*/
    if (value) {
        if (pattern.test(value)) {
            return true;
        }
    }
    return 'Please enter a valid phone number.';
};

const onlyPositiveNumber = (answer, element) => {
    let value = extractTextAnswerValue(answer);
    if (value) {
        let intValue = Math.parseInt(value);
        if (intValue > 0) {
            return true;
        }
    }
    return 'Please do not enter a negative value.';
};

export { validate, defaultRequired, email, maxLength, onlyNumber, phoneNumber, onlyPositiveNumber };