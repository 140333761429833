import { Avatar, Box, Card, Grid, TextField, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { extractImageValue, extractTextAnswerValue } from '../../store/form.answer.extract';
import { textAnswer } from '../../store/form.answer.fill';
import { withStyles } from '@material-ui/styles';
import ImagePopup from './ImagePopup';
import { openSectionState, currentSectionState } from '../../store/form.store';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
    }
});

class MultiLineInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            imageOpen: false,
            imageValue: [],
            imageUrl: '',
            imageName: '',
            editable: false
        };
    }

    componentDidMount() {
        let expanded = ''
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => {expanded = sectionId});
        this.currentSectionSubscription = currentSectionState.subscribe(section => {
            this.setState({ editable: section === expanded})
        });
        this.onLoadPage(this.props);
        if (this.props.element.properties.allowFileUpload) {
            this.onLoadImage(this.props);
        }
    }

    componentWillUnmount() {
        this.sectionStateSubscription.unsubscribe();
        this.currentSectionSubscription.unsubscribe();
    }

    onLoadPage(props) {
        if (props.element) {
            let answer = props.element.answer;
            let userResponse = extractTextAnswerValue(answer);
            if (userResponse) {
                this.setState({
                    value: userResponse
                });
            }
        }
    }

    onLoadImage(props) {
        if (props.element) {
            let image = props.element.answer;
            let imageResponse = extractImageValue(image);
            if (imageResponse) {
                this.setState({
                    imageValue: imageResponse
                });
            }
        }
    }

    onClickAvatar = (image) => {
        this.setState({
            imageOpen: true,
            imageUrl: image.imageUrl,
            imageName: image.imageName
        });
    }

    onClose = () => {
        this.setState({ imageOpen: false });
    }

    onChange = (event) => {
        this.setState({ value: event.target.value})
        let answerNode = textAnswer(event.target.value, this.props.element.answer);
        if (this.props.onChange !== undefined) {
            this.props.onChange(answerNode, this.props.element);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                {this.state.editable ? (
                    <TextField
                        id="textField" fullWidth required variant="outlined"
                        multiline
                        minRows={4}
                        error={this.props.hasError}
                        value={this.state.value}
                        onChange={this.onChange}
                    />
                    ) : (<Typography>{this.state.value}</Typography>)}
                {(this.props.element.properties.allowFileUpload && this.state.imageValue !== []) ? this.state.imageValue.map((image) => (
                    <Card className={classes.root} >
                        <Box>
                            <Grid container spacing={1}>
                                <Avatar alt={image.imageName} src={image.imageUrl} variant="square" style={{ height: '75px', width: '100px' }}
                                    onClick={() => this.onClickAvatar(image)} />
                            </Grid>
                        </Box>
                    </Card>
                )) : null}
                <ImagePopup open={this.state.imageOpen} onClose={this.onClose} imageUrl={this.state.imageUrl} title={this.state.imageName}></ImagePopup>
            </Fragment>
        );
    }
}
export default withStyles(styles)(MultiLineInput);