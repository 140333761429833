export const FORM_CATEGORIES = Object.freeze({
  BUSINESS: "Business",
  SUB_BUSINESS: "Sub-Business",
  GROUP: "Group",
  SUB_GROUP: "Sub-Group"
});

export const SORT_MENU_OPTIONS = Object.freeze([
  { label: "Status", value: "formId.formStatus" },
  { label: "Form Name", value: "formId.title" },
  { label: "Group", value: "formId.formCategories.category" },
  { label: "Sub-Group", value: "formId.formCategories.category" },
  { label: "WindFarm", value: "formId.formSiteRegion.siteRegion.siteId" },
  { label: "Turbine", value: "" },
  { label: "Date Added", value: "createdDate" },
  { label: "Author", value: "userId.createdBy" }
]);

export const FORM_MENU_OPTIONS = Object.freeze([
  { label: "RejectForm", value: "rejectForm" },
  { label: "Download", value: "downloadForm" },
  { label: "Delete", value: "deleteForm" }
]);

export const MY_FORMS = Object.freeze({
  SIZE: 20
}); 

export class ReviewFormHelperService { }