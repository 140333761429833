import React, { Component, Fragment } from 'react';
import { FormStore, openSectionState, activeTocElementState } from '../../services/form.store';
import { withStyles } from '@material-ui/styles';
import hiddenBase from '../../shared/imgs/hiddenBack.png';
import { Typography, Collapse, IconButton, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Components from './CompMap';
import Link from '@material-ui/core/Link';

const styles = theme => ({
    root: {
        paddingBottom: '0px',
        border: '1px solid rgba(0, 0, 0, .08)',  
    },
    flexGrow: {
        flexGrow: 1,
    },
    fixedFooter: {
        top: 'auto',
        bottom: 0,
        width: 'calc(100% - 300px)',
    },
    sectionTitle: {
        padding: theme.spacing(1, 3),  
    },
    questionBody: {
        borderTop: '1px solid rgba(0, 0, 0, .08)',
      },
    hiddenQ: {
        backgroundImage: `url(${hiddenBase})`,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    toolbarButtons: {
        marginLeft: 'auto',
      },
});


class SectionList extends Component {

    constructor() {
        super();
        this.state = {
            opened: false,
            onHover: false,
            activeElement:null,
        }
    }

    componentDidMount() {
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => {
            this.setState({ opened: sectionId === this.props.sectionlist.elementId });
        });

        this.activeTocElementState = activeTocElementState.subscribe(value => {
            if(value){
              this.setState({activeElement:value})
            }
        })
    }

    componentWillUnmount() {
        this.sectionStateSubscription.unsubscribe();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.activeElement!==this.state.activeElement){
            FormStore.sharedInstance().getElementRef(this.state.activeElement).scrollIntoView({block: 'center'})
        }
    }

    handleExpandClick = (event) => {
        event.preventDefault();
        FormStore.sharedInstance().openSection(this.props.sectionlist.elementId);
    };

    onMouseOver = () => {
        this.setState({ onHover: true })
    }

    onMouseOut = () => {
        this.setState({ onHover: false })
    }

    handleClick = (elementId) =>{
        FormStore.sharedInstance().toggleTocDrawer(true, elementId)
        FormStore.sharedInstance().getElementRef(elementId).scrollIntoView({block: 'center'});
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography component="div" className={classes.root}>
                            <Box display="flex" alignItems="center"
                                className={classes.sectionTitle} 
                                style={{ color: (this.props.sectionlist.label.length <= 0) ? 'red' : 'black' }}>
                                <Typography>
                                    {(this.props.sectionlist.label.length) > 0 ? this.props.sectionlist.label : null }
                                </Typography>
                                <div className={classes.flexGrow} />
                                <IconButton edge="end"
                                    onClick={this.handleExpandClick}>
                                    <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.opened })} />
                                </IconButton>
                            </Box>
                            <Collapse in={this.state.opened} timeout="auto" unmountOnExit>
                                <RenderChildren sectionlist={this.props.sectionlist} classes={this.props} handleClick={this.handleClick} isActive={this.state.activeElement}/>
                            </Collapse>
                </Typography>
            </Fragment>
        )
    }
}

function RenderChildren(props) {
    const { classes } = props.classes;
    if (props.sectionlist !== undefined) {
        const SubSectionList = Components['subsectionlist'];
        const items = props.sectionlist.children.map((item, key) =>
            item.componentType === 'subsection' ? <SubSectionList element={item} /> : 
            <Fragment>
              <Box p={2} className={classes.questionBody}>
                <Fragment >
                <Link className={classes.toolbarButtons}
                    component="button"
                    variant="string"
                    color={props.isActive === item.elementId?"primary":"secondary"}
                    onClick={()=>props.handleClick(item.elementId)}>
                    <span dangerouslySetInnerHTML={{ __html: item?.label }}></span>
                </Link>
                </Fragment>
            </Box>
          </Fragment>
        );
        return items;
    }

    return (<div> Empty Children </div>);
}

export default withStyles(styles)(SectionList);
