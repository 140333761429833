import React from 'react';
import { Box, Divider, Button, Icon } from '@material-ui/core';

const styles = {
  addButtonStyle: {
    backgroundColor: 'transparent',
    textTransform: 'none',
    boxShadow: 'none',
      fontSize: 14,
      color: 'rgb(68, 137, 151)'
    },
    dividerStyle: {
      backgroundColor: 'rgb(190, 190, 190)', height: 1
    }
}

const AddSectionBlock = (props) => {
    return (
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" pt={1} pb={1}>
            <Box flex={1}>
              <Divider flexgrow={1} style={styles.dividerStyle} />
            </Box>
            <Button
                style={styles.addButtonStyle}
                variant="contained"
                id="newSectionButton"
                startIcon={<Icon>add</Icon>}
                onClick={() => { props.handler() }}>
                New Section
              </Button>
            <Box flex={1}>
              <Divider flex={1} style={styles.dividerStyle} />
            </Box>
        </Box>
    )
}

export default AddSectionBlock;
