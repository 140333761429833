import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tab from './Tab';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 300,
    marginTop: 69,
    height: 'calc(100% - 69px)',
    backgroundColor: '#FCFCFC'
  }
}));

const Sidebar = props => {
  const { open, variant } = props;
  const classes = useStyles();
  return (
    <Drawer classes={{ paper: classes.drawer }} open={open} variant={variant}>
      <Tab />
    </Drawer>
  )
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;