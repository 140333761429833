import React, { Component, Fragment } from 'react';
import { makeStyles, Box, Menu, Card, Typography, Collapse, Button, IconButton, 
	Divider, Icon } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import hiddenBase from '../../shared/imgs/hiddenBack.png';
import { withStyles } from '@material-ui/styles';
import FormElementsList from '../FormElementsList';
import AddSectionBlock from './AddSectionBlock';
import ToolPallete from './ToolPallete';
import Components from './CompMap';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { FormStore, openSectionState, activeElementState,
  activeTranslateElementState, 
  openTranslateSectionState } from '../../services/form.store';
import ActiveSection from './question/ActiveSection';
import ConfirmationDialog from '../form-element/ConfirmationDialog';
import { SectionDeleteMsg } from '../../utility/Constant';

const styles = theme => ({
  root: { padding: theme.spacing(3), paddingBottom: '0px' },
  flexGrow: { flexGrow: 1 },
  fixedFooter: { top: 'auto', bottom: 0, width: 'calc(100% - 300px)' },
  sectionTitle: { padding: theme.spacing(1, 3) },
  sectionBody: {
    padding: theme.spacing(2), borderTop: '1px solid rgba(0, 0, 0, .08)'
  },
  hiddenQ: { backgroundImage: `url(${hiddenBase})` },
  expand: {
    transform: 'rotate(0deg)', marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: { transform: 'rotate(180deg)' }
});

const questionStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid rgb(91, 195, 115)',
    backgroundColor: 'rgba(91,195,115,0.1)'
  },
  flexGrow: { flexGrow: 1 },
  switchLabel: { marginLeft: 0, marginRight: 20 },
  switch: { marginLeft: 3 },
  questionButtonStyle: {
    backgroundColor: 'transparent', boxShadow: 'none', fontSize: 14
  },
  dividerStyle: { backgroundColor: 'rgb(190, 190, 190)', width: 1, height: 40 },
  dropdownArrowStyle: {
    backgroundColor: 'transparent', boxShadow: 'none', fontSize: 14,
    color: 'black', marginLeft: 0, marginRight: 0, textAlign: 'center',
    "&:hover": { backgroundColor: "transparent", boxShadow: 'none' }
  },
  subSectionButtonStyle: {
    backgroundColor: 'transparent', boxShadow: 'none', fontSize: 14,
    color: 'rgb(68, 137, 151)', textTransform: 'capitalize',
    "&:hover": { backgroundColor: "transparent", boxShadow: 'none' }
  }
}));

class Section extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false, active: false, onHover: false, anchorEl: null,
      showAlert: false, isEnabled:false, expandedSection: false,
      activeSection: false, isEnabledSection : false
    }
  }

  componentDidMount() {
    if (this.props.sectionPopup) {
      this.sectionStateSubscription = openTranslateSectionState.subscribe(sectionId => {
        this.setState({ expandedSection: sectionId === this.props.sectionPopup.elementId });
      });
      this.activeStateSubscription = activeTranslateElementState.subscribe(newState => {
        this.setState({ activeSection: newState === this.props.sectionPopup.elementId });
      });
    }
    else {
      this.sectionStateSubscription = openSectionState.subscribe(sectionId => {
        this.setState({ expanded: sectionId === this.props.section.elementId });
      });
      this.activeStateSubscription = activeElementState.subscribe(newState => {
        this.setState({ active: newState === this.props.section.elementId });
      });
    }
  }

  componentWillUnmount() {
    this.sectionStateSubscription.unsubscribe();
    this.activeStateSubscription.unsubscribe();
  }

  handleExpandClick = (event) => {
    event.preventDefault();
    FormStore.sharedInstance().openSection(this.props.section.elementId);
  };

  handleSectionExpandClick = (event) => {
    event.preventDefault();
    FormStore.sharedInstance().openTranslateSection(this.props.sectionPopup.elementId);
  }

  onTitleClick = (event) => {
    event.preventDefault();
    this.setState({isEnabled:true})
    FormStore.sharedInstance().makeActive(this.props.section.elementId);
  }

  onSectionTitleClick = (event) => {
    event.preventDefault();
    this.setState({ isEnabledSection : true});
    FormStore.sharedInstance().makeActiveTranslateSection(this.props.sectionPopup.elementId);
  }

  prefixSection = () => {
    FormStore.sharedInstance().addSection(true);
  }

  postFixSection = () => {
    FormStore.sharedInstance().addSection();
  }

  handleCopyOrDelete = (value, event) => {
    if (value === 'TYPE_COPY') {
      FormStore.sharedInstance().copySection(this.props.section);
    }
    else if (value === 'TYPE_DELETE') {
      let sections = FormStore.sharedInstance().getSections();
      if (sections.length === 1) {
        this.setState({ showAlert: true });
      } else {
        this.setState({ anchorEl: event.currentTarget });
      }
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  };
  deleteSection = () => {
    FormStore.sharedInstance().deleteSection(this.props.section);
    this.handleClose();
  }
  onMouseOver = () => {
    this.setState({ onHover: true })
  }

  onMouseOut = () => {
    this.setState({ onHover: false })
  }
  
  alertClose = () => {
    this.setState({
      showAlert: false,
    })
  };

  render() {
    const { classes } = this.props;
    // const [open, setOpen] = React.useState(true);
    return (
      <Fragment>
        {this.props.section ?
          <Typography component="div" className={classes.root}>
            {this.state.expanded ? <AddSectionBlock handler={this.prefixSection} /> : null}
            <Card square id="sectionCard" onMouseEnter={this.onMouseOver} onMouseLeave={this.onMouseOut}>
              {!this.state.isEnabled || !this.state.active ? <Box display="flex" alignItems="center"
                className={classes.sectionTitle}
                style={{ color: (this.props.section.label.length <= 0) ? 'red' : 'black' }}>
                <Typography variant="h6"
                  onClick={this.onTitleClick}>
                  {(this.props.section.label.length) > 0 ? this.props.section.label : "Section name is required"}
                </Typography>
                <div className={classes.flexGrow} />
                {(this.state.onHover || this.state.expanded) ? <ToolPallete id="toolsPalette" type={ToolPallete.PalleteType.SECTION_TYPE} onClick={this.handleCopyOrDelete} /> : null}
                <ConfirmationDialog
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                  title="Delete Section"
                  description={SectionDeleteMsg}
                  onDelete={this.deleteSection}
                  anchorEl={this.state.anchorEl}
                />
                <IconButton edge="end"
                  onClick={this.handleExpandClick}>
                  <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.expanded })} />
                </IconButton>
              </Box>:
              <ActiveSection element={this.props.section} />}
              <Collapse in={this.state.showAlert}>
                  <Alert variant="filled" severity="warning"
                    action={<IconButton aria-label="close" color="inherit" size="small" onClick={this.alertClose}>
                        <CloseIcon fontSize="inherit" />
                      </IconButton>}> 
                      The form needs to have at least one section mandatorily!
                 </Alert>
                </Collapse>
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <RenderChildren section={this.props.section} />
              </Collapse>
            </Card> 
          {this.state.expanded ? <AddQuestionBlock /> : null}
          {this.state.expanded ? <AddSectionBlock handler={this.postFixSection} /> : null}
          </Typography> : null}

        {this.props.sectionPopup ? <Typography component="div" className={classes.root}>
        {!this.state.isEnabledSection || !this.state.activeSection ?
            <Box display="flex" alignItems="center" className={classes.sectionTitle}
              style={{ color: (this.props.sectionPopup.label.length <= 0) ? 'red' : 'black' }}>
              <Typography
                onClick={this.onSectionTitleClick}>
                {(this.props.sectionPopup.label.length) > 0 ? this.props.sectionPopup.label : "Section name is required"}
              </Typography>
              <div className={classes.flexGrow} />
              <IconButton edge="end"
                onClick={this.handleSectionExpandClick}>
                <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.expandedSection })} />
              </IconButton>
            </Box> : <ActiveSection elementPopup={this.props.sectionPopup} />}
          <Collapse in={this.state.expandedSection} timeout="auto" unmountOnExit>
            <RenderSectionChildren sectionPopup={this.props.sectionPopup}/>
          </Collapse>
        </Typography> : null}
      </Fragment>
    )
  }
}

function RenderChildren(props) {
  if (props.section !== undefined) {
    const TagName = Components['question'];
    const SubSection = Components['subsection'];
    const items = props.section.children.map((item, key) =>
      item.componentType === 'subsection' ? <SubSection element={item} /> : <TagName element={item} />
    );
    return items;
  }

  return (<div> Empty Children </div>);
}

function RenderSectionChildren(props) {
  if (props.sectionPopup !== undefined) {
    const TagName = Components['question'];
    const SubSection = Components['subsection'];
    const items = props.sectionPopup.children.map((item, key) =>
      item.componentType === 'subsection' ? <SubSection elementPopup={item} /> : <TagName elementPopup={item} />
    );
    return items;
  }

  return (<div> Empty Children </div>);
}

const AddQuestionBlock = (props) => {
  const classes = questionStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    setAnchorEl(null);
    const newType = value.component;
    if (newType) {
      FormStore.sharedInstance().addElement(newType);
      return;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubSectionClick = () => {
    FormStore.sharedInstance().addSubSection();
  }

  const handleAddQuestionClick = () => {
    FormStore.sharedInstance().addElement('text');
  }

  return (
    <Box display="flex"
      borderTop={0} pt={1} pb={1} pr={1} bgcolor="#fcfcfc">
      <Button
        id="add_sub_section_btn"
        className={classes.subSectionButtonStyle}
        variant="contained"
        startIcon={<Icon>add</Icon>}
        onClick={handleSubSectionClick}>
        New Sub-Section
              </Button>
      <Box display="flex" flexDirection="row" marginRight={3} flex={1} justifyContent="flex-end" >
        <Box display="flex" border={1} borderColor="#191919" alignSelf="center">
          <Button
            id="add_question_btn"
            className={classes.questionButtonStyle}
            startIcon={<Icon>add</Icon>}
            onClick={handleAddQuestionClick}>
            Question
                </Button>
          <Divider flex={1} orientation="vertical" className={classes.dividerStyle} />
          <Button
            className={classes.dropdownArrowStyle}
            variant="contained"
            endIcon={<Icon>arrow_drop_down</Icon>}
            onClick={handleClickListItem} />
        </Box>
        <Menu keepMounted id="lock-menu"
          className={classes.categoryNav}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <FormElementsList onItemClick={handleMenuItemClick} />
        </Menu>
      </Box>
    </Box>

  )
}

export default withStyles(styles)(Section);
