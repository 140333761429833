import React, { Component} from 'react';
import { withStyles } from '@material-ui/styles';
import FormElementsList from '../../components/FormElementsList';
import { FormStore } from '../../services/form.store';

const styles = theme => ({
  root: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
});

class FormElementSelector extends Component {

  constructor(props) {
    super();
  }

  onItemClick = (element) => {
    const newType = element.component;
    if (newType) {      
      FormStore.sharedInstance().addElement(newType);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <FormElementsList onItemClick={this.onItemClick} />
      </div>
    )
  }
}

export default withStyles(styles)(FormElementSelector);
