import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { ClickAwayListener } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  uppercase: {
    textTransform: 'uppercase'
  },
  delete: {
    color: '#ff0000'
  },
  paper: {
    maxWidth: "310px",
    border: "1px solid rgba(0, 0, 0, 0.24)",
    position: 'absolute',
    right: 0
  },
  divider: {
    marginLeft: "24px"
  },
  dialog:{
    padding: "10px 10px"
  }
}));

export default function SearchDialog(props) {
  const classes = useStyles();
  const { open, anchorEl, onCloseSearch, onCloseMenu, name} = props;

  return (
    <Popper 
      open={open}
      anchorEl={anchorEl}
      onClose={name === 'advanceSearch' ? onCloseSearch : onCloseMenu}
      placement="bottom-end">
      <Paper className={classes.paper}>
      <ClickAwayListener onClickAway={onCloseMenu ? onCloseMenu : () => {}}>
        <DialogContent style={{ width: 280 }} className={classes.dialog}>         
        {props.children}
        </DialogContent>
        </ClickAwayListener>
        <Divider className={classes.divider} />
      </Paper>
    </Popper>
  );
}

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};
