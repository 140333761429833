import React, { Component } from "react";
import { Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { messages } from '../../utility/Constant';
import { FormStore } from "../../services/form.store";
import { FormInputTypesValidationService } 
	from '../../services/form-input-types-validations.service';
import { ATTRIBUTES } from '../../services/form.helper.service';
import FormBuilderService from '../../services/form-builder.service';

const styles = (theme) => ({
  addOptionButton: {
    marginTop: "10px", color: "#757a76", marginLeft: "-7px",
    border: "1px solid gray"
  },
  activeTextField: { backgroundColor: "rgba(255, 255, 255, .75)" },
  dividerStyle: { width: 1, height: 40 },
  dividerStyleNameBlock: { marginLeft: 15 },
  toolpalete: { marginLeft: "108px" },
  input: { display: "none" },
  fileName: { padding: "5px", marginTop: "15px" },
  error: { 
	color: 'red', padding: '4px', fontSize: 14, width: '-webkit-fill-available' 
  },
  borderRed: { border: '1px solid red' },
  box: {marginLeft: '-7px'},
  container: {width: 'calc(100% - 288px)'}
});

class InstructionHelp extends Component {
  constructor(props) {
    super();
    this.FormBuilderService = new FormBuilderService();
    this.state = {
      errorMessage: '', showTextError: false, showImageEror: false,
      imageErrorMessage: '',
      hasHelpImage: props.element.hasHelpImage ? props.element.hasHelpImage : 
    	false,
      helpImageText: props.element.helpImageText? props.element.helpImageText : 
    	messages.LOREM_IPSUM
    };
  }

  onChange = (text) => {
    let plainText = text.replace(/(<([^>]+)>)/ig, '');
    let errorMessage = FormInputTypesValidationService.validateCommonAttributes(
    		ATTRIBUTES.INSTRUCTION_HELP_TEXT, plainText);
    this.setState({ 
    	errorMessage: errorMessage, 
    	showTextError: errorMessage.length > 0 ? true : false
    }, () => {
      if (this.state.errorMessage === '') this.updateState(text);
      else FormStore.sharedInstance().addErrorId(this.props.elementId);
    });
  }

  updateState(text) {
    this.setState({ helpImageText: text, showTextError: false }, () => {
      FormStore.sharedInstance().addHelpText(this.state.helpImageText);
      FormStore.sharedInstance().deleteErrorId(this.props.elementId);
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Box display="flex" flexDirection="column">
        <Box>
          <Box>
            <Grid container className={classes.container}>
              <SunEditor defaultValue={this.state.helpImageText} id="plainText" 
            	  height='auto' minHeight='50'
                  setOptions={{
                    buttonList: [
                      [
                        'bold', 'italic', 'underline', 'list', 'align', 
                        'fontColor', 'hiliteColor'
                      ]
                    ],
                    colorList: [
                      [
                        '#ff0000', '#ff5e00', '#ffe400', '#abf200', '#00d8ff', '#ffffff',
                        '#0055ff', '#6600ff', '#ff00dd', '#000000'
                      ]
                    ],
                    showPathLabel: false, resizingBar: false
                  }}
                  onChange={this.onChange} />
              {(this.state.errorMessage !== '' && this.state.showTextError) ? 
            	<label className={classes.error}>
              		{this.state.errorMessage}
            	</label> 
              : null}
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(InstructionHelp);