import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RouteWithLayout from './RouteWithLayout';
import AccessDenied from '../components/AccessDenied';
import { RoutesConfig } from './RoutesConfig';

const Routes = (props) => {
  const routes = RoutesConfig.routes;
  let userRole = '', redirectPath = '';
  userRole = props.userRole;

  if (userRole && userRole.length !== 0) {
    if (userRole === 'GLOBALADMIN') {
      redirectPath = '/createForm';
    } else if (userRole === 'ASSETMANAGER') {
      redirectPath = '/assets';
    } else if (userRole === 'SITEMANAGER' || userRole === 'TECHNICIAN') {
      redirectPath = '/review';
    } else {
      redirectPath = '/accessDenied';
    }
  } 

  return (
    <Switch>
      <Redirect exact from='/' to={redirectPath} />
      {props.userRole && routes.map((route, index) => (
        <RouteWithLayout component={route.component} exact layout={route.layout} path={route.path} userRole={props.userRole} roles={route.roles} key={index} />))}
      <Route component={AccessDenied} exact path="/accessDenied"/>
    </Switch>
  );
};

export default Routes;