import { phoneNumber, onlyNumber, maxLength, email } from './form.validators';
import { textRequired } from './form.validator.text';
import { dateRequired, hoursValidation } from './form.validator.date';
import { checkBoxRequired } from './form.validator.checkbox';
import { selectRequired } from './form.validator.select';

export const validationMap = (elementType) => {
  if (elementType) {
    if (elementType === 'text' || elementType === 'textarea' || elementType === 'barcode') {
      return {
        required: textRequired,
        email: email,
        maxLength: maxLength,
        numberOnly: onlyNumber,
        phoneNumber: phoneNumber,
      };
    }else if (elementType === 'date') {
      return {
        required: dateRequired,
        hashoursWorked: hoursValidation,
      };
    }else if (elementType === 'radio' || elementType === 'checkbox') {
      return {
        required: checkBoxRequired,
      };
    }else if (elementType === 'select') {
      return {
        required: selectRequired,
      };
    }
    else if (elementType === 'range') {
      //No validation for range currently.
    } 
  }
  return {};
};