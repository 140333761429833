import React, { Component, Fragment } from 'react';
import { AppBar, Toolbar, IconButton, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import logo from '.././shared/imgs/FW_logo.svg';
import { withStyles } from '@material-ui/styles';
import { routeState, FormStore } from '../services/form.store';
import AlertDialog from '../components/form-element/AlertDialog';
import { ALERT_DIALOGS } from '../services/form.helper.service';
import FormBuilderService from '../services/form-builder.service';
import { currentUserState } from '../services/session.manager';
import NotificationsNoneOutlined from '@material-ui/icons/NotificationsNoneOutlined';
import PeopleIcon from '@material-ui/icons/People';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Menu from '@material-ui/core/Menu';

const styles = theme => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  navLink: {
    padding: '20px',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  tmp: {
    border: 'red'
  },
  formLabel: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif']
  },
  imgStyle: {
    margin: '1%'
  },
  header: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center'
  },
  avatar: {
    flex: '0 0 auto',
    marginRight: '16px',
    width: '50px',
    height: '50px'
  },
  headerContent: {
    flex: '1 1 auto',
    color: '#419BAC'
  },
  title: {
    display: 'block'
  },
  subtitle: {
    marginTop: '7px',
    color: 'black'
  },
  actions: {
    backgroundColor: '#f6f6f6',
    top: 'auto',
    position: 'relative',
    width: '100%'
  },
  logout: {
    border: '2px solid #419BAC',
    color: '#419BAC'
  }
});

class Header extends Component {
  constructor() {
    super();
    this.state = {
      edited: false,
      showAlert: false,
      currentUrl: "",
      anchorEl: null,
      userName: '',
      user: {},
      showLogoutConfirmation: false,
      isReview: window.location.pathname === '/review' ? true : false
    }
    this.formBuilderService = new FormBuilderService();
  }

  componentDidMount() {
    this.routerSubscription = routeState.subscribe(isEdited => {
      this.setState({ edited: isEdited })
    });
    this.currentUserSubscription = currentUserState.subscribe(user => {
      this.setState({ user: user })
    });
  }

  componentWillUnmount() {
    this.routerSubscription.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }

  navLink = (url) => {
    this.setState({
      currentUrl: url
    })
    const { history } = this.props;
    if (this.state.edited) {
      this.setState({
        showAlert: true
      })
    }
    else {
      history.push(url);
    }
  }

  handleAccountOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleAccountClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleClose = () => {
    this.setState({
      showAlert: false,
      showLogoutConfirmation: false,
      anchorEl: null
    })
  };

  handleOk = () => {
    const { history } = this.props;
    history.push(this.state.currentUrl);
    this.setState({
      showAlert: false,
    });
    FormStore.sharedInstance().shouldNavigate(false);
  }

  handleLogout = () => {
    this.setState({
      showLogoutConfirmation: true
    })
  }

  handleConfirmLogout = () => {
    this.setState({
      showAlert: false,
      showLogoutConfirmation: false,
      anchorEl: null
    })
    localStorage.clear();
    FormStore.sharedInstance().updateLoggingState(false);
    window.location.href = process.env.REACT_APP_SSO_LOGOUT;
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <AppBar className={classes.root} color="secondary">
          <Toolbar>
            <Typography className={classes.formLabel} variant="inherit">
              NextGen FieldWork
            </Typography>
            {this.props.userRole === "ASSETMANAGER" ? (
              <Typography className={classes.formLabel} variant="inherit">
                NextGen FieldWork - Assets
              </Typography>
            ) : null}
            <div className={classes.flexGrow} />
            {this.props.userRole === "GLOBALADMIN" ? (
              <Button
                id="newFormButton"
                className={classes.navLink}
                color="inherit"
                onClick={() => this.navLink("/createForm")}
              >
                New Form
              </Button>
            ) : null}
            {this.props.userRole === "GLOBALADMIN" ? (
              <Button
                id="existingFormsButton"
                className={classes.navLink}
                color="inherit"
                onClick={() => this.navLink("/existingForms")}
              >
                Existing Forms
              </Button>
            ) : null}
            {this.props.userRole === "GLOBALADMIN" ? (
              <Button
                id="userGroupButton"
                className={classes.navLink}
                color="inherit"
                onClick={() => this.navLink("/usergroup")}
              >
                User Group
              </Button>
            ) : null}
            {this.props.userRole === "GLOBALADMIN" ? (
              <Button
                id="userManagementButton"
                className={classes.navLink}
                color="inherit"
                onClick={() => this.navLink("/users")}
              >
                Users
              </Button>
            ) : null}
            {this.props.userRole === "GLOBALADMIN" ? (
              <Button
                id="assetsButton"
                className={classes.navLink}
                color="inherit"
                onClick={() => this.navLink("/assets")}
              >
                Assets
              </Button>
            ) : null}
            {this.props.userRole === "GLOBALADMIN" ? (
              <Button
                id="reviewButton"
                className={classes.navLink}
                color="inherit"
                onClick={() => this.navLink("/review")}
              >
                Review
              </Button>
            ) : null}

            {(this.props.userRole === "GLOBALADMIN" ||
              this.props.userRole === "SITEMANAGER") ? (
              <Fragment>
                <IconButton onClick={this.clearSelected} color="inherit">
                  <NotificationsNoneOutlined fontSize="inherit" />
                </IconButton>
                <IconButton onClick={this.clearSelected} color="inherit">
                  <PeopleIcon fontSize="inherit" />
                </IconButton>
                <IconButton onClick={this.clearSelected} color="inherit">
                  <HelpOutlineIcon fontSize="inherit" />
                </IconButton>
              </Fragment>
            ) : null}
            {this.state.user.email ? (
              <Tooltip title={this.state.user.email}>
                <IconButton
                  id="profileImageIcon"
                  edge="end"
                  color="inherit"
                  aria-label="profile-menu"
                  onClick={this.handleAccountOpen}
                >
                  <Avatar id="avatar" alt="Profile Image" src="" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Toolbar>
          {this.state.showAlert ? (
            <AlertDialog
              id="alertDialog"
              open={this.state.showAlert}
              onClose={this.handleClose}
              handleOk={this.handleOk}
              dialogTitle={ALERT_DIALOGS.UNSAVED_CHANGES_TITLE}
              dialogContent={ALERT_DIALOGS.UNSAVED_CHANGES_CONTENT}
              cancelButton={ALERT_DIALOGS.UNSAVED_CHANGES_CANCEL}
              confirmButton={ALERT_DIALOGS.UNSAVED_CHANGES_CONFIRM}
            />
          ) : null}
        </AppBar>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleAccountClose}
          MenuListProps={{ disablePadding: true }}
        >
          <div className={classes.header}>
            <Avatar className={classes.avatar}></Avatar>
            <div className={classes.headerContent}>
              <span className={classes.title}>
                {this.state.user.userFirstName +
                  " " +
                  this.state.user.userLastName}
              </span>
              <span className={classes.title}>{this.state.user.sso}</span>
              <div className={classes.subtitle}>{this.state.user.userRole}</div>
            </div>
          </div>
          <Toolbar className={classes.actions}>
            <span className={classes.flexGrow} />
            <Button
              onClick={this.handleLogout}
              className={classes.logout}
              color="inherit"
              variant="outlined"
              size="small"
            >
              LOGOUT
            </Button>
          </Toolbar>
        </Menu>
        {this.state.showLogoutConfirmation ? (
          <AlertDialog
            open={this.state.showLogoutConfirmation}
            onClose={this.handleClose}
            handleOk={this.handleConfirmLogout}
            dialogTitle={ALERT_DIALOGS.LOGOUT_TITLE}
            dialogContent={ALERT_DIALOGS.LOGOUT_CONTENT}
            cancelButton={ALERT_DIALOGS.LOGOUT_CANCEL}
            confirmButton={ALERT_DIALOGS.LOGOUT_CONFIRM}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Header))
