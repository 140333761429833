import API from './http-helper.service';
import { Subject } from 'rxjs';

let accessToken = localStorage.getItem('access_token') ? 
	localStorage.getItem('access_token') : '';
let config = {};
if(accessToken && accessToken.length > 0)
  config = { headers:{ 'Authorization': 'Bearer ' + accessToken + '' } };

const endPoint = "category";
const categoryName = "&categoryName=";
const isMockFlow = false;
const subject = new Subject();
const language = new Subject();

export const categoryService = {
  didChangeCategory: () => subject.asObservable(),
  didChangeLanguage: () => language.asObservable(),
  initializeCategories: () => {
    initializeCategoryMap();
  },
  selectCategory: (category, value) => {
    var rootNode;
    if (category === rootCategory.key) {
      rootNode = rootCategory;
    } else {
      var ch = rootCategory.children;
      while (ch) {
        if (ch.key === category) {
          rootNode = ch;
          break;
        }
        ch = ch.children;
      }
    }

    if (rootNode) {
      var children = rootNode.children;
      while (children) {
        children.reset();
        children = children.children;
      }
      rootNode.onSelect(value);
    }
  },
  loadLanguages: () => {
    return API.get('language', config).then(res => {
      let languages = res.data;
      language.next(languages);
    });
  }
};

export class Category {
  constructor(category, children) {
    this.category = category;
    this.options = [];
    this.value = '';
    this.key = category.toLowerCase().replace('-','');
    if (children) {
      this.children = children;
      this.children.parent = this;
    }
  }

  reset = () => {
    this.value = '';
    this.options = [];
  }

  onSelect = (value) => {
    this.value = value;
    if (this.children) {
      this.children.value = '';
      this.children.loadNodes();
    }
  }

  loadNodes = () => {
    if (isMockFlow) {
      this.loadMockResponse();
      onStateChanged();
      return;
    }
    const categoryType = (this.parent) ? (this.parent.category) ? this.parent.category : this.category : '';
    var categoryName = (this.parent) ? (this.parent.value) ? this.parent.value : '' : '';
    const apiURL = formattedCategoryURL(categoryType, categoryName);
    this.options = [];
    API.get(apiURL, config).then (res => {
      var data = res.data;
      if (data) {
        if (this.category === categoryBusiness)  this.options = data;
        else {
          var subCategories;
          data.forEach((item) => {
            if (item.categoryName === categoryName) 
            	subCategories = item.subCategories;
          });
          if (subCategories) this.options = subCategories;
        }
      }
      onStateChanged();
    });
  }

  display = () => {
   if (this.category) {
     var value = {};
     value['options'] = this.options;
     value['value'] = this.value;
     return value;
   }
    return null;
  }

  loadMockResponse = () => {
    var response;
    if (this.category === categoryBusiness) {
      response =  mockBusiness;
    } else if (this.category === categorySubusiness) {
      response = subbusiness;
    } else if (this.category === categoryGroup) {
      response = mockGroup;
    } else if (this.category === categorySubGroup) {
      response =  mockSubGroup;
    }
    this.options = response;
    onStateChanged();
  }
};

const formattedCategoryURL = (category, name) => {
  var apiEndPoint = (category && category.length > 0) ? 
		  endPoint + '?categoryType=' + category : endPoint;
  if (name && name.length > 0) apiEndPoint = apiEndPoint + categoryName + name;
  return  apiEndPoint;
};

const categoryBusiness    = "Business";
const categorySubusiness  = "Sub-Business";
const categoryGroup       = "Group";
const categorySubGroup    = "Sub-Group";

var rootCategory;

const initializeCategoryMap = () => {
  var subGroup = new Category(categorySubGroup);
  var group = new Category(categoryGroup, subGroup);
  var subBusiness = new Category(categorySubusiness, group);
  var business = new Category(categoryBusiness, subBusiness);
  rootCategory = business;
  rootCategory.loadNodes();
};

const onStateChanged = () => {
  if (rootCategory) {
    var root = {};
    root[rootCategory.key] = rootCategory.display();
    var children = rootCategory.children;
    while (children) {
      root[children.key] = children.display();
      children = children.children;
    }
    subject.next(root);
  }
};

const mockBusiness = [
  {
    "id": 1,
        "categoryName": "Renewables",
        "categoryType": "Business",
        "subCategories": [
            {
                "id": 4,
                "categoryName": "Onshore Wind",
                "categoryType": "Sub-Business"
            },
            {
                "id": 5,
                "categoryName": "Offshore Wind",
                "categoryType": "Sub-Business"
            },
            {
                "id": 6,
                "categoryName": "Hydro",
                "categoryType": "Sub-Business"
            },
            {
                "id": 7,
                "categoryName": "Grid",
                "categoryType": "Sub-Business"
            }
        ],
        "lastUpdatedDate": 1589197115536
    },
    {
        "id": 2,
        "categoryName": "Power",
        "categoryType": "Business",
        "subCategories": [],
        "lastUpdatedDate": 1589197115536
    },
    {
        "id": 3,
        "categoryName": "Aviation",
        "categoryType": "Business",
        "subCategories": [],
        "lastUpdatedDate": 1589197115536
    }
];

const subbusiness = [
  {
                  "id": 4,
                  "categoryName": "Onshore Wind",
                  "categoryType": "Sub-Business"
              },
              {
                  "id": 5,
                  "categoryName": "Offshore Wind",
                  "categoryType": "Sub-Business"
              },
              {
                  "id": 6,
                  "categoryName": "Hydro",
                  "categoryType": "Sub-Business"
              },
              {
                  "id": 7,
                  "categoryName": "Grid",
                  "categoryType": "Sub-Business"
              }
];

const mockGroup = [
    {
        "id": 8,
        "categoryName": "Projects",
        "categoryType": "Group",
        "lastUpdatedDate": 1589197115536
    }
];

const mockSubGroup = [
  {
                  "id": 12,
                  "categoryName": "Receiving",
                  "categoryType": "Sub-Group"
              },
              {
                  "id": 13,
                  "categoryName": "Commissioning",
                  "categoryType": "Sub-Group"
              },
              {
                  "id": 14,
                  "categoryName": "Installation",
                  "categoryType": "Sub-Group"
              },
              {
                  "id": 15,
                  "categoryName": "SCADA",
                  "categoryType": "Sub-Group"
              }
];
