const answerNode = () => {
    return {
        createdBy: "dev",
        createdDate: new Date().getTime(),
        lastUpdatedBy: "dev",
        lastUpdatedDate: new Date().getTime(),
    };
};

export const textAnswer = (newValue) => {
    let node = answerNode();
    node.answerValue = newValue;
    return [node];
};

export const dateAnswer = (newValue, answer) => {
    if (answer && answer[0] !== undefined) {
        answer[0].answerValue = newValue
        answer[0].lastUpdatedDate = new Date().getTime()
        return answer
    } else {
        let node = answerNode();
        node.answerValue = newValue;
        return [node];
    }
};

export const hoursAnswer = (newValue, answer) => {
    if (answer[0] !== undefined) {
        answer[0].answerDescription = newValue
        answer[0].lastUpdatedDate = new Date().getTime()
        return answer
    } else {
        let node = answerNode();
        node.answerDescription = newValue;
        return [node];
    }
};

export const radioButtonAnswer = (value, comment, image) => {
    let node = answerNode();
    node.answerValue = value;
    // Checking for comment and image is required, because both keys may or may not have value.
    // 'answer' : [ { image: […], comment: 'sample comment', ... } ]
    if (comment !== "") {
      node.comment = comment;
    }
    if (image.length > 0) {
      node.image = image;
    }
    return [node];
  };

export const checkBoxAnswer = (values) => {
    let answers = []
    values.forEach((value) => {
        let node = answerNode()
        node.answerValue = value
        answers.push(node)
    })
    return answers
}

export const dropDownAnswer = (values, comment, image) => {
    let answers = []
    values.forEach((value) => {
        let node = answerNode();
        node.answerValue = value;
        // Checking for comment and image is required, because both keys may or may not have value.
        // 'answer' : [ { image: […], comment: 'sample comment', ... } ]
        if(comment!==""){
            node.comment = comment;
        }
        if(image.length>0){
            node.image = image; 
        }
        answers.push(node)
    });
    return answers
}

export const rangeAnswer = (value) => {
    let node = answerNode();
    node.answerValue = value;
    return [node];
};

export const addAnswer = (answer, elementId, form) => {
    if (answer && elementId && form && form.children) {
        form.children.forEach((child) => {
            child.children.forEach((sectionChild) => {
                if (sectionChild.children && sectionChild.children.length > 0) {
                    sectionChild.children.forEach((subSectionChild) => {
                        if (subSectionChild.id === elementId) {
                            subSectionChild.answer = answer;
                        }
                    });
                } else {
                    if (sectionChild.id === elementId) {
                        sectionChild.answer = answer;
                    }
                }
            });
        });
    }
    return form;
};
