export const DEFAULT_LANGUAGE_LIST = 'defaultLanguageList';
export const NA = 'N/A';
export const COMPONENT_TYPE = 'componentType';
export const DATE_TIME_FORMATS = {
    DD_MM_YY: 'DD/MM/YY',
    DD_MM_YY_SMALL: 'dd/MM/yy',
    DD_MM_YYYY: "DD/MM/YYYY",
    DD_MM_YYYY_SMALL: "dd/MM/yyyy",
    MM_DD_YY: "MM/DD/YY",
    MM_DD_YY_SMALL: "MM/dd/yy",
    MM_DD_YYYY: "MM/DD/YYYY",
    MM_DD_YYYY_SMALL: "MM/dd/yyyy",
    DD_MM_YYYY_H_MM_SS: "DD/MM/YYYY h:mm:ss",
    DD_MM_YYYY_H_MM_SS_SMALL: "dd/MM/yyyy h:mm:ss",
    YYYY_MM_DD_H_MM_SS: "YYYY/MM/DD h:mm:ss",
    YYYY_MM_DD_H_MM_SS_SMALL: "yyyy/MM/dd h:mm:ss",
    YYYY_MM_DD_H_MM_SS_A: "YYYY/MM/DD h:mm:ss a",
    YYYY_MM_DD_H_MM_SS_A_SMALL: "yyyy/MM/dd h:mm:ss a",
    YYYY_MM_DD_H_MM_A: "YYYY/MM/DD h:mm a",
    YYYY_MM_DD_H_MM_A_SMALL: "yyyy/MM/dd h:mm a",
    DD_MMM_YYYY: "DD/MMM/YYYY",
    DD_MMM_YYYY_SMALL: "dd/MMM/yyyy",
    DD_MMM_YYYY_H_MM_SS: "DD/MMM/YYYY h:mm:ss",
    DD_MMM_YYYY_H_MM_SS_SMALL: "dd/MMM/yyyy h:mm:ss",
    DD_MMM_YYYY_H_MM_SS_A: "DD/MMM/YYYY h:mm:ss a",
    DD_MMM_YYYY_H_MM_SS_A_SMALL: "dd/MMM/yyyy h:mm:ss a",
    DD_MMM_YYYY_H_MM_A: "DD/MMM/YYYY h:mm a",
    DD_MMM_YYYY_H_MM_A_SMALL: "dd/MMM/yyyy h:mm a",
    YYYY_MM_DD_HH_MM_SS_SSSZ:"yyyy/MM/DD HH:mm:ss.SSSZ",
    YYYY_MM_DD_HH_MM_SS_SSSZ_SMALL:"yyyy/MM/dd hh:mm:ss.sssz",
    DD_MMM_YYYY_HH_MM_SS_A:"DD/MMM/yyyy HH:mm:ss a",
    DD_MMM_YYYY_HH_MM_SS_A_SMALL:"dd/MMM/yyyy hh:mm:ss a",
    MM_DD_YYYY_HH_MM_A:"MM/DD yyyy hh:mm a",
    MM_DD_YYYY_HH_MM_A_SMALL:"MM/dd yyyy hh:mm a"
}
