import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Header from '.././components/Header';
import Sidebar from '.././shared/component/Sidebar';

const useStyles = makeStyles( theme => ({
  root: {
    paddingTop: 69,
    height: '100%'
  },
  shiftContent: {
    minHeight: `calc(100% - 69px)`,
    paddingLeft: 300
  }
}));

const Main = props => {
  const{children} = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header userRole={props.userRole}/>
      {<Sidebar open variant='persistent'/>}
      <main className={classes.shiftContent}>
        {children}
      </main>      
    </div>
  )
};

export default Main;