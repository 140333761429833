import MuiButton from './MuiButton';
import MuiPaper from './MuiPaper';
import MuiToggleButtonGroup from './MuiToggleButtonGroup';
import MuiChip from './MuiChip';

export default {
  MuiButton,
  MuiPaper,
  MuiToggleButtonGroup,
  MuiChip,
};
