
export const dateHelperService = {

  formattedDateFromUTS: (unixTimestamp) => {
    const dateObj = new Date(unixTimestamp);
    const formatted = hiphenSeperatedDate(dateObj);
    return formatted;
  }

}

const hiphenSeperatedDate = (dat) => {
    let today = new Date(dat);
    let month = "" + (today.getMonth() + 1);
    if (today.getMonth() + 1 < 10) { month = "0" + month; }

    let day = "" + (today.getDate());
    if (today.getDate() < 10) { day = "0" + day; }

    return day + "-" + month + "-" + today.getFullYear();
};
