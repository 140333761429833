import { TextField, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';

export default class HoursWorkedInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  }

  render() {
    return (
      <Fragment>
          <Typography>Hour(s) Worked</Typography>
          <TextField value={this.props.defaultValue} onChange={this.onChange}>
          </TextField>
      </Fragment>
    );
  }
}
