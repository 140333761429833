import { Checkbox, FormControl, Grid, Input, ListItemText, MenuItem, Select, Typography, Avatar, Box, Card } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import ImagePopup from './ImagePopup';
import { currentSectionState, openSectionState } from '../../store/form.store';
import { extractDropDownValue, extractCommentValue, extractImageValue } from '../../store/form.answer.extract';
import { dropDownAnswer } from '../../store/form.answer.fill';

const styles = theme => ({
  userResponseFont: {
    fontSize: '15px'
  },
  formControl: {
    borderRadius: 4,
    border: '1px solid grey',
    padding: '10px',
    width: '92%'
  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  commentText: {
    color: '#00000094',
    fontSize: '14px',
  },
});

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: [],
      editable: false,
      imageValue: [],
      options: this.props.element.options,
      checkedAllOptions: false,
      optionIds: [],
      isMultiple: this.props.element.properties.multiple,
      commentValue: '',
    };
  }

  componentDidMount() {
    let expanded = ''
    this.sectionStateSubscription = openSectionState.subscribe(sectionId => { expanded = sectionId });
    this.currentSectionSubscription = currentSectionState.subscribe(section => {
      this.setState({ editable: section === expanded })
    });
    this.onLoadPage(this.props);
    if (this.props.element.properties.allowFileUpload || this.props.element.properties.conditional) {
      this.onLoadImage(this.props);
    }
    if(this.props.element.properties.conditional){
      this.onLoadComment(this.props)
    }
    let options = [];
    this.props.element.options.forEach((option) => {
      if (!options.includes(option)) {
        options.push(option);
      }
    });
    this.setState({
      options: options
    });
  }

  componentWillUnmount() {
    this.sectionStateSubscription.unsubscribe();
    this.currentSectionSubscription.unsubscribe();
  }

  onLoadImage(props) {
    if(props.element){
      let image = props.element?.answer;
      let imageResponse = extractImageValue(image);
      if (imageResponse) {
          this.setState({
              imageValue: imageResponse
          });
      }
    }
  }

  onLoadComment(props) {
    if (props.element) {
        let answer = props.element?.answer;
        let commentResponse = extractCommentValue(answer);
        if (commentResponse) {
            this.setState({
                commentValue: commentResponse
            });
        }
    }
}

  onLoadPage(props) {
    if (props.element) {
      let answer = props.element.answer;
      let userResponse = extractDropDownValue(answer);
      if (userResponse) {
        this.setState({
          selectedValues: userResponse,
        });
      }
    }
  }

  onChangeOption = (event) => {
    let options = [];
    if (event.target.value.includes('all')) {
      if (this.state.checkedAllOptions) {
        this.setState({
          checkedAllOptions: false,
          optionIds: options,
        });
      } else {
        options = this.state.options;
        this.setState({
          checkedAllOptions: true,
          optionIds: options,
        });
      }
    } else {
      if (this.state.isMultiple) {
        options = event.target.value;
      }
      else {
        options = [event.target.value];
      }
      this.setState({
        optionIds: options,
        checkedAllOptions: false,
      });
    }
    if (this.props.onChange !== undefined) {
      this.props.onChange(dropDownAnswer(options, this.state.commentValue, this.state.imageValue), this.props.element);
    }
  }

  selectedOptions = (values) => {
    return values?.length
        ? Array.isArray(values)
          ? values.join(", ")
          : values
        : "Please select a value";
  };

  onClickAvatar = (image) => {
    this.setState({
        imageOpen: true,
        imageUrl: image.imageUrl,
        imageName: image.imageName
    });
  }
  onClose = () => {
    this.setState({ imageOpen: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {this.state.editable ?
          <Grid item md={12} xs={12} >
            <FormControl fullWidth variant="outlined" className={classes.formControl}
              style={{ border: (this.props.hasError && (!this.state.optionIds.length)) ? '1px solid red' : '1px solid grey' }}>
              <Select label='Select Option' className={classes.underline}
                id='options' name='options' multiple={this.state.isMultiple}
                value={this.state.optionIds?.length ? this.state.optionIds.join(", ") : this.state.selectedValues.join(", ")}
                onChange={this.onChangeOption} input={<Input value={this.state.optionIds?.length ? this.state.optionIds.join(", ") : this.state.selectedValues.join(", ")} />}
                displayEmpty={true}
                renderValue={(selected) => this.selectedOptions(selected)}
                disabled={!this.state.options.length}>
                {(this.state.options.length > 0) && (this.state.isMultiple) ?
                  <MenuItem key="all" value="all">
                    <Checkbox checked={this.state.checkedAllOptions} />
                    <ListItemText primary="Select All" />
                  </MenuItem> : null}

                {this.state.options.map((option, index) => (
                  (typeof option === 'object' && option !== null) ?
                  <MenuItem key={index} value={option.value}>
                    {this.state.isMultiple ? <Checkbox checked={this.state.optionIds.indexOf(option.value) > -1} /> : null}
                    <ListItemText primary={option.value} />
                  </MenuItem>
                 :
                  <MenuItem key={index} value={option}>
                    {this.state.isMultiple ? <Checkbox checked={this.state.optionIds.indexOf(option) > -1} /> : null}
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> :
          <Grid item md={12} xs={12} >
          <Typography className={classes.userResponseFont}>
            {this.state.selectedValues.map((selectedValue, index) => {
              return index !== this.state.selectedValues.length - 1
                ? selectedValue + ', '
                : typeof selectedValue === 'string'
                  ? selectedValue
                  : selectedValue.name + ' ';
            })}
          </Typography>
          </Grid>}
          <Grid item md={12} xs={12}>
          {((this.props.element.properties.allowFileUpload || this.props.element.properties.conditional) && this.state.imageValue.length>0) ? this.state.imageValue.map((image) => (
                <Card className={classes.root} style={{ marginTop: '10px'}}>
                    <Box>
                        <Grid container spacing={1}>
                            {image ? <Avatar alt={image?.imageName} src={image?.imageUrl} variant="square" style={{ height: '75px', width: '100px' }}
                                onClick={image?.imageUrl!=="" ? () => this.onClickAvatar(image) : null} /> : ""}
                        </Grid>
                    </Box>
                </Card>
            )) : null}
            <ImagePopup open={this.state.imageOpen} onClose={this.onClose} imageUrl={this.state.imageUrl} title={this.state.imageName}></ImagePopup>
            {(this.props.element.properties.conditional && this.state.commentValue !== "") ? <Typography className={classes.commentText}>{this.state.commentValue}</Typography> : null}
          </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(Dropdown);