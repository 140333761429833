import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import ShortTextOutlinedIcon from '@material-ui/icons/ShortTextOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import NotesOutlinedIcon from '@material-ui/icons/NotesOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import LinearScaleOutlinedIcon from '@material-ui/icons/LinearScaleOutlined';
import GestureOutlinedIcon from '@material-ui/icons/GestureOutlined';
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  listHeader: {
    marginTop: theme.spacing(1),
    lineHeight: '16px',
    letterSpacing: 1,
  },
  icon: {
    marginRight: theme.spacing(2),
    minWidth: 32,
  },
  sideNavDivider: {
    marginLeft: 62,
  },
});

const items = [
  {
    name: 'MOST USED',
    children: [
      { name: 'Single Line Input', Icon: ShortTextOutlinedIcon, component: 'text' },
      { name: 'Paragraph - Text Area', Icon: NotesOutlinedIcon, component: 'textarea' },
      { name: 'Radio Group', Icon: RadioButtonCheckedOutlinedIcon, component: 'radio' },
      { name: 'Checkbox', Icon: CheckBoxOutlinedIcon, component: 'checkbox' },
      { name: 'Dropdown List', Icon: ArrowDropDownCircleOutlinedIcon, component: 'select' },
    ]
  },
  {
    name: 'OTHER',
    children: [
      { name: 'Date / Time', Icon: TodayOutlinedIcon, component: 'date' },
      { name: 'Range Slider', Icon: LinearScaleOutlinedIcon, component: 'range' },
      { name: 'File Uploader', Icon: CloudUploadOutlinedIcon, component: 'fileInput' },
      ]
  },
  {
    name: 'CUSTOM',
    children: [
      { name: 'Dependent Dropdown', Icon: DnsOutlinedIcon, component: 'dependentDropdown' },
      { name: 'Barcode Scanner', Icon: CropFreeOutlinedIcon, component: 'barcode' },
      { name: 'Signature', Icon: GestureOutlinedIcon, component: 'signature' },
      { name: 'Instruction / Help', Icon: AssignmentOutlinedIcon, component: 'helpimage' },
      ]
  }
];

class FormElementsList extends Component {

  constructor(props) {
    super();
    this.state = {
      items: items
    }
  }

  onItemClick = (element) => {    
    this.props.onItemClick(element);
  }

  render() {
    const { classes } = this.props;
    return (
      <List>
        {this.state.items.map(({...item}, index) => (
          <Fragment key={index}>
            <List className={classes.root} subheader={<ListSubheader className={classes.listHeader}>{item.name}</ListSubheader>}></List>
            <List>
              {item.children.map(child => (
                <Fragment key={child.name}>
                  <ListItem key={child.name} button onClick={(event) => this.onItemClick(child)}>
                    <ListItemIcon className={classes.icon}>
                      <child.Icon />
                    </ListItemIcon>
                    <ListItemText primary={child.name} />
                  </ListItem>
                  <Divider light className={classes.sideNavDivider} />
                </Fragment>
              ))}
            </List>
          </Fragment>
        ))}
      </List>
    )
  }

}

export default withStyles(styles)(FormElementsList);
