import React, { Fragment } from 'react';
import { IconButton, Grid, Menu } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { withStyles } from '@material-ui/styles';
import { FormStore } from '../../../services/form.store';
import AnswerLogicMenu from '../menu/AnswerLogicMenu';
import { QUESTION_LOGIC, LOGIC_TYPES } from '../../../services/form.helper.service';

const styles = theme => ({
    flexGrow: {
        flexGrow: 1,
    },
    optionDiv: {
        borderRadius: 0,
        border: "0.8px solid rgba(16,22,26,.2)",
        padding: "2px",
        backgroundColor: "rgba(91, 195, 115, .1)",
        color: "rgb(81, 90, 100)"
    }
});

class ActiveQuestionLogicOptions extends React.Component {
    constructor(props) {
        super();
        this.state = { options: props.options };
    }

    componentDidMount() {
      const logicOptions = this.getLogicOptions(this.props.options);
      this.setState({ options: logicOptions });
    }

    componentWillReceiveProps() {
      const logicOptions = this.getLogicOptions(this.props.options);
      this.setState({ options: logicOptions });
    }

    componentDidUpdate(prevProps) {
      if (prevProps.options !== this.props.options) {
        const logicOptions = this.getLogicOptions(this.props.options);
        this.setState({
          options: logicOptions
        });
      }
    }

    componentWillUnmount() { }

  getLogicOptions = (inOptions) => {
    if (inOptions) {
      let options = [];
      var actionNames = [];
      inOptions.forEach((item) => {
    	  actionNames = [];
        const enabledActions = FormStore.sharedInstance().actionsForOption(item.id);
        if (enabledActions) {
          enabledActions.forEach((action) => {
            let label;
            if (action.name === QUESTION_LOGIC.HIDE.name || 
            		action.name === QUESTION_LOGIC.SHOW.name) {
              if (!actionNames.includes(LOGIC_TYPES.SHOW_HIDE_QUESTIONS.label)) 
            	  label = LOGIC_TYPES.SHOW_HIDE_QUESTIONS.label;
            } else label = action.label;
            if (label && !actionNames.includes(label)) actionNames.push(label);
          });
        }
        if(actionNames.length === 0) actionNames = ['No Action'];
        options.push({id: item.id, options: actionNames});
      });
      return options;
    }
  }

    handleClick = (index) => (event) => {
      FormStore.sharedInstance().showConditionalsMenu(index);
      this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = (event) => {
      FormStore.sharedInstance().hideConditionalsMenu();
      this.setState({ anchorEl: null });
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
            {this.state.options.map((child, index) => (
              <Grid key={"p" + index} container className={classes.optionDiv} 
              	direction="row" alignItems="center" spacing={0}>
                <Grid key={"" + index} item 
                  style={{width: '80%', padding:'10px'}}>
                  {child.options ? child.options.join(", ") : 'No Action'}
                </Grid>
                <Grid item style={{ width: '20%' }}>
                <IconButton id="iconButton" edge="end" aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={this.handleClick(index)}>
                  <MoreHorizIcon />
                </IconButton>
	                <Menu elevation={3} id="menu" anchorEl={this.state.anchorEl} 
	                	keepMounted open={Boolean(this.state.anchorEl)}
	                	onClose={this.handleClose} getContentAnchorEl={null}
	                	anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
	                	transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
	                  { <AnswerLogicMenu id={"AnswerLogicMenu" + index} index={index}
	                  	 options={this.state.options}/> }
                  </Menu>
                </Grid>
              </Grid>
            ))}
          </Fragment>
        )
    }
}

export default withStyles(styles)(ActiveQuestionLogicOptions);