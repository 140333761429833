import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Chip, MenuItem, TextField, withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { Box, Grid, Typography, ListItemText, Checkbox } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormBuilderService from '../../services/form-builder.service';
import { toast } from 'react-toastify';
import { FormStore, turbinesState } from '../../review-portal/store/form.store';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormFillerService from '../../services/review-portal-services/form-filler.service';
import { handleSiteResponseAndSorting } from '../../utility/CommonUtils';

const styles = theme => ({
  formLabel: {
    padding: "0px 2px"
  },
  asterisk: {
    color: 'red'
  },
  formControl: {
    margin: theme.spacing(1),
    borderRadius: 4,
    border: '1px solid grey',
    padding: '10px',
    width: '100%'
  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  errorText: {
    color: '#f44336'
  },
  MuiPaper: {
    width: '75%'
  }
})

class AddAssetMapping extends Component {
  constructor(props) {
    super();
    this.state = {
      siteArray: [],
      sites: [],
      sitesOptionList:[],
      turbines: [],
      optionalEquipments: [],
      turbineIds: [],
      equipmentIds: [],
      showTurbine: false,
      showNoTurbineMesg : false,
      showEquipment: false,
      checkedAllTurbines: false,
      checkedAllEquipments: false,
      selectedSite: props.selectedSite ? props.selectedSite : null,
      selectedSiteId: props.selectedSiteId,
      selectedOptionalEquipment: '',
      hasError: false,
      filteredData: []
    };
    this.formBuilderService = new FormBuilderService();
    this.formFillerService = new FormFillerService();
  }

  componentDidMount() {
    this.formFillerService.getAllSites().then(response => {
      this.setState({
        sites: response,
        sitesOptionList:handleSiteResponseAndSorting(response)
      },() => {
        if (this.state.selectedSiteId) {
          this.getTurbineList(this.props.selectedSiteId);
        }
      });
    });
    this.formBuilderService.getAllOptionalEquipments().then(response => {
      this.setState({
        optionalEquipments: response.data
      });
    });
  }

  onChangeSite = (_event, value) => {
    if (value) {
      this.setState({
        selectedSite: this.state.sites.find(val=> val.siteId===value.id),
        selectedSiteId: value.id
      });
      if (value.id !== undefined) {
        this.getTurbineList(value.id);
      }
    }
  }

  getTurbineList(value) {
    FormStore.sharedInstance().getAllTurbinesBySiteId(value);
    this.turbineStateSubscription = turbinesState.subscribe((turbines) => {
      if (turbines) {
        const arr = turbines;
        const turbineIdArray = arr.map(turbine => turbine.windTurbineId);
        const filteredTurbineArray = arr.filter(({ windTurbineId }, index) => !turbineIdArray.includes(windTurbineId, index + 1));
        this.setState({
          showTurbine: true,
          turbines: filteredTurbineArray
        });
      } else {
        this.setState({
          showTurbine: false,
          showNoTurbineMesg: true
        });
      }
    });
  }


  onChangeTurbine = (event, value) => {
    if (value !== undefined) {
      this.setState({ showEquipment: true });
      if (value.includes('all')) {
        if (this.state.checkedAllTurbines) {
          this.setState({
            checkedAllTurbines: false,
            turbineIds: []
          });
        } else {
          this.setState({
            checkedAllTurbines: true,
            turbineIds: this.state.turbines.map(turbine => turbine.windTurbineId)
          });
        }
      } else {
        this.setState({
          turbineIds: value.map(turbine => turbine.windTurbineId),
          checkedAllTurbines: false
        });
      }
    } else {
      this.setState({ showEquipment: false });
    }
  }

  onChangeEquipment = (event) => {
    if (event.target.value !== undefined) {
      if (event.target.value.includes('all')) {
        if (this.state.checkedAllEquipments) {
          this.setState({
            checkedAllEquipments: false,
            equipmentIds: []
          });
        } else {
          this.setState({
            checkedAllEquipments: true,
            equipmentIds: this.state.optionalEquipments.map(option => option.optionalEquipId)
          });
        }
      } else {
        this.setState({
          equipmentIds: event.target.value,
          checkedAllEquipments: false
        });
      }
    }
  }

  selectedTurbines = (values) => {
    let selectedTurbineArray = this.state.turbines.filter(turbine => values.includes(turbine.windTurbineId)).map(turbine => turbine.properties.shortName)
    return selectedTurbineArray.join(',');
  }

  selectedEquipments = (values) => {
    let selectedEquipmentArray = this.state.optionalEquipments.filter(option => values.includes(option.optionalEquipId)).map(option => option.equipmentName)
    return selectedEquipmentArray.join(',');
  }

  formSubmitHandler = () => {
    const isValid = this.validatePopup();
    if (isValid) {
      var payload = {};
      payload.siteId = this.state.selectedSiteId;
      payload.turbineId = this.state.turbineIds;
      payload.optionalEquipId = this.state.equipmentIds;
      this.formBuilderService.createNewMapping(payload).then((res) => {
        if (res.data) {
          toast.success("Mapping created successfuly!");
          this.formBuilderService.getOptionalEquipmentMapping(this.state.selectedSiteId).then(response => {
            if (response.data) {
              this.props.updateFilteredData(response.data);
              this.setState({
                filteredData: response.data
              });
            }
          });
        } else {
          toast.error("Mapping could not be created due to errors!")
        }
      });
      this.props.onClose();
    }
    this.setState({
      hasError: true
    });
  }

  validatePopup = () => {
    var valid = false;
    if (this.state.selectedSiteId && this.state.turbineIds.length && this.state.equipmentIds.length) {
      valid = true;
    }
    return valid;
  }

  getHelperTextForRequiredField = (value, type) => {
    let helperText = '';
    if (this.state.hasError) {
      if (type === 'site' && !value) {
        helperText = 'Site is required!';
      } else if (type === 'turbine' && !value.length) {
        helperText = 'Turbine is required!';
      } else if (type === 'equipment' && !value.length) {
        helperText = 'Optional Equipment is required!';
      } else {
        return;
      }
    }
    return helperText;
  }

  onEnterSearch = (event) => {
    if (event.key === 'Enter') {
        event.stopPropagation();
    }
  }

  render() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const { classes } = this.props;
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onClose} classes={{ paper: classes.MuiPaper }} disableBackdropClick
          aria-labelledby="form-dialog-title">
          <DialogTitle id="dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <Box p={3}>
              <Grid container spacing={3} >
                <Grid item md={12} xs={12} >
                  <Typography className={classes.formLabel}>Site <span className={classes.asterisk}>*</span></Typography>
                  <Autocomplete
                    id='siteMappingId'
                    options={this.state.sitesOptionList}
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={this.onChangeSite}
                    style={{ width: "100%" }}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.label}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="SitesTextField"
                        variant="outlined"
                        placeholder="Select Sites"
                        onKeyDown={this.onEnterSearch}
                      />
                    )}
                  />
                  <FormHelperText className={classes.errorText}>{this.getHelperTextForRequiredField(this.state.selectedSite, 'site')}</FormHelperText>
                </Grid>

                {this.state.showTurbine ?
                  <Grid item md={12} xs={12} >
                    <Typography className={classes.formLabel}>Turbines <span className={classes.asterisk}>*</span></Typography>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={this.state.turbines}
                      onChange={this.onChangeTurbine}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.properties.shortName}
                      renderTags={(value, getTagProps) => value.map((option, index) =>
                      (<Chip color="secondary"
                        id="chip"
                        size="small"
                        label={option.properties.shortName} {...getTagProps({ index })} />))}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            id="checkboxIcon"
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected} />
                          {option.properties.shortName}
                        </React.Fragment>)}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params}
                          id="turbineTextField"
                          variant="outlined"
                          placeholder="Select Turbines"
                          required />
                      )} />
                    <FormHelperText className={classes.errorText}>{this.getHelperTextForRequiredField(this.state.turbineIds, 'turbine')}</FormHelperText>
                  </Grid> : null}

                {this.state.showEquipment ?
                  <Grid item md={12} xs={12} >
                    <Typography className={classes.formLabel}>Optional Equipment <span className={classes.asterisk}>*</span></Typography>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}
                      style={{ border: (this.state.hasError && (!this.state.equipmentIds.length)) ? '1px solid red' : '1px solid grey' }}>
                      <Select label='Optional Equipments' multiple className={classes.underline}
                        name='optionalEquipments' id='optionalEquipments'
                        value={this.state.equipmentIds}
                        onChange={this.onChangeEquipment} input={<Input />}
                        renderValue={(selected) => this.selectedEquipments(selected)}
                        error={this.state.hasError && !this.state.equipmentIds.length}
                        disabled={!this.state.optionalEquipments.length}>
                        {(this.state.optionalEquipments.length > 0) ?
                          <MenuItem key='all' value='all'>
                            <Checkbox checked={this.state.checkedAllEquipments} />
                            <ListItemText primary='Select All' />
                          </MenuItem> : <MenuItem key='none' value='none'>None</MenuItem>}
                        {this.state.optionalEquipments.map((option) => (
                          <MenuItem key={option.optionalEquipId} value={option.optionalEquipId}>
                            <Checkbox checked={this.state.equipmentIds.indexOf(option.optionalEquipId) > -1} />
                            <ListItemText primary={option.equipmentName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormHelperText className={classes.errorText}>{this.getHelperTextForRequiredField(this.state.equipmentIds, 'equipment')}</FormHelperText>
                  </Grid> : null}
              </Grid>
              {(this.state.turbines.length === 0 && this.state.showNoTurbineMesg) ? <Typography className={classes.errorText}>No turbines mapped with the selected site!</Typography> : null}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} variant="contained" id="cancel" color="secondary">Cancel</Button>
            <Button onClick={this.formSubmitHandler} variant="contained" id="save">Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddAssetMapping);