import FormBuilderService from "./form-builder.service";
import { debugState, userObject } from "../utility/Constant";
import { FormStore } from "./form.store";
import { SessionManager } from "./session.manager";

const formBuilderService = new FormBuilderService();

export class UserInfoService {
  async getUserInfo(userSso) {
    let user = {}, userSSO = '';
    if (debugState) {
      userSSO = userObject.sub;
      localStorage.setItem('ge_sso', userSSO);
    } else {
      let ge_sso = localStorage.getItem('ge_sso');
      if (ge_sso === null || ge_sso === undefined || ge_sso === '') {
        userSSO = userSso;
      } else {
        userSSO = ge_sso;
      }
    }
    await formBuilderService.getUserDetailsBySSO(userSSO).then((response) => {
      if (response.data) {
        user = response.data;
        localStorage.setItem('user_role', user.userRole ? user.userRole : '');
        SessionManager.sharedInstance().updateCurrentUser(user);
      }
    });
   
    FormStore.sharedInstance().updateLoggingState(true);
    return user;
  }
}