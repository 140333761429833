import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { FormStore } from '../../services/form.store';
import { QUESTION_LOGIC  } from '../../services/form.helper.service';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SECTION, SUB_SECTION } from '../../utility/Constant';

const HIDE = 'hide';
const SHOW = 'show';

export default function QuestionDialog(props) {
  const { onClose, open } = props;
  const [ questionsList, setQuestionsList ] = useState([]);
  const [ checkboxItems, setCheckboxItems ] = useState({});
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(4)
    },
    dialogPaper: {
      minHeight: '70%',
      maxHeight: '70%',
      minWidth: '60%'
    },
    dialogContent: {
      '&::-webkit-scrollbar': {
        width: "6px"
      },
      '&::-webkit-scrollbar-track': {
        background: "#FFFFFF"
      },
      '&::-webkit-scrollbar-thumb' :{
        background: "#D3D3D3" 
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: "#888" 
      }
    },
    dialogTitle: {
      backgroundColor: '#F7F7F7',
      padding: '21px 0 15px 24px',
      maxHeight: '64px'
    },
    dialogFooter: {
      backgroundColor: '#F7F7F7',
      padding: '14px 25px 14px 0',
      maxHeight: '64px'
    },
    formLabel: {
      color: "#8A8F98",
      padding: "15px 0 11px 25px"
    },
    cancelButton: {
      display: 'flex',
      maxWidth: '103px',
      maxHeight: '36px',
      padding: '11px 25px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      flexShrink: 0,
      borderRadius: '35px',
      background: '#626770',
      color: '#FFFFFF',
      '&:hover': {
        background: '#626770',
        color: '#FFFFFF'
      }
    },
    confirmButton: {
      display: 'flex',
      maxWidth: '120px',
      maxHeight: '36px',
      padding: '11px 25px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      borderRadius: '35px',
      background: '#51C56C',
      color: '#FFFFFF',
      '&:hover': {
        background: '#51C56C',
        color: '#FFFFFF'
      }
    },
    accordionSeperator: {
      marginBottom: '10px',
      borderRadius: '4px',
      '&::before': {
        backgroundColor: 'unset'
      }
    },
    accordionDetails: {
      display: 'block',
      padding: '7px 0 16px 0',
    },
    accordionHeader: {
      display: 'flex',
      maxHeight: 40,
      padding: '0px 16px',
      alignItems: 'center',
      gap: 10,
      flexShrink: 0,
      backgroundColor: '#EBECED',
      color: '#2C2C2C',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    accordionFormLabel: {
      display: 'flex',
      margin: '0 0 12px 5px'
    },
    accordionLabel: {
      color: '#2C2C2C',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    subSectionAccordion: {
      marginLeft: '15px !important',
      marginRight: '15px !important',
    },
    subSectionAccordionFormLabel: {
      marginLeft: '16px'
    },
    subSectionAccordionLabel: {
      color: '#2C2C2C',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    subSectionAccordionBottom: {
      paddingBottom: 'unset'
    }
  }));

  const setDefaultValues = () => {
	  if(open) {
		  let questionsList = FormStore.sharedInstance().getQuestionsList();
      const { form, visibleElementsIds } = questionsList;
      const sectionIds = form?.children?.map((section) => section?.elementId);
      // Retrieve the current values from the form object to preserve the checkbox selections.
      let conditional = FormStore.sharedInstance().selectedAction(props.id);
      const conditionallyHiddenIds = [];
      conditional?.actions?.forEach((action) => {
        if (action?.name === HIDE) {
          if (action?.targets?.length) {
            action?.targets?.forEach((target) => {
              conditionallyHiddenIds.push(target?.elementId);
            })
          }
        }
      });
      if (visibleElementsIds?.length) {
        const computedCheckboxItems = {};
        visibleElementsIds?.forEach((elementId) => {
          if (conditionallyHiddenIds?.length) {
            if (conditionallyHiddenIds?.includes(elementId)) {
              if (!sectionIds?.includes(elementId)) computedCheckboxItems[elementId] = true;
            } else {
              if (!sectionIds?.includes(elementId)) computedCheckboxItems[elementId] = false;
            }
          } else {
            if (!sectionIds?.includes(elementId)) computedCheckboxItems[elementId] = false;
          }
        });
        setCheckboxItems(computedCheckboxItems);
      }
	    setQuestionsList(questionsList);
	  }
  }

  const handleCancel = () => {
    onClose();
  };

  const handleAnsLogicState = () => {
    const checkedItemsArr = Object.keys(checkboxItems).reduce((acc, cur) => {
      if (checkboxItems[cur] === true) {
        acc.push({ elementId: cur });
      }
      return acc;
    }, []);
    props.updateCheckedQuestions(checkedItemsArr);
  };

  const handleSave = () => {
    const conditionalItems = [
      {
        label: QUESTION_LOGIC.SHOW.label,
        name: QUESTION_LOGIC.SHOW.name,
        icon: QUESTION_LOGIC.SHOW.icon,
        targets: [],
      },
      {
        label: QUESTION_LOGIC.HIDE.label,
        name: QUESTION_LOGIC.HIDE.name,
        icon: QUESTION_LOGIC.HIDE.icon,
        targets: [],
      },
    ];
    // Define the IDs to be displayed or hidden and construct the payload accordingly.
    const items = conditionalItems?.map((item) => {
      const shouldIncludeTarget = item?.name === SHOW ? false : true;
      const populatedTargets = Object.keys(checkboxItems)
        ?.map(
          (id) =>
            checkboxItems[id] === shouldIncludeTarget && {
              elementId: id,
            }
        )
        ?.filter(Boolean);
      item.targets = populatedTargets;
      return item;
    });
    let conditionals = FormStore.sharedInstance().getConditional();
    conditionals = JSON.parse(JSON.stringify(conditionals));
    FormStore.sharedInstance().saveOptionQuestions(
      props.id,
      items,
      conditionals
    );
    handleAnsLogicState()
    onClose();
  };

  const handleCheckboxSelection = (elementList, isChecked, elementIdentifier, subSectionList) => {
    const computedCheckboxItems = JSON.parse(JSON.stringify(checkboxItems));
    const elementId = elementList?.elementId;
    // Manage checkbox selections for the section, sub-section "select all" option, and sub-section level.
    if (elementIdentifier === SECTION) {
      computedCheckboxItems[elementId] = isChecked;
    } else if (elementIdentifier === SUB_SECTION) {
      computedCheckboxItems[elementId] = isChecked;
      const subSectionId = subSectionList?.elementId;
      const subSectionIds = subSectionList?.children?.map((subSection) => subSection?.elementId);
      const allChecked = subSectionIds?.every((subSectionId) => computedCheckboxItems[subSectionId]);
      if (allChecked) {
        computedCheckboxItems[subSectionId] = true;
      } else {
        computedCheckboxItems[subSectionId] = false;
      }
    } else {
      computedCheckboxItems[elementId] = isChecked;
      const subSectionIds = elementList?.children?.map((subSection) => subSection?.elementId);
      subSectionIds?.forEach((subSectionId) => {
        computedCheckboxItems[subSectionId] = isChecked;
      });
    }
    setCheckboxItems(computedCheckboxItems);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(6)
    }
  });

  const DialogTitle = withStyles(styles)((props) => {
	  const { children, classes, onClose, ...other } = props;
	  return (
	    <MuiDialogTitle disableTypography className={classes.root} {...other}>
	      <Typography variant="h5">{children}</Typography>
	    </MuiDialogTitle>
	  );
  });
  const classes = useStyles();
  const { form, visibleElementsIds } = questionsList;
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      id="customized-dialog-title"
      className={classes.root}
      classes={{ paper: classes.dialogPaper }}
      onEnter={setDefaultValues}
    >
      <DialogTitle
        id="dialog-title"
        onClose={handleCancel}
        className={classes.dialogTitle}
      >
        Show / Hide Question
      </DialogTitle>
      {visibleElementsIds?.length ? (
        <Typography className={classes.formLabel} variant="subtitle1">
          Select question(s) to hide. If question(s) already selected, deselect
          to show.
        </Typography>
      ) : null}
      <DialogContent className={classes.dialogContent}>
        {visibleElementsIds?.length ? (
          <FormControl component="fieldset" fullWidth>
            {form?.children?.map(
              (section) =>
                visibleElementsIds.includes(section?.elementId) && (
                  <Accordion
                    defaultExpanded={true}
                    className={classes.accordionSeperator}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand Section"
                      aria-controls="section-content"
                      id="section-header-label"
                      className={classes.accordionHeader}
                    >
                      {section?.label}
                    </AccordionSummary>
                    <AccordionDetails
                      id="section-content"
                      className={classes.accordionDetails}
                    >
                      {section?.children?.map((sectionList) =>
                        visibleElementsIds?.includes(sectionList?.elementId) &&
                        sectionList?.componentType !== SUB_SECTION ? (
                          <FormControlLabel
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                              <Checkbox
                                checked={checkboxItems[sectionList?.elementId]}
                                onChange={(e) =>
                                  handleCheckboxSelection(
                                    sectionList,
                                    e.target.checked,
                                    SECTION
                                  )
                                }
                              />
                            }
                            label={
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: sectionList?.label,
                                }}
                              ></span>
                            }
                            classes={{ label: classes.accordionLabel }}
                            className={classes.accordionFormLabel}
                          />
                        ) : (
                          visibleElementsIds?.includes(
                            sectionList?.elementId
                          ) && (
                            <Accordion
                              defaultExpanded={true}
                              className={`${classes.subSectionAccordion} ${classes.accordionSeperator}`}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand Sub-Section"
                                aria-controls="sub-section-content"
                                id="sub-section-header-label"
                                className={classes.accordionHeader}
                              >
                                <FormControlLabel
                                  onClick={(event) => event.stopPropagation()}
                                  onFocus={(event) => event.stopPropagation()}
                                  control={
                                    <Checkbox
                                      checked={
                                        checkboxItems[sectionList?.elementId]
                                      }
                                      onChange={(e) =>
                                        handleCheckboxSelection(
                                          sectionList,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={<span>{sectionList?.label}</span>}
                                  classes={{
                                    label: classes.subSectionAccordionLabel,
                                  }}
                                />
                              </AccordionSummary>
                              <AccordionDetails
                                id="sub-section-content"
                                className={`${classes.accordionDetails} ${classes.subSectionAccordionBottom}`}
                              >
                                {sectionList?.children?.map(
                                  (subSection) =>
                                    visibleElementsIds?.includes(
                                      subSection?.elementId
                                    ) && (
                                      <FormControlLabel
                                        onClick={(event) =>
                                          event.stopPropagation()
                                        }
                                        onFocus={(event) =>
                                          event.stopPropagation()
                                        }
                                        control={
                                          <Checkbox
                                            checked={
                                              checkboxItems[
                                                subSection?.elementId
                                              ]
                                            }
                                            onChange={(e) =>
                                              handleCheckboxSelection(
                                                subSection,
                                                e.target.checked,
                                                SUB_SECTION,
                                                sectionList
                                              )
                                            }
                                          />
                                        }
                                        label={
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: subSection?.label,
                                            }}
                                          ></span>
                                        }
                                        classes={{
                                          label: classes.accordionLabel,
                                        }}
                                        className={`${classes.accordionFormLabel} ${classes.subSectionAccordionFormLabel}`}
                                      />
                                    )
                                )}
                              </AccordionDetails>
                            </Accordion>
                          )
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                )
            )}
          </FormControl>
        ) : (
          <div style={{ textAlign: "center" }}>No questions available.</div>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogFooter}>
        <Button
          onClick={handleCancel}
          disableElevation
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        {visibleElementsIds?.length ? (
          <Button
            onClick={handleSave}
            disableElevation
            className={classes.confirmButton}
          >
            CONFIRM
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}