import React, { Fragment } from 'react';
import { Card, Box, Typography, Checkbox, IconButton, Button, InputBase, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { withStyles } from '@material-ui/styles';
import { FormStore } from '../../services/form.store';
import {FormHelperService, ACTIONS, ELEMENT_TYPE } from '../../services/form.helper.service';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const styles = theme => ({
    flexGrow: {
        flexGrow: 1
    },
    checkboxIcon: {
        borderRadius: 2, width: 22, height: 22,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#F2F2F2'
    },
    checkedIcon: {
        backgroundColor: '#419BAC',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block', width: 22, height: 22,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""'
        },
        'input:hover ~ &': {
            backgroundColor: '#419BAC'
        }
    },
    optionDiv: {
        borderRadius: 2, border: "0.8px solid rgba(16,22,26,.2)",
        padding: "5px"
    },
    deleteOptionButton: {
        float: 'right'
    },
    addOptionButton: {
        marginTop: "10px",
        color: "#419BAC",
        marginLeft: "-7px",
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
});

const getListStyle = isDraggingOver => ({
    background: "rgba(16,22,26,.2)",
    // width: 250
  });

const getItemStyle = (isDragging, draggableStyle, result) => ({
    borderColor: isDragging ? "#419BAC" : "rgba(16,22,26,.2)",
    borderWidth: isDragging ? "2px" : "0.8px",
    borderRadius: isDragging ? 4 : 2,
    background: isDragging ? "rgba(255, 255, 255, 0.75)" : "#FFFFFF",
    ...draggableStyle
});

class CheckBox extends React.Component {

    constructor(props) {
        super();
        if (props.elementPopup) {
            let options = [];
            if (props.elementPopup.options && props.elementPopup.options.length > 0) {
                props.elementPopup.options.forEach((item) => {
                    options.push({ "checked": false, "optionText": item });
                });
            }
            this.state = {
                optionsPopup: options
            };
            this.editPopupOptionLabel = this.editPopupOptionLabel.bind(this);
            this.updatePopupState = this.updatePopupState.bind(this);
        }

        else {
            let options = [];
            if (props.element.options && props.element.options.length > 0) {
                props.element.options.forEach((item) => {
                    options.push({"checked": false, "optionText": item });
                });
            }
            this.state = {
                options: options
            };
            this.addOption = this.addOption.bind(this);
            this.deleteOption = this.deleteOption.bind(this);
            this.editOptionLabel = this.editOptionLabel.bind(this);
            this.updateState = this.updateState.bind(this);
            this.moveOptions = this.moveOptions.bind(this);
            this.disableDelete = this.disableDelete.bind(this);
        }
    }

    updateState(updatedOptions) {
        let array = [];
        this.setState({ options: updatedOptions }, () => {
            if (this.state.options.length > 0) {
                this.state.options.forEach((item) => {
                    array.push(item.optionText);
                });
            }
            FormStore.sharedInstance().addOptions(array);
        });
    }

    updatePopupState(updatedOptions) {
        let array = [];
        this.setState({ optionsPopup: updatedOptions }, () => {
            if (this.state.optionsPopup.length > 0) {
                this.state.optionsPopup.forEach((item) => {
                    array.push(item.optionText);
                });
            }
            FormStore.sharedInstance().addPopupOptions(array);
        });
    }

    addOption() {
        let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.ADD, undefined, undefined, ELEMENT_TYPE.CHECKBOX);
        this.updateState(updatedOptions);
    }

    deleteOption(index) {
        let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.DELETE, index);
        this.updateState(updatedOptions);
    }

    editOptionLabel(index, value) {
        let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.EDIT, index, value);
        this.updateState(updatedOptions);
    }

    editPopupOptionLabel(index, value) {
        let updatedOptions = FormHelperService.performAction(this.state.optionsPopup, ACTIONS.EDIT, index, value);
        this.updatePopupState(updatedOptions);
    }

    moveOptions(result) {
        if (!result.destination) {
            return;
        }
        let updatedOptions = FormHelperService.performAction(this.state.options, ACTIONS.MOVE, undefined, undefined, undefined, result.source.index, result.destination.index);
        this.updateState(updatedOptions);
    }

    disableDelete() {
        return (this.state.options.length <= 1) ? true : false;
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                {this.props.element ?
                    <Typography component="div" id="rootCheckbox">
                        <Grid container spacing={3}>
                            <Grid item style={{ width: 'calc(100% - 290px)' }} >
                                <DragDropContext onDragEnd={this.moveOptions}>
                                    <Droppable droppableId="droppableForOptionsReordering">
                                        {(provided, snapshot) => (
                                            <Card id="checkboxCard" square {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                <Box alignItems="center">
                                                    {this.state.options.map((child, index) => (
                                                        <Draggable key={index} draggableId={index.toString()} index={index}>
                                                            {(draggableProvided, draggableSnapshot) => (
                                                                <div key={index} id="cbOption"
                                                                    className={classes.optionDiv}
                                                                    ref={draggableProvided.innerRef}
                                                                    {...draggableProvided.draggableProps}
                                                                    {...draggableProvided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                    	draggableSnapshot.isDragging,
                                                                        draggableProvided.draggableProps.style
                                                                    )}>
                                                                    <Checkbox id="checkbox" icon={<span className={classes.checkboxIcon} />} disabled/>
                                                                    <InputBase
                                                                        id="optionLabel"
                                                                        value={child.optionText}
                                                                        inputProps={{ 'aria-label': 'naked' }}
                                                                        autoComplete='off'
                                                                        style={{ width: "80%", padding: "5px" }}
                                                                        onChange={(event) => { this.editOptionLabel(index, event.target.value); }}></InputBase>
                                                                    <IconButton id="deleteButton" disabled={this.disableDelete()} className={classes.deleteOptionButton} onClick={() => { this.deleteOption(index) }}><DeleteIcon fontSize="inherit" /></IconButton>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </Box>
                                            </Card>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>
                        </Grid>
                        <Button id="addOptionButton" className={classes.addOptionButton} variant="text" onClick={this.addOption}>+ ADD OPTION</Button>
                    </Typography> : null}
                {this.props.elementPopup ?
                    <Typography component="div" id="rootCheckboxPopup">
                        <Grid container spacing={3}>
                            <Grid item></Grid>
                            <Card id="checkboxCard" square>
                                <Box alignItems="center">
                                    {this.state.optionsPopup.map((child, index) => (
                                        <div key={index} id="cbOptionPopup" 
                                        	className={classes.optionDiv}>
                                            <Checkbox id="checkbox" 
                                            	icon={<span className={classes.checkboxIcon} />} disabled />
                                            <InputBase id="optionLabelPopup"
                                                value={child.optionText}
                                                inputProps={{ 'aria-label': 'naked' }}
                                                autoComplete='off'
                                                style={{ width: '430px', padding: "5px" }}
                                                onChange={(event) => { this.editPopupOptionLabel(index, event.target.value) }}>
                                            </InputBase>
                                        </div>
									))}
                                </Box>
                            </Card>
                        </Grid>
                    </Typography> 
                : null}
            </Fragment>
        )
    }
}
export default withStyles(styles)(CheckBox);