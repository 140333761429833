import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import PanToolIcon from '@material-ui/icons/PanTool';
import Header from './Header';
import { currentUserState } from '../services/session.manager';
import Link from '@material-ui/core/Link';

const styles = theme => ({
    root: { height: '100%', paddingTop: 64 },
    shiftContent: {
        minHeight: `calc(100% - 64px)`, display: "flex", alignItems: 'center',
        justifyContent: 'center', flexDirection: 'column'
    },
    rootCard: { width: '30%', height: '140px' },
    pantoolIcon: { fontSize: '3.2rem', margin: '5%' },
    initialPoint: { paddingLeft: "8px" }
});

class AccessDenied extends Component {
    constructor(props) {
        super(props);
        this.state = { currentUrl: "", userRole: "" };
    }

    componentDidMount() {
    	if (currentUserState) {
            this.currentUserSubscription = currentUserState.subscribe(user => {
                let userRole = user.userRole;
                this.setState({ userRole: userRole });
            });
    	}
    }
    
    componentWillUnmount(){
        this.currentUserSubscription.unsubscribe();
    }

    navLink = (url) => {
        this.setState({
            currentUrl: url
        })
        const { history } = this.props;
        history.push(url);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Header />
                <main className={classes.shiftContent}>
                    <Card className={classes.rootCard}>
                        <CardContent>
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={2}>
                                        <Typography>
                                            <PanToolIcon className={classes.pantoolIcon} />
                                        </Typography>
                                    </Grid>
                                    <Grid item md={10} xs={10} className={classes.initialPoint}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Access Denied!
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary" component="p">
                                            You are not allowed to access this screen
                                        </Typography>
                                        {this.state.userRole === 'GLOBALADMIN' ?
                                            <Link
                                                underline="always"
                                                id="createForm"
                                                component="button"
                                                variant="body2"
                                                onClick={() => this.navLink("/createForm")}>
                                                New Form
                                            </Link> : null}
                                        {this.state.userRole === 'ASSETMANAGER' ?
                                            <Link
                                                underline="always"
                                                id="assets"
                                                component="button"
                                                variant="body2"
                                                onClick={() => this.navLink("/assets")}>
                                                Assets
                                            </Link> : null}
                                        {((this.state.userRole === 'SITEMANAGER') || (this.state.userRole === 'TECHNICIAN')) ?
                                            <Link
                                                underline="always"
                                                id="review"
                                                component="button"
                                                variant="body2"
                                                onClick={() => this.navLink("/review")}>
                                                Review
                                            </Link> : null}
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </main>
            </div>
        );
    }
}

export default withStyles(styles)(AccessDenied);