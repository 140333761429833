import { extractDropDownValue } from '../store/form.answer.extract';

const selectRequired = (answer, element) => {
    let value = extractDropDownValue(answer);
    if (value) {
        if (value.length > 0) {
            return true;
        }
    }
    return 'This field is required.';
};

export { selectRequired };