import React, { Fragment } from 'react';

export default class Signature extends React.Component {
  render() {
    return (
      <Fragment>
        <div id="signature"></div>
      </Fragment>
    )
  }
}
