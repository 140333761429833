import React, { Fragment } from "react";
import { Box, TextField, Grid, IconButton, Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { withStyles } from "@material-ui/styles";
import { FormStore } from '../../services/form.store';
import FormBuilderService from '../../services/form-builder.service';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  activeTextField: {
    backgroundColor: "rgba(255, 255, 255, .75)",
  },
  deleteOptionButton: {
    float: 'right'
  },
  addOptionButton: {
    marginTop: "10px",
    color: "#419BAC",
    marginLeft: "-7px",
    "&:hover": {
      backgroundColor: "transparent"
    },
    textTransform: "none"
  }
});

class DependentDropdown extends React.Component {

  constructor(props) {
    super();
    this.formBuilderService = new FormBuilderService();
    this.selectedOptions = [];

    this.state = {
      selectedOptions: this.selectedOptions,
      options: []
    }

    this.addChildDropdown = this.addChildDropdown.bind(this);
    this.updateState = this.updateState.bind(this);
    this.getAllSiblingOptionsForSelectedOptions = this.getAllSiblingOptionsForSelectedOptions.bind(this);
    this.updateDynamicDropdownList = this.updateDynamicDropdownList.bind(this);
    this.delete = this.delete.bind(this);

  }

  componentDidMount() {
    this.formBuilderService.getDependentDropdowns().then(response => {
      this.response = response.data;
      if (response.status === 200) {
        if (this.props.element.dynamicDropdownList && this.props.element.dynamicDropdownList.length !== 0) {
          this.props.element.dynamicDropdownList.forEach(option => {
            if (this.selectedOptions.length === 0) { // first time
              this.selectedOptions.push(this.response.filter(obj => obj.id === option.id)[0]); // Filtering out objects from the response by matching against IDs that were saved as part of dynamicDropdownList attribute
            }
            else { // second time onwards
              this.selectedOptions.push(this.selectedOptions[this.selectedOptions.length - 1].children.filter(obj => obj.id === option.id)[0]);
            }
          });
        }
        else { // default case
          this.selectedOptions.push(this.response[0]);
        }
        this.updateState(this.selectedOptions);
      }
    });
  }

  disableAddButton(selectedOptions) {
    if (this.state.selectedOptions && this.state.selectedOptions.length !== 0) {
      return selectedOptions[selectedOptions.length - 1].children.length === 0;
    }
    else {
      return true;
    }
  }

  // Returns an array of arrays, with each array containing sibling options for each selected option in selectedOptions state variable, in the same order 
  getAllSiblingOptionsForSelectedOptions(selectedOptions, response) {
    let options = [];
    selectedOptions.forEach(selectedOption => {
      if (options.length === 0) {
        if (response.includes(selectedOptions[0])) {
          let siblingsOfSelectedOption = response;
          options.push(siblingsOfSelectedOption);
        }
      }
      else {
        let siblingsOfSelectedOption = options[options.length - 1].filter((obj) => obj.children.includes(selectedOption))[0].children;
        options.push(siblingsOfSelectedOption);
      }
    });
    return options;
  }

  // Returns an array of IDs for the selected options, which will be used to update the Store
  updateDynamicDropdownList(selectedOptions) {
    let updatedDynamicDropdownList = [];
    selectedOptions.forEach(element => {
      updatedDynamicDropdownList.push({ "id": element.id });
    });
    return updatedDynamicDropdownList;
  }

  updateState(updatedSelectedOptions) {
    this.setState({
      selectedOptions: updatedSelectedOptions
    }, () => {
      let updatedOptions = this.getAllSiblingOptionsForSelectedOptions(this.state.selectedOptions, this.response);
      this.setState({
        options: updatedOptions
      });
      let updatedDynamicDropdownList = this.updateDynamicDropdownList(this.state.selectedOptions);
      FormStore.sharedInstance().addDependentDropdownOptions(updatedDynamicDropdownList);
    });
  }

  onOptionChange = (event, index) => {
    const value = event.target.value;
    let arr = Array.from(this.state.selectedOptions);
    arr[index] = value;

    while ((++index) < (arr.length)) { // moving to the next index, iterating the entire length of selectedOptions starting at the index at which the option was changed 
      if (arr[index - 1].children.length !==0) { // replacing previous children with subsequent children of the new option (if present), starting at the new option
        arr[index] = arr[index - 1].children[0]; // setting the first option as the default selection
      }
      else { // if no children, stop. And delete all the children previously present starting at this index
        arr.splice(index);
      }
    }
    this.updateState(arr);
  }

  addChildDropdown() {
    let arr = Array.from(this.state.selectedOptions);
    let currentLastSelectedOption = arr[arr.length - 1];
    let firstChildOfLastOption = currentLastSelectedOption.children[0];
    arr.push(firstChildOfLastOption);
    this.updateState(arr);
  }

  delete(index) {
    let arr = Array.from(this.state.selectedOptions);
    arr.splice(index);
    this.updateState(arr);
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Box className={classes.root} id="box">
          <Grid container spacing={3}>
            {this.state.selectedOptions && this.state.selectedOptions.map((selectedOption, index) => (
              <Grid item style={{ width: 'calc(100% - 300px)' }} key={index}>
                <TextField select style={{ width: 'calc(100% - 50px)' }}
                  id="dropdown"
                  name="dependentDropdown" variant="outlined"
                  className={classes.activeTextField}
                  value={this.state.selectedOptions[index]}
                  onChange={(event) => this.onOptionChange(event, index)}
                >
                  {this.state.selectedOptions.length === this.state.options.length && this.state.options[index].map((option) => (
                    <MenuItem
                      key={option.id} value={option}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <IconButton id="deleteButton"
                  disabled={index === 0 ? true : false}
                  className={classes.deleteOptionButton}
                  onClick={() => { this.delete(index) }}
                ><DeleteIcon fontSize="inherit" /></IconButton>
              </Grid>))}
          </Grid>
        </Box>
        <Button id="addChildDropdownButton" onClick={this.addChildDropdown} disabled={this.disableAddButton(this.state.selectedOptions)} className={classes.addOptionButton} variant="text">+ Child Dropdown</Button>
      </Fragment>
    );
  }
}

export default withStyles(styles)(DependentDropdown);
