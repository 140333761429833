import React, { Component, Fragment } from 'react';
import clsx from 'clsx';
import { Box, Typography, Chip } from '@material-ui/core';
import hiddenBase from '../../.././shared/imgs/hiddenBack.png';
import ActiveQuestion from './ActiveQuestion';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  FormStore,
  activeElementState,
  activeTranslateElementState
} from '../../../services/form.store';

const styles = theme => ({
  root: {},
  sectionBody: {
    borderTop: '1px solid rgba(0, 0, 0, .08)',
    '&:hover': {
      backgroundColor: '#FCFCFC',
    },
  },
  activeQ: {
    backgroundColor: 'rgba(91, 195, 115, .1)',
    border: '1px solid rgb(91, 195, 115)',
    '&:hover': {
      backgroundColor: 'rgba(91, 195, 115, .1)',
    },
  },
  hiddenQ: {
    backgroundImage: `url(${hiddenBase})`,
  },
  QuestionStatus: {
    fontSize: '.7rem',
    lineHeight: '12px',
    marginTop: '-17px',
    marginBottom: theme.spacing(1),
    '& span': {
      textTransform: 'uppercase',
      fontWeight: 500,
      letterSpacing: '1px',
      color: 'white',
      display: 'inline-block',
      padding: '3px 6px',
    },
  },
  hidden: {
    '& span': {
      background: '#B5917B',
    }
  },
  logic: {
    '& span': {
      background: '#B5B17B',
    }
  },
  dummyTextInput: {
    backgroundColor: '#F2F2F2',
    height: 24,
    margin: '3px 0',
  },
  dummyBtn: {
    backgroundColor: '#F2F2F2',
    display: 'inline-block',
    padding: '3px 10px',
    margin: '3px 0',
  },
  dummyRange: {
    marginTop: theme.spacing(2),
    '& > div': {
      backgroundColor: '#F2F2F2',
      height: 2,
    },
    '& > span': {
      height: 20,
      width: 20,
      borderRadius: 10,
      backgroundColor: '#F2F2F2',
      marginLeft: '100px',
      marginTop: '-10px',
      display: 'block',
      border: '2px solid white',
    },
  },
  activeTextField: {
    backgroundColor: 'rgba(255, 255, 255, .75)',
  },
  dummyAddSignatureBtn: {
    backgroundColor: '#F2F2F2',
    display: 'inline-block',
    padding: '3px 10px',
    margin: '3px 0',
    minWidth: '32px'
  },
  listItem:{
    '& ul': {
      marginLeft: '25px',
    },
    '& ol': {
      listStylePosition: 'inside',
      marginLeft: '10px',
    }
  }
});


class FormQuestions extends Component {

  constructor(props) {
    super();
    this.state = {
      active: false,
      element: props.element,
      activeQuestion: false
    }
  }

  componentDidMount() {
    if (this.props.elementPopup) {
      this.activeStateSubscription = activeTranslateElementState.subscribe(newState => {
        this.setState({ activeQuestion: newState === this.props.elementPopup.elementId });
      });
    } else {
      this.activeStateSubscription = activeElementState.subscribe(newState => {
        this.setState({ active: newState === this.props.element.elementId });
      });
    }
  }

  componentWillUnmount() {
    this.activeStateSubscription.unsubscribe();
  }

  onClick = () => {
    if (this.state.active === false) {
      FormStore.sharedInstance().makeActive(this.props.element.elementId)
    }
  }

  onClickQuestion = () => {
    if(this.state.activeQuestion === false){
      FormStore.sharedInstance().makeActiveTranslateSection(this.props.elementPopup.elementId);
    }
  }

  setRef = (ref) => {
    if(this.props.element){
      FormStore.sharedInstance().updateElementRef(this.props.element.elementId, ref)
    }
    else{
      FormStore.sharedInstance().updateElementRef(this.props.elementPopup.elementId, ref)
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div ref={this.setRef}>
        { this.props.element ?
          <Box onClick={this.onClick} 
        	className={clsx(classes.sectionBody, { [classes.activeQ]: this.state.active })}>
            {!this.state.active ?
              <Box p={3} >
                <Fragment >
                  <Typography component="div">
                    {(this.props.element.validations) && (this.props.element.validations.required === true) ? 
                    	<Chip color="primary" 
                    		style={{ backgroundColor: '#bc666d' }} 
                    		key="required" label="REQUIRED" size="small" /> 
                    : null}
                    {(this.props.element.properties) && (this.props.element.properties.visible === false) ? 
                    	<Chip color="primary" 
                    		style={{ backgroundColor: '#bc8a66' }} key="hidden" 
                    		label="HIDDEN" size="small" />
                    : null}
                    {(this.props.element.properties) && (this.props.element.properties.conditional)? 
                    	<Chip color="secondary" 
                    		style={{ backgroundColor: '#bcb566' }} key="logic" 
                    		label="LOGIC" size="small" />
                    : null}
                  </Typography>
                  <Typography id="questionText" className={classes.listItem} gutterBottom>
                  	<Typography component="span" color="error">*</Typography>&nbsp;
                  	<span dangerouslySetInnerHTML={{ __html: this.props.element.label }}></span>
                  </Typography>
                  {(this.props.element.options) && 
                   (this.props.element.componentType === 'radio') ?
	                   this.props.element.options.map((option) => (
	                    <Typography component="span" color="black">
	                    	<Chip variant="outlined" key={option.id} 
	                    		label={option.value} size="small" />
	                    </Typography>
	                   )) 
	               : null}
                 {(this.props.element.options) &&
                   (this.props.element.componentType === 'select') ?
	                   this.props.element.options.map((option) => (
	                    <Typography component="span" color="black">
                        <Chip variant="outlined" key={option.id}
                        label={option.value} size="small" />
                      </Typography>
	                   ))
	               : null}
                  {(this.props.element.options) && 
                   (this.props.element.componentType === 'checkbox') ?
	                   this.props.element.options.map((option) => (
	                    <Typography component="span" color="black">
	                    	<Chip variant="outlined" key={option} 
                        label={option} size="small" />
	                    </Typography>
	                   )) 
	               : null}
                  {this.props.element.componentType !== "fileInput" ?
                    <div className={classes.dummyTextInput}></div> : null}
                  {this.props.element.componentType === "signature" && 
                	  this.props.element.properties && this.props.element.properties.allowDuplicate ?
                    <Button id="dummyAddSignatureButton" 
                    	className={classes.dummyAddSignatureBtn}>+</Button> 
                  : null}
                  {this.props.element.componentType === "fileInput" ?
                    <Button id="dummyBrowseButton" 
                    	className={classes.dummyAddSignatureBtn}>BROWSE</Button> 
                  : null}
                </Fragment>
              </Box> : null}
            {this.state.active ? 
            	<ActiveQuestion element={this.props.element} 
            		subsectionId={this.props.subsectionId} />
            : null}
          </Box> : null}
        {this.props.elementPopup ?
          <Box onClick={this.onClickQuestion} 
        	className={clsx(classes.sectionBody, { [classes.activeQ]: this.state.activeQuestion })}>
            {!this.state.activeQuestion ?
              <Box p={3} >
                <Fragment >
                  <Typography id="questionText" gutterBottom>
                  	<Typography component="span" color="error">*</Typography>&nbsp;
                  	<span dangerouslySetInnerHTML={{ __html: this.props.elementPopup.label }}></span>
                  </Typography>
                </Fragment>
              </Box> : null}
            {this.state.activeQuestion ? 
            	<ActiveQuestion elementPopup={this.props.elementPopup} />
            : null}
          </Box> : null}
      </div>
    )
  }
}

export default withStyles(styles)(FormQuestions);