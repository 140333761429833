import React, { Fragment } from 'react';
import { Box, TextField, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { FormStore } from '../../services/form.store';

const styles = theme => ({
  root: { display: "block" },
  flexGrow: { flexGrow: 1 },
  answerLogicMenu: { borderRadius: 0 },
  activeTextField: {
    backgroundColor: 'rgba(255, 255, 255, .75)', marginBottom: '8px',
    '& p':{ 
    	backgroundColor: 'rgba(91, 195, 115, .1)', margin: '0px', 
    	paddingLeft : '14px' 
    }
  },
  error: { border: '2px solid red' }
});

class RangeSlider extends React.Component {
  constructor(props) {
    super();
    this.state = {error:false};
  }

  onInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const elementId = this.props.element.elementId;
    const minRange = Number(this.props.element.properties.minRange);
    const maxRange = Number(this.props.element.properties.maxRange);
    const defaultValue = Number(this.props.element.properties.defaultValue);
    let error = '';
    if ((name === 'minRange' && value === '') || 
    		(name === 'maxRange' && value === '') || 
    		(name === 'rangeMultiplier'&& value === '')) 
		error = 'Please fill out this field.';
	else if (name === 'minRange' && Number(value) > maxRange)
    	error = 'MIN RANGE should be lesser than MAX RANGE!';
	else if (name === 'maxRange' && Number(value) < minRange)
    	error = 'MAX RANGE should be greater than MIN RANGE!';
    else if ((name === 'defaultValue' && value !== '' && 
    		(Number(value) > maxRange || Number(value) < minRange)) || 
    		(name === 'minRange' && defaultValue < Number(value)) ||
    		(name === 'maxRange' && defaultValue > Number(value))) 
    	error = 'DEFAULT VALUE should be in between MIN and MAX RANGE!';
    else if(this.props.element.properties.rangeLabel){
      if (name === 'minRangeLabel' && value === '')
        error = 'Please fill out this field.';
      else if (name === 'maxRangeLabel' && value === '')
        error = 'Please fill out this field.';
    }
    if (error.length > 0) FormStore.sharedInstance().addErrorId(elementId);
    else FormStore.sharedInstance().deleteErrorId(elementId);
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: name, value: value });    
  }

  render() {
    const { classes } = this.props;
      return (
         <Fragment>
          <Box style={{ width: 'calc(100% - 290px)' }}>
            <Grid container spacing={3}>
              <Grid item style={{ width: '50%' }}>
                <TextField id="slider_min_range" fullWidth required
                   type="number" variant="outlined" label="MIN RANGE"
                   name="minRange" onChange={this.onInputChange}
                   InputProps={{ classes: 
                   	{ notchedOutline: (Number(this.props.element.properties.minRange) > Number(this.props.element.properties.maxRange)) ? classes.error : null } 
                   }}
                   helperText={(Number(this.props.element.properties.minRange) > Number(this.props.element.properties.maxRange)) ? 
                   		'MIN RANGE should be lesser than MAX RANGE!' : ''} 
                   error={(Number(this.props.element.properties.minRange) > Number(this.props.element.properties.maxRange)) ? true : false}
                   value={this.props.element.properties.minRange}
                   className={classes.activeTextField} />
              </Grid>
              <Grid item style={{ width: '50%' }}>
                <TextField id="slider_max_range" fullWidth required
                   type="number" variant="outlined" label="MAX RANGE"
                   name="maxRange" onChange={this.onInputChange}
                   InputProps={{ classes: 
                   	{ notchedOutline: (Number(this.props.element.properties.maxRange) < Number(this.props.element.properties.minRange)) ? classes.error : null } 
                   }}
                   helperText={(Number(this.props.element.properties.maxRange) < Number(this.props.element.properties.minRange)) ? 
                		'MAX RANGE should be greater than MIN RANGE!' : ''} 
                   error={(Number(this.props.element.properties.maxRange) < Number(this.props.element.properties.minRange)) ? true : false}
                   value={this.props.element.properties.maxRange}
                   className={classes.activeTextField}/>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item style={{ width: '50%' }} >
                <TextField id="slider_range_multiplier" fullWidth required
                  type="number" variant="outlined" label="RANGE MULTIPLIER"
                  name="rangeMultiplier" onChange={this.onInputChange}
                  value={this.props.element.properties.rangeMultiplier}
                  className={classes.activeTextField} />
              </Grid>
              <Grid item style={{ width: '50%' }} >
                <TextField id="slider_range_multiplier_default" fullWidth
                   type="number" variant="outlined" label="DEFAULT VALUE" 
                   name="defaultValue" onChange={this.onInputChange}
                   value={this.props.element.properties.defaultValue}
                   InputProps={{ classes: 
                   	{ notchedOutline: (this.props.element.properties.defaultValue !== '' && 
                   		(Number(this.props.element.properties.defaultValue) > Number(this.props.element.properties.maxRange) || 
                   		Number(this.props.element.properties.defaultValue) < Number(this.props.element.properties.minRange))) ? classes.error : null } 
                   }}
                   className={classes.activeTextField} 
                   helperText={(this.props.element.properties.defaultValue !== '' && 
                		(Number(this.props.element.properties.defaultValue) > Number(this.props.element.properties.maxRange) || 
                   		Number(this.props.element.properties.defaultValue) < Number(this.props.element.properties.minRange))) ? 
                   		'DEFAULT VALUE should be in between MIN and MAX RANGE!' : ''} 
                   error={(this.props.element.properties.defaultValue !== '' && 
                		(Number(this.props.element.properties.defaultValue) > Number(this.props.element.properties.maxRange) || 
                   		Number(this.props.element.properties.defaultValue) < Number(this.props.element.properties.minRange))) ? true : false} />
              </Grid>
            </Grid>
            {this.props.element.properties && this.props.element.properties.rangeLabel ?
              <Grid container spacing={3}>
                <Grid item style={{ width: '50%' }} >
                  <TextField id="slider_range_multiplier" fullWidth required
                     variant="outlined" label="MIN RANGE LABEL"
                     name="minRangeLabel" onChange={this.onInputChange}
                     InputProps={{ classes:
                      { notchedOutline: (this.props.element.properties.rangeLabel && this.props.element.properties.minRangeLabel) === "" ? classes.error : null }
                    }}
                    helperText={(this.props.element.properties.rangeLabel && this.props.element.properties.minRangeLabel) === "" ?
                     'MIN RANGE LABEL should not be blank!' : ''}
                    error={(this.props.element.properties.rangeLabel && this.props.element.properties.minRangeLabel) === "" ? true : false}
                     value={this.props.element.properties.minRangeLabel}
                     className={classes.activeTextField} />
                </Grid>
                <Grid item style={{ width: '50%' }} >
                  <TextField id="slider_range_multiplier_default" fullWidth required
                     variant="outlined" label="MAX RANGE LABEL"
                     name="maxRangeLabel" onChange={this.onInputChange}
                     InputProps={{ classes:
                      { notchedOutline: (this.props.element.properties.rangeLabel && this.props.element.properties.maxRangeLabel) === "" ? classes.error : null }
                    }}
                    helperText={(this.props.element.properties.rangeLabel && this.props.element.properties.maxRangeLabel) === "" ? 
                     'MAX RANGE LABEL should not be blank!' : ''}
                    error={(this.props.element.properties.rangeLabel && this.props.element.properties.maxRangeLabel) === "" ? true : false}
                     value={this.props.element.properties.maxRangeLabel}
                     className={classes.activeTextField} />
                </Grid>
            </Grid> :
            null}
          </Box>
          </Fragment>
      )
  }
}

export default withStyles(styles)(RangeSlider);