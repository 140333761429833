import { extractDateHoursValue } from "../store/form.answer.extract";

const dateRequired = (answer, element) => {
  let dateValue = extractDateHoursValue(answer);
  let hasHours = element.properties.hasHoursWorked ? element.properties.hasHoursWorked : false;
  if (dateValue !== undefined && dateValue.date !== "Invalid date") {
    if (hasHours) {
      if (
        dateValue.date !== undefined &&
        dateValue.hours !== undefined &&
        dateValue.date.length > 0 &&
        dateValue.hours.length > 0
      ) {
        return true;
      }
    }
    return true;
  }
  return 'This date field is required.';
};

const hoursValidation = (answer, element) => {
  let hasHours = element.properties.hasHoursWorked ? element.properties.hasHoursWorked : false;
  let dateValue = extractDateHoursValue(answer);
  if (hasHours) {
    if (dateValue !== undefined && dateValue.hours !== undefined) {
      let hours = Number.parseInt(dateValue.hours, 10);
      if (hours <= 0) {
        return 'Hours worked cannot be zero or negative.';
      }
      else if(isNaN(hours) || hours == undefined){
        return 'Hours is required';
      }
    }
  }
  return true;
};

export { dateRequired, hoursValidation };
