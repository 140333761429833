import React, { Fragment } from "react";
import { Box, TextField, Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/styles";
import { FormStore } from '../../services/form.store';
import { FormInputTypesValidationService } from '../../services/form-input-types-validations.service';
import { ATTRIBUTES } from '../../services/form.helper.service';
import { DATE_TIME_FORMATS } from "../../constants/Constants";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  activeTextField: {
    backgroundColor: "rgba(255, 255, 255, .75)",
  },
  maxLenthStyle: {
    width:"50%",
  },
});

class DateTimeElement extends React.Component {

  constructor(props) {
    super();
    this.elementId = props.element.elementId;
    this.state = {
      maxLengthVal: 2000,
      text: "Anwer label",
      formats: [
        DATE_TIME_FORMATS.DD_MM_YY,
        DATE_TIME_FORMATS.DD_MM_YYYY,
        DATE_TIME_FORMATS.MM_DD_YY,
        DATE_TIME_FORMATS.MM_DD_YYYY,
        DATE_TIME_FORMATS.DD_MM_YYYY_H_MM_SS,
        DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_SS,
        DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_SS_A,
        DATE_TIME_FORMATS.YYYY_MM_DD_H_MM_A,
        DATE_TIME_FORMATS.DD_MMM_YYYY,
        DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_SS,
        DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_SS_A,
        DATE_TIME_FORMATS.DD_MMM_YYYY_H_MM_A,
        DATE_TIME_FORMATS.YYYY_MM_DD_HH_MM_SS_SSSZ,
        DATE_TIME_FORMATS.DD_MMM_YYYY_HH_MM_SS_A,
        DATE_TIME_FORMATS.MM_DD_YYYY_HH_MM_A
      ],
      format: props.element.validations && props.element.validations.dateFormat ? props.element.validations.dateFormat : "DD/MM/YY",
      hoursWorked: props.element.properties && props.element.properties.hasHoursWorked ? props.element.properties.hoursWorkedValue : 0.5,
      hoursWorkedError: props.element.properties &&  props.element.properties.hasHoursWorked && FormInputTypesValidationService.validateCommonAttributes(ATTRIBUTES.HOURS_WORKED_VALUE, props.element.properties.hoursWorkedValue) !== '' ? true : false,
      hoursWorkedErrorText: props.element.properties &&  props.element.properties.hasHoursWorked ? FormInputTypesValidationService.validateCommonAttributes(ATTRIBUTES.HOURS_WORKED_VALUE, props.element.properties.hoursWorkedValue) : ''
    };
  }

  onFormatChange = event => {
    const value = event.target.value;
    this.setState({
      format: value
    });
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'validations', name: 'dateFormat', value: value });
  }

  onHoursWorkedChange = (event) => {
    const hoursWorked = event.target.value;
    let errorMessage = FormInputTypesValidationService.validateCommonAttributes(ATTRIBUTES.HOURS_WORKED_VALUE, hoursWorked);

    if (errorMessage !== '') {
      if (!this.state.hoursWorkedError) { // Add only if the field is not already in error state
        FormStore.sharedInstance().addErrorId(this.elementId);
      }
      this.setState({
        hoursWorkedError: true,
        hoursWorkedErrorText: errorMessage,
      });
    }
    else {
      this.setState({
        hoursWorkedError: false,
        hoursWorkedErrorText: errorMessage,
      });
      FormStore.sharedInstance().deleteErrorId(this.elementId);
    }
    this.setState({
      hoursWorked: hoursWorked
    });
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: 'hoursWorkedValue', value: hoursWorked });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
      <Box className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField select fullWidth
                     id="dateTextField"
                     label="DATE FORMAT" name="dateformat" variant="outlined"
                     className={classes.activeTextField}
                     value={this.state.format}
                     onChange={this.onFormatChange}>
                      {this.state.formats.map((option) => (
                        <MenuItem key={option} value={option}>
                        {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {(this.props.element.properties && this.props.element.properties.hasHoursWorked) ?
              <Grid item xs={6}>
                <TextField
                  id="timeTextField"
                  fullWidth
                  required
                  variant="outlined"
                  label="HOUR(S) WORKED"
                  type="number"
                  value={this.state.hoursWorked}
                  error={this.state.hoursWorkedError}
                  helperText={this.state.hoursWorkedErrorText}
                  onChange={this.onHoursWorkedChange}
                  className={classes.activeTextField} />
              </Grid>
              : null}
          </Grid>
      </Box>
      </Fragment>
    );
  }

}

export default withStyles(styles)(DateTimeElement);
