import React, { Component, Fragment } from 'react';
import { Box, Collapse, IconButton, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { currentSubsectionState, FormStore } from '../../store/form.store';
import Components from '../form-element/CompMap';

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
        paddingBottom: '0px'
    },
    flexGrow: {
        flexGrow: 1
    },
    sectionTitle: {
        padding: theme.spacing(1, 2)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
});

class SubSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            isEnabled: false
        };
    }

    componentDidMount() {
        this.subSectionStateSubscription = currentSubsectionState.subscribe(newState => {
            this.setState({ expanded: newState === this.props.element.elementId });
        });
    }

    componentWillUnmount() {
        this.subSectionStateSubscription.unsubscribe();
    }

    handleExpandClick = (event) => {
        event.preventDefault();
        FormStore.sharedInstance().openSubSection(this.props.element.elementId);
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                {this.props.element ?
                    <Typography component="div" className={classes.root}>
                        <Box display="flex" alignItems="center" 
                        	className={classes.sectionTitle}
                            style={{ color: (this.props.element.label.length <= 0) ? 'red' : 'black' }}>
                            <IconButton edge="start" id="iconButton" 
                            	onClick={this.handleExpandClick}>
                                <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.expanded })} />
                            </IconButton>
                            <Box display='flex' flexDirection='column'>
                                <Typography>{(this.props.element.label.length) > 0 ? this.props.element.label : null}</Typography>
                                <Typography style={{color: '#616161'}} 
                                	variant='caption'>{this.props.element.description}</Typography>
                            </Box>
                        </Box>
                        <Collapse in={this.state.expanded} timeout="auto" 
                        	unmountOnExit>
                            <RenderChildren subsection={this.props.element} 
                            	classes={this.props} />
                        </Collapse>
                    </Typography> : null}
            </Fragment >
        );
    }
}

function RenderChildren(props) {
    if (props.subsection !== undefined) {
    	const TagName = Components['question'];
    	const items = props.subsection.children.map((item, key) =>
            <TagName key={key} element={item} 
            	subsectionId={props.subsection.elementId} />
        );
        return items;
    }
    return (<div>Empty Children</div>);
}

export default withStyles(styles)(SubSection);