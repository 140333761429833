import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import ShortTextOutlinedIcon from '@material-ui/icons/ShortTextOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import NotesOutlinedIcon from '@material-ui/icons/NotesOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import LinearScaleOutlinedIcon from '@material-ui/icons/LinearScaleOutlined';
import GestureOutlinedIcon from '@material-ui/icons/GestureOutlined';
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import FormQuestions from "./question/FormQuestions";
import Section from "./Section";
import SubSection from "./SubSection";
import SingleInput from "./SingleInput";
import TextArea from "./textArea";
import RadioGroup from "./RadioGroup";
import CheckBox from "./CheckBox";
import DropdownList from "./DropdownList";
import DateTimeElement from "./DateTimeElement";
import RangeSlider from "./RangeSlider";
import FileUploader from "./FileUploader";
import DependentDropdown from "./DependentDropdown";
import BarcodeScanner from "./BarcodeScanner";
import Signature from "./Signature";
import InstructionHelp from "./InstructionHelp";
import SectionList from "./SectionList";
import SubSectionList from "./SubSectionList";

const Components = {
  question: FormQuestions,
  section:  Section,
  sectionlist :SectionList,
  subsection: SubSection,
  subsectionlist : SubSectionList,
  text:     SingleInput,
  textarea: TextArea,
  radio: RadioGroup,
  checkbox: CheckBox,
  select: DropdownList,
  date: DateTimeElement,
  range: RangeSlider,
  fileInput: FileUploader,
  dependentDropdown: DependentDropdown,
  barcode: BarcodeScanner,
  signature: Signature,
  helpimage: InstructionHelp
};

export const componentIconMap = {
  'text': { icon: ShortTextOutlinedIcon, name: 'Single Line Input'},
  'textarea': { icon: NotesOutlinedIcon, name: 'Paragraph - Text Area'},
  'radio': { icon: RadioButtonCheckedOutlinedIcon, name: 'Radio Group'},
  'checkbox': { icon: CheckBoxOutlinedIcon, name: 'Checkbox'},
  'select': { icon: ArrowDropDownCircleOutlinedIcon, name: 'Dropdown List'},
  'date': { icon: TodayOutlinedIcon, name: 'Date / Time'},
  'range': { icon: LinearScaleOutlinedIcon, name: 'Range Slider'},
  'fileInput': { icon: CloudUploadOutlinedIcon, name: 'File Uploader'},
  'dependentDropdown': { icon: DnsOutlinedIcon, name: 'Dependent Dropdown'},
  'barcode': { icon: CropFreeOutlinedIcon, name: 'Barcode Scanner'},
  'signature': { icon: GestureOutlinedIcon, name: 'Signature'},
  'helpimage': { icon: AssignmentOutlinedIcon, name: 'Instruction / Help'},
};

export default Components;
