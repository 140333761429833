import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { dateHelperService } from '../services/date-helper.service';
import { useHistory } from "react-router-dom";
import FormDialog from "./FormDialog"
import { FormStore, FORM_STATUS, dialogState } from '../services/form.store';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FormListActions from '../shared/component/FormListActions';
import CopyFormDialog from '../views/CopyFormDialog';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpward';
import { FORM_LIST_HEADERS, SORT_MENU_OPTIONS, FORM_CATEGORIES } from '../services/form.helper.service';
import AlertDialog from '../components/form-element/AlertDialog';
import { ALERT_DIALOGS } from '../services/form.helper.service';

const FormGridView = props => {
    const [open, setOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);
    const [sortParameter, setSortParameter] = React.useState(props.sortParam);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [copyOpen, setCopyOpen] = React.useState(false);
    const [showCopyDialog, setShowCopyDialog] = React.useState(false);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(false);
    const [selectedForm, setSelectedForm] = React.useState(null);
    const history = useHistory();

    useEffect(() => {
        dialogState.subscribe(val => { setShowCopyDialog(false); })
    }, []);

    const handleClick = (formId) => {
        let existedForm = props.items.find(item => item.id === formId);
        let filtered = props.items.filter(item => {        
            return item.formCode === existedForm.formCode && (parseFloat(item.version) > parseFloat(existedForm.version));
        });
        if (filtered.length) {
            setSelectedId(formId);
            setOpen(true);
            FormStore.sharedInstance().editPublishedForm(false);
        } else {
            FormStore.sharedInstance().editPublishedForm(true);
            history.push('/editForm/' + formId);
        }
    };

    const handleCancel = () => {
        setOpen(false);
    }

    const handleRedirect = () => {
        history.push('/editForm/' + selectedId);
        setOpen(false);
    }

    const toggleActionMenu = () => {
        setCopyOpen((prevOpen) => !prevOpen);
    }

    const onClosePopup = () => {
      setTimeout(() => {
        setCopyOpen(false);
      }, 5000);
    }

    const handleMoreClick = (e, item) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        toggleActionMenu();
        setSelectedForm(item);
    }

    const handleClosecopyMenu = () => {
        toggleActionMenu();
    }

    const handleCopy = () => {
        toggleActionMenu();
        if (selectedForm !== undefined) {
            FormStore.sharedInstance().copyForm(selectedForm);
            toggleCopyFormDialog();
        }
    }

    const handleDelete = () => {
        setShowDeleteConfirmDialog(true);
        toggleActionMenu();
    }

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
    }

    const handleConfirmDelete = () => {
        if (selectedForm !== undefined) {
            FormStore.sharedInstance().deleteForm(selectedForm.id);
            setShowDeleteConfirmDialog(false);
        }
    }

    const handleCopyCancel = () => {
        toggleCopyFormDialog();
    }

    const toggleCopyFormDialog = () => {
        setShowCopyDialog((prevCopyDialog) => !prevCopyDialog);
    }
    
    const headers = FORM_LIST_HEADERS;

    const onHeaderClick = (header) => {
        let sortLabel = "";
        if (header.label === FORM_LIST_HEADERS[0].label) 
        	sortLabel = SORT_MENU_OPTIONS[0].label;
        else if (header.label === FORM_LIST_HEADERS[1].label) 
            sortLabel = SORT_MENU_OPTIONS[1].label;
        else if (header.label === FORM_LIST_HEADERS[2].label) 
            sortLabel = SORT_MENU_OPTIONS[2].label;
        else if (header.label === FORM_LIST_HEADERS[3].label) 
            sortLabel = SORT_MENU_OPTIONS[3].label;
        else sortLabel = header.label;

        if (sortParameter.label !== sortLabel) /*different from current selection*/
            props.onHeaderClick({ label: sortLabel, value: header.value, order: "desc" });
        else /*toggling*/
            props.onHeaderClick({ label: sortLabel, value: header.value, 
            	order: sortParameter.order === "asc" ? "desc" : "asc" });
    }

    useEffect(() => {
        setSortParameter(props.sortParam);
    }, [props.sortParam]);

    const getCategory = (form, name) =>{
        let filtered = form.formCategories.filter(formCategory => formCategory.categoryType === name);
        if (filtered.length) return filtered[0].categoryName;
        else return "NA";
    }

    return (
        <TableContainer component="div">
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableCell id={header.id} key={index} 
                            	style={{ cursor: "pointer" }} 
                            	onClick={() => { onHeaderClick(header) }}>
                                {header.label}
                                {sortParameter.label === SORT_MENU_OPTIONS[index].label && 
                                	sortParameter.order === "desc" &&
                                    <ArrowDownwardOutlinedIcon fontSize="inherit" 
                                    	viewBox="0 0 20 20" 
                                    	style={{ marginLeft: "5px", color: "#419BAC" }} />}
                                {sortParameter.label === SORT_MENU_OPTIONS[index].label && 
                                	sortParameter.order === "asc" &&
                                    <ArrowUpwardOutlinedIcon fontSize="inherit" 
                                    	viewBox="0 0 20 20" 
                                    	style={{ marginLeft: "5px", color: "#419BAC" }} />}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.items.map(({ ...item }, index) => (
                        <TableRow hover onClick={(event) => handleClick(item.id)} 
                        	key={index}>
                            <TableCell component="th" scope="row">
                                <Typography component="div">
                                    {item.title}
                                </Typography>
                                {item.keywords.map((kValue, kIndex) => (
                                	<Typography component="span" 
                                		variant="caption" key={kValue}>
                                    	<RenderDelimeter index={kIndex} /> {kValue}
                                    </Typography>
                                ))}
                            </TableCell>
                            <TableCell>{item.languageName}</TableCell>
                            <TableCell>
                            	{(item.formStatus === FORM_STATUS.PUBLISHED) ? 
                            			item.publishedVersion : item.version}
                            </TableCell>
                            <TableCell>{item.formStatus}</TableCell>
                            <TableCell>{item.createdBy}</TableCell>
                            <TableCell>{dateHelperService.formattedDateFromUTS(item.lastUpdatedDate)}</TableCell>
                            <TableCell>
                            	{getCategory(item, FORM_CATEGORIES.BUSINESS)}
                            </TableCell>
                            <TableCell>{getCategory(item, FORM_CATEGORIES.SUB_BUSINESS)}</TableCell>
                            <TableCell>
                            	{getCategory(item, FORM_CATEGORIES.GROUP)}
                            </TableCell>
                            <TableCell>
                                {getCategory(item, FORM_CATEGORIES.SUB_GROUP)}
                                <IconButton id="copyActions" edge="end" 
                                	aria-label="more" aria-controls="long-menu" 
                                	aria-haspopup="true"
                                    onClick={(event) => handleMoreClick(event, item)} 
                                	onMouseLeave={onClosePopup}>
                                    <MoreVertIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <FormDialog open={open} handleConfirm={handleRedirect} 
            	handleCancel={handleCancel} />
            <FormListActions open={copyOpen} anchorEl={anchorEl} 
            	selectedForm={selectedForm} 
            	handleActionCancel={handleClosecopyMenu} handleCopy={handleCopy} 
            	handleDelete={handleDelete} ></FormListActions>
            <CopyFormDialog open={showCopyDialog} onClose={handleCopyCancel} />
            {(showDeleteConfirmDialog) ?
                <AlertDialog open={showDeleteConfirmDialog}
                    onClose={handleCancelDelete} handleOk={handleConfirmDelete}
                    dialogTitle={ALERT_DIALOGS.DELETE_FORM_TITLE}
                    dialogContent={ALERT_DIALOGS.DELETE_FORM_CONTENT}
                    cancelButton={ALERT_DIALOGS.DELETE_FORM_CANCEL}
                    confirmButton={ALERT_DIALOGS.DELETE_FORM_CONFIRM}
                /> : null}
        </TableContainer>
    );
}

function RenderDelimeter(props) {
    if (props.index !== undefined) {
        if (props.index > 0) 
        	return (<span style={{ padding: '4px' }}>&#183;</span>);
    }

    return (<span></span>);
}

export default FormGridView;