import BarcodeScanner from "./BarcodeScanner";
import CheckBox from "./CheckBox";
import DateTimeElement from "./DateTimeElement";
import DependentDropdown from "./DependentDropdown";
import Dropdown from "./Dropdown";
import FileUploader from "./FileUploader";
import InstructionHelp from "./InstructionHelp";
import MultiLineInput from "./MultiLineInput";
import FormQuestions from "./question/FormQuestions";
import RadioGroup from "./RadioGroup";
import RangeSlider from "./RangeSlider";
import Section from "./Section";
import Signature from "./Signature";
import SingleLineInput from "./SingleLineInput";
import SubSection from "./SubSection";


const Components = {
    section: Section,
    subsection: SubSection,
    question: FormQuestions,
    text: SingleLineInput,
    textarea: MultiLineInput,
    radio: RadioGroup,
    checkbox: CheckBox,
    select: Dropdown,
    date: DateTimeElement,
    range: RangeSlider,
    fileInput: FileUploader,
    dependentDropdown: DependentDropdown,
    barcode: BarcodeScanner,
    signature: Signature,
    helpimage: InstructionHelp
};

export default Components;