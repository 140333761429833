import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import { Card, Divider, Grid, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { categoryService } from '../services/category-state.service';
import { toast } from 'react-toastify';
import FormBuilderService from '../services/form-builder.service';
import { Loader } from '../components/Loader';
import Toolbar from '@material-ui/core/Toolbar';
import { FormStore, formState, formValidState } from '../services/form.store';
import { formListService } from '../services/form-list.service';
import DialogFooter from '../components/form-element/DialogFooter';
import { Redirect } from 'react-router-dom';
const styles = theme => ({
  root: { padding: '12px' },
  keywordLink: {
    textDecoration: 'underline',
    '&:hover': { textDecoration: 'none' },
  },
  keywordFormCell: { paddingBottom: 0 },
  toolBar: {
    backgroundColor: '#242D3E',
    bottom: 0,
    top: 'auto',
    position: 'relative',
    width: '100%',
  },
  resetButton: { border: '1px solid white', color: 'white' },
  flexGrow: { flexGrow: 1 },
});

const formValues = {
  title: '',
  description: '',
  keywords: [],
  business: '',
  subbusiness: '',
  group: '',
  subgroup: '',
  language: '',
};

const recentKeywords = []; /*TODO: Recent keywords will be populated later*/
let keywords = [];

class CreateFrom extends Component {
  constructor() {
    super();
    this.formBuilderService = new FormBuilderService();
    const maxLength = 5;
    let formType = 'updateForm';
    this.state = {
      business: [],
      subbusiness: [],
      group: [],
      subgroup: [],
      languages: [],
      formvalues: formValues,
      expanded: false,
      isValid: false,
      showError: false,
      loading: false,
      selectedCategories: [],
      maxLength: maxLength,
      formType: formType,
      navigate: false,
      redirect: null,
      showFormNameError: false,
      error: { showKeywords: false },
    };
    this.onTagsChange = this.onTagsChange.bind(this);
  }

  componentDidMount() {
    this.subscription = categoryService.didChangeCategory().subscribe(response => {
      for (var key in response) {
        this.updateCategory(key, response[key]);
      }
    });
    this.language = categoryService.didChangeLanguage().subscribe(response => {
      var options = {};
      options['options'] = response;
      this.updateCategory('languages', options);
    });
    this.validSubscription = formValidState.subscribe(response => {
      if (response === false) {
        this.setState({ showError: true });
      }
    });
    categoryService.initializeCategories();
    categoryService.loadLanguages();
    if (this.props.updateForm || this.props.copyForm) {
      this.formSubscription = formState.subscribe(response => {
        keywords = response.keywords;
        this.setState({
          formvalues: {
            ...this.state.formvalues,
            id: response.id,
            formCode: response.formCode,
            title: 'Copy of ' + response.title,
            description: response.description,
            keywords: response.keywords,
            language: response.defaultLanguageCode,
            formStatus: response.formStatus,
            version: response.version,
            publishedversion: response.publishedversion,
          },
        });
        this.loadCategoriesFor(response);
      });
    }
  }

  loadCategoriesFor = formItem => {
    if (formItem.formCategories.length > 0) {
      formListService.getFormCategories(formItem.id).then(res => {
        this.processCategory(res);
      });
    }
  };

  processCategory = categoryResponse => {
    const categories = categoryResponse.data;
    if (categories.length > 0) {
      categories.forEach((category, i) => {
        const catType = category.categoryType.replace('-', '').toLowerCase();
        const catName = category.categoryName.replace('-', '');
        this.setState({
          formvalues: {
            ...this.state.formvalues,
            [catType]: catName,
          },
        });
        this.setState({
          selectedCategories: [...this.state.selectedCategories, { [catType]: false }],
        });
        if (catType !== 'business' || catType !== 'subbusiness') {
          categoryService.selectCategory(catType, catName);
        }
      });
    }
  };

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.language.unsubscribe();
    this.validSubscription.unsubscribe();
  }

  handleExpandClick = event => {
    this.setState({ expanded: !this.state.expanded });
  };

  categoryChangeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.updateFormValue(name, value);
    categoryService.selectCategory(name, value);
    FormStore.sharedInstance().getFormCategoryDetails(name, value, this.state[name]);
    if (this.props.updateForm || this.props.copyForm) {
      FormStore.sharedInstance().updateFormCategories(name, value, this.state[name]);
    }
  };

  changeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'title') {
      clearTimeout(null);
      setTimeout(() => {
        this.handleChange(value);
      }, 1000);
    }
    this.updateFormValue(name, value);
    FormStore.sharedInstance().getFormDetails(name, value);
    if (this.props.updateForm || this.props.copyForm) {
      FormStore.sharedInstance().updateFormDetails(name, value);
    }
  };

  handleChange = value => {
    this.formBuilderService.isValidName(value).then(res => {
      this.setState({ showFormNameError: res.data });
    });
  };

  onTagsChange = (event, values) => {
    let validTag = this.getHelperTextForKeyword(values).length > 0 ? true : false;
    if (values) {
      this.setState({
        formvalues: { ...this.state.formvalues, keywords: values },
      });
      FormStore.sharedInstance().getFormDetails('keywords', values);
      if (this.props.updateForm || this.props.copyForm) {
        FormStore.sharedInstance().updateFormDetails('keywords', values);
      }
      let error = this.state.error;
      error.showKeywords = validTag;
      this.setState({ error: error });
    }
  };

  updateFormValue = (name, value) => {
    this.setState({
      formvalues: {
        ...this.state.formvalues,
        [name]: value,
      },
    });
  };

  updateCategory = (name, categoryInfo) => {
    const options = categoryInfo['options'];
    this.setState({
      [name]: options,
    });
    if (options.length && this.state.selectedCategories.some(item => item.hasOwnProperty(name))) {
      let selected = [...this.state.selectedCategories];
      let index = selected.findIndex(item => item.hasOwnProperty(name));
      selected[index][name] = true;
      this.setState({ selectedCategories: selected });
    }

    const value = categoryInfo['value'];
    if (value !== undefined) {
      this.updateFormValue(name, value);
      FormStore.sharedInstance().getFormCategoryDetails(name, value, options);
      if (this.props.updateForm) {
        FormStore.sharedInstance().updateFormCategories(name, value, options);
      }
    }
  };

  formResetHandler = () => {
    this.setState({
      business: [],
      subbusiness: [],
      group: [],
      subgroup: [],
      languages: [],
      formvalues: formValues,
      expanded: false,
      isValid: false,
      showError: false,
      loading: false,
      error: { showKeywords: false },
    });
    categoryService.initializeCategories();
    categoryService.loadLanguages();
  };

  requiredFieldValidator(value) {
    return value && value.length > 0 ? true : false;
  }

  validateRequiredFields(formValues) {
    return this.requiredFieldValidator(formValues.title) &&
      this.requiredFieldValidator(formValues.business) &&
      this.requiredFieldValidator(formValues.language) &&
      this.requiredFieldValidator(formValues.keywords)
      ? true
      : false;
  }

  minLengthValidator(value, minLength) {
    if (value !== '') {
      return value.trim().length >= minLength ? true : false;
    } else {
      return true;
    }
  }

  validateMinLength(formValues) {
    return this.minLengthValidator(formValues.title, 3) &&
      this.minLengthValidator(formValues.description, 3)
      ? true
      : false;
  }

  maxLengthValidator(value, maxLength) {
    if (Array.isArray(value)) {
      return this.hasEmptyKeyword(value);
    } else if (value !== '') {
      return value.length <= maxLength ? true : false;
    } else {
      return true;
    }
  }

  hasEmptyKeyword(value) {
    if (Array.isArray(value)) {
      let ind = value.findIndex(item => item.trim().length === 0);
      if (ind !== -1) {
        value.splice(ind, 1);
        let formvalues = this.state.formvalues;
        formvalues.keywords = value;
        this.setState({ formvalues: formvalues });
        if (value.length === 0) {
          return true;
        } else {
          let validity = value.length > this.state.maxLength ? true : false;
          return validity;
        }
      } else {
        let validity = value.length > this.state.maxLength ? true : false;
        return validity;
      }
    }
  }

  validateKeywordsLength(formValues) {
    let validTag = !this.hasEmptyKeyword(formValues.keywords);
    let error = this.state.error;
    this.setState({ error: error });
    return validTag;
  }

  alphanumericValidator(value) {
    if (value !== '') {
      const pattern = /^\d*[a-zA-Z]{1,}\d*/; /*This RegEx is safe. No need to fix.*/
      return pattern.test(value.trim());
    } else {
      return true;
    }
  }

  validateAlphanumeric(formValues) {
    return this.alphanumericValidator(formValues.title) &&
      this.alphanumericValidator(formValues.description)
      ? true
      : false;
  }

  formSubmitHandler = () => {
    const isValid = this.validateForm();
    if (isValid) {
      if (this.props.updateForm) {
        FormStore.sharedInstance().updateFormMetaData(this.state.formType);
        return;
      } else if (this.props.copyForm) {
        this.setState(
          {
            formType: 'copyForm',
          },
          () => {
            FormStore.sharedInstance().updateFormMetaData(this.state.formType);
          }
        );
      } else {
        this.createForm();
        return;
      }
    }
    this.setState({ showError: true });
  };

  validateForm = () => {
    var valid = false;
    let formValues = this.state.formvalues;
    if (
      this.validateRequiredFields(formValues) &&
      this.validateMinLength(formValues) &&
      this.validateKeywordsLength(formValues) &&
      this.validateAlphanumeric(formValues) &&
      this.state.showFormNameError === false
    ) {
      valid = true;
    }
    this.setState({ isValid: valid });
    return valid;
  };

  getHelperTextForName(value) {
    let helperText = '';
    if (this.state.showError) {
      if (!this.requiredFieldValidator(value)) {
        helperText = 'Form Name is required!';
      } else if (!this.minLengthValidator(value, 3)) {
        helperText = 'Form Name should have a minimum of 3 characters.';
      } else if (!this.alphanumericValidator(value)) {
        helperText = 'Form Name should be alphanumeric.';
      }
    }
    return helperText;
  }

  getHelperTextForDescription(value) {
    let helperText = '';
    if (this.state.showError) {
      if (!this.minLengthValidator(value, 3)) {
        helperText = 'Form Description should have a minimum of 3 characters.';
      } else if (!this.alphanumericValidator(value)) {
        helperText = 'Form Description should be alphanumeric.';
      }
    }
    return helperText;
  }

  getHelperTextForBusiness(value) {
    let helperText = '';
    if (this.state.showError) {
      if (!this.requiredFieldValidator(value)) {
        helperText = 'Business is required!';
      }
    }
    return helperText;
  }

  getHelperTextForDefaultLanguage(value) {
    let helperText = '';
    if (this.state.showError) {
      if (!value) {
        helperText = 'Default Language is required!';
      }
    }
    return helperText;
  }

  getHelperTextForKeyword(value) {
    let helperText = '';
    if (!this.requiredFieldValidator(value)) {
      helperText = 'At least one keyword is required!';
    } else if (this.maxLengthValidator(value, this.state.maxLength)) {
      helperText = 'More than 5 keywords are not allowed!';
    }
    return helperText;
  }

  createForm = () => {
    this.setState({ loading: true });
    let formValues = FormStore.sharedInstance().sendFormDetails();
    this.formBuilderService.createForm(formValues).then(res => {
      if (res.data) {
        const formId = res.data;
        this.props.history.push('/editForm/' + formId);
        toast.success('Form created successfuly!');
        this.setState({ loading: false });
      } else {
        toast.error('Form could not be created due to errors!');
        this.setState({ loading: false });
      }
    });
  };

  closeDialog = () => {
    this.setState({ selectedCategories: [] });
    if (this.props.updateForm) {
      this.setState({
        formvalues: { ...this.state.formvalues, keywords: keywords },
      });
      FormStore.sharedInstance().updateFormDetails('keywords', keywords);
    }
    FormStore.sharedInstance().showDialog(false);
  };

  isEnabled = () => {
    if (this.state.selectedCategories.length) {
      return this.state.selectedCategories.every(item => item[Object.keys(item)[0]] === true);
    } else {
      return this.state.selectedCategories.length;
    }
  };

  getPayload = () => {
    const values = this.state.formvalues;
    var payload = {};
    payload.title = values.title.trim();
    payload.description = values.description.trim();
    payload.keywords = values.keywords;
    const business = values.business;
    if (business.length > 0) {
      const businessid = this.getCodeFor('business', business);
      if (businessid) {
        payload.business = businessid;
      }
    }

    const subbusiness = values.subbusiness;
    if (subbusiness.length > 0) {
      const subbusinessid = this.getCodeFor('subbusiness', subbusiness);
      if (subbusinessid) {
        payload.subbusiness = subbusinessid;
      }
    }

    const group = values.group;
    if (group.length > 0) {
      const groupid = this.getCodeFor('group', group);
      if (groupid) {
        payload.group = groupid;
      }
    }

    const subgroup = values.subgroup;
    if (subgroup.length > 0) {
      const subgroupid = this.getCodeFor('subgroup', subgroup);
      if (subgroupid) {
        payload.subgroup = subgroupid;
      }
    }

    const languageid = values.language;
    if (languageid) {
      payload.language = languageid;
    }

    return payload;
  };

  getCodeFor = (type, value, comparator = 'categoryName') => {
    const items = this.state[type];
    var matchedId = null;
    if (items.length > 0) {
      items.forEach(item => {
        if (item[comparator] === value) {
          matchedId = item.id;
        }
      });
    }
    return matchedId;
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {/* <Titlebar title="Create New Form" /> */}
        <Typography component="div" className={classes.root}>
          <div style={{ textAlign: 'center' }}>
            <Loader loading={this.state.loading} />
          </div>
          <Card>
            <form autoComplete="off" noValidate>
              <Box p={3}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      id="title"
                      error={
                        this.state.showFormNameError ||
                        (this.state.showError &&
                          (!this.requiredFieldValidator(this.state.formvalues.title) ||
                            !this.minLengthValidator(this.state.formvalues.title, 3) ||
                            !this.alphanumericValidator(this.state.formvalues.title)))
                      }
                      helperText={
                        this.state.showFormNameError
                          ? 'Form Name Already Exist!'
                          : this.getHelperTextForName(this.state.formvalues.title)
                      }
                      label="Form Name"
                      name="title"
                      value={this.state.formvalues.title}
                      onChange={this.changeHandler}
                      required
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider light />
              <Box p={3}>
                <Grid item>
                  <TextField
                    fullWidth
                    label="Description about your form"
                    multiline
                    minRows="2"
                    name="description"
                    variant="outlined"
                    value={this.state.formvalues.description}
                    error={
                      this.state.showError &&
                      (!this.minLengthValidator(this.state.formvalues.description, 3) ||
                        !this.alphanumericValidator(this.state.formvalues.description))
                    }
                    helperText={this.getHelperTextForDescription(this.state.formvalues.description)}
                    onChange={this.changeHandler}
                  />
                </Grid>
              </Box>
              <Divider light />
              <Box p={3}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Business"
                      name="business"
                      required
                      variant="outlined"
                      value={this.state.formvalues.business}
                      error={
                        this.state.showError &&
                        !this.requiredFieldValidator(this.state.formvalues.business)
                      }
                      helperText={this.getHelperTextForBusiness(this.state.formvalues.business)}
                      onChange={this.categoryChangeHandler}
                    >
                      {this.state.business.length > 0 ? (
                        this.state.business.map(option => (
                          <MenuItem key={option.id} value={option.categoryName}>
                            {option.categoryName}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key="none" value="none">
                          None
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Sub-Business"
                      name="subbusiness"
                      variant="outlined"
                      value={this.state.formvalues.subbusiness}
                      onChange={this.categoryChangeHandler}
                      disabled={!this.state.formvalues.business.length}
                    >
                      {this.state.subbusiness.length > 0 ? (
                        this.state.subbusiness.map(option => (
                          <MenuItem key={option.id} value={option.categoryName}>
                            {option.categoryName}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key="none" value="none">
                          None
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Group"
                      name="group"
                      variant="outlined"
                      value={this.state.formvalues.group}
                      onChange={this.categoryChangeHandler}
                      disabled={!this.state.formvalues.subbusiness.length}
                    >
                      {this.state.group.length > 0 ? (
                        this.state.group.map(option => (
                          <MenuItem key={option.id} value={option.categoryName}>
                            {option.categoryName}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key="none" value="none">
                          None
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Sub-Group"
                      name="subgroup"
                      variant="outlined"
                      value={this.state.formvalues.subgroup}
                      onChange={this.categoryChangeHandler}
                      disabled={!this.state.formvalues.group.length}
                    >
                      {this.state.subgroup.length > 0 ? (
                        this.state.subgroup.map(option => (
                          <MenuItem key={option.id} value={option.categoryName}>
                            {option.categoryName}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key="none" value="none">
                          None
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
              <Divider light />
              <Box p={3}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Default Language"
                      name="language"
                      variant="outlined"
                      required
                      value={this.state.formvalues.language}
                      error={this.state.showError && !this.state.formvalues.language}
                      helperText={this.getHelperTextForDefaultLanguage(
                        this.state.formvalues.language
                      )}
                      onChange={this.changeHandler}
                      disabled={this.props.updateForm}
                    >
                      {this.state.languages.map(option => (
                        <MenuItem key={option.languageCode} value={option.languageCode}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
              <Divider light />
              <Box p={3} className={classes.keywordFormCell}>
                <Grid item>
                  <Autocomplete
                    multiple
                    freeSolo
                    autoSelect
                    id="tags-filled"
                    value={this.state.formvalues.keywords}
                    options={
                      recentKeywords && recentKeywords.length > 0
                        ? recentKeywords.map(option => option.title)
                        : []
                    }
                    onChange={this.onTagsChange}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) =>
                        option.trim() !== '' ? (
                          <Chip
                            color="secondary"
                            className={classes.inputKeyword}
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          ></Chip>
                        ) : null
                      )
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Keywords [Min. 1]"
                        name="keywords"
                        required
                        error={
                          (this.state.showError &&
                            !this.requiredFieldValidator(this.state.formvalues.keywords) &&
                            !this.maxLengthValidator(
                              this.state.formvalues.keywords,
                              this.state.maxLength
                            )) ||
                          this.state.error.showKeywords
                        }
                        helperText={this.getHelperTextForKeyword(this.state.formvalues.keywords)}
                        placeholder="Enter your tag"
                      ></TextField>
                    )}
                  ></Autocomplete>
                  <Box pt={1} pb={1}></Box>
                </Grid>
              </Box>
              <Divider light />
            </form>
          </Card>
          <br />
        </Typography>
        {this.props.updateForm || this.props.copyForm ? (
          <DialogFooter
            cancel={this.closeDialog}
            disabled={this.isEnabled}
            copyForm={this.props.copyForm}
            save={this.formSubmitHandler}
          ></DialogFooter>
        ) : (
          <Toolbar className={classes.toolBar}>
            <span className={classes.flexGrow}></span>
            <Button
              id="reset"
              className={classes.resetButton}
              variant="outlined"
              color="inherit"
              disableElevation
              onClick={this.formResetHandler}
            >
              Reset
            </Button>
            <Button variant="contained" disableElevation onClick={this.formSubmitHandler}>
              Create Form
            </Button>
          </Toolbar>
        )}
        {this.state.navigate && this.state.redirect ? (
          <Redirect to={this.state.redirect}></Redirect>
        ) : null}
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CreateFrom);
