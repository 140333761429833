import React, { Fragment } from "react";
import { Box, TextField, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { FormStore } from "../../services/form.store";
import { ATTRIBUTES } from "../../services/form.helper.service";
import  FormBuilderService from "../../services/form-builder.service";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  activeTextField: {
    backgroundColor: "rgba(255, 255, 255, .75)",
  },
  maxLenthStyle: {
    width: "50%",
  },
});
export class BarcodeScanner extends React.Component {
  constructor(props) {
    super();
    this.state = {
      data: [],
      selectedOption: props.element.placeHolder,
    };
    this.formBuilderService = new FormBuilderService();
  }

  componentDidMount() {
    this.formBuilderService.getBarcodeTypes().then((res) => {
      if(res.data){
        this.setState({ data: res.data });
      }
    })
  }

  handleOption = (e) => {
    this.setState({ selectedOption: e.target.value });
    FormStore.sharedInstance().updateElementProperties(ATTRIBUTES.PLACEHOLDER, e.target.value);
  };
  
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Box className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                id="barcode"
                label="BARCODE TYPE"
                name="barcode"
                variant="outlined"
                className={classes.activeTextField}
                value={this.state.selectedOption}
                onChange={this.handleOption}
              >
                {this.state.data.map((item) => (
                  <MenuItem id="menuName" key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    );
  }
}

export default withStyles(styles)(BarcodeScanner);
