import axios from 'axios';

let accessToken = localStorage.getItem('access_token') ? 
  localStorage.getItem('access_token') : '';
let config = {};
if(accessToken && accessToken.length > 0) 
  config = {
	headers:{ 'Authorization': 'Bearer ' + accessToken + '', 'tenantId': 'so' }
  };

const baseURL = 'https://internal-uai3027660-fw2-alb-web-dev-lb-303578408.us-east-1.elb.amazonaws.com/api/images/getImage?blobStoreKey=fw-service_so_null-fw-services-test-001-95';

export async function InstructionHelpService() {
  return await axios.get(baseURL, config)
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      console.error(error);
    });
}