import React, { Component, Fragment } from 'react';
import { Toolbar, Switch, FormControlLabel, IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/styles';
import MenuMap from '../CompMenuMap';
import { FormStore } from '../../../services/form.store';
import { ATTRIBUTES } from '../../../services/form.helper.service';

const styles = theme => ({
  root: { borderTop: '1px solid rgb(91, 195, 115)' },
  flexGrow: { flexGrow: 1 },
  switchLabel: { marginLeft: 2, marginRight: 20 },
  switch: { marginLeft: 3 },
});

const HELP_TEXT = 'Help Text';
class ActiveQuestionFooter extends Component {
  constructor(props) {
    super();
    let required = false, hidden = true, disabled = true;
    if (props.element.validations && 
    	props.element.validations.required!==undefined) 
    	required = props.element.validations.required;
    if (props.element.properties && 
    	props.element.properties.visible!==undefined) 
    	hidden = props.element.properties.visible;
    if (props.element.properties && 
    	props.element.properties.disabled!==undefined) 
    	disabled = props.element.properties.disabled;
    this.state = {
      required: required, hide: !hidden, ansTxt: props.element.componentType !== 'radio' ? !disabled : disabled,
      helpImage: (props.element.properties &&
    		  	  props.element.properties.hasHelpImage) ? 
    		  	 props.element.properties.hasHelpImage : false,
      anchorEl: null, element: props.element
    }
  }

  toggleRequiredChecked = (event) => {
    this.setState({ required:  event.target.checked });
    FormStore.sharedInstance().toggleQuestionAttr({attr: 'validations',
    	name: 'required', value: event.target.checked});
  }

  toggleHiddenChecked = (event) => {
    this.setState({ hide:  event.target.checked });
    FormStore.sharedInstance().toggleQuestionAttr({attr: 'properties',
    	name: 'visible', value: !event.target.checked});
  }

  toggleAnswerChecked = (event) => {
    this.setState({ ansTxt: event.target.checked });
    FormStore.sharedInstance().toggleQuestionAttr({attr: 'properties',
    	name: 'disabled', value: this.props.element.componentType !== 'radio' ? !event.target.checked : event.target.checked});
    if (event.target.checked) {
      if (!this.props.element.properties.answerLabel && this.props.element.properties.answerLabel !== HELP_TEXT)
        FormStore.sharedInstance().toggleQuestionAttr({attr: 'properties', name: 'answerLabel', value: this.props.answer ?? HELP_TEXT });
    } else {
      FormStore.sharedInstance().toggleQuestionAttr({attr: 'properties', name: 'answerLabel', value: '' });
    }
    this.props.answerText(event.target.checked);
  }

  toggleHelpImageChecked = (event) => {
    this.setState({ helpImage: event.target.checked });
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', 
    	name: ATTRIBUTES.HAS_HELP_IMAGE, value: event.target.checked });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = (event) => {
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Toolbar className={classes.root}>
          {(this.props.element.componentType === "helpimage") ?
            <FormControlLabel id="helpImage" label="Help Image"
              labelPlacement="start" className={classes.switchLabel}
              control={
                <Switch size="small" checked={this.state.helpImage} 
                  onChange={this.toggleHelpImageChecked} name="helpImage" 
                  color="primary" className={classes.switch} />
              } />
            :
            <div>
              <FormControlLabel id="required" label="Required" 
            	labelPlacement="start" className={classes.switchLabel}
                control={
                  <Switch size="small" checked={this.state.required} 
                    onChange={this.toggleRequiredChecked} name="required" 
                    color="primary" className={classes.switch} />
                } />
              <FormControlLabel id="hidden" label="Hidden" 
            	labelPlacement="start" className={classes.switchLabel}
                control={
                  <Switch size="small" checked={this.state.hide} 
                    onChange={this.toggleHiddenChecked} name="hide" 
                    color="primary" className={classes.switch} />
                } />
              <FormControlLabel id="answerHint" label="Help Text" 
            	labelPlacement="start" className={classes.switchLabel}
                control={
                  <Switch size="small" checked={this.state.ansTxt} 
                    onChange={this.toggleAnswerChecked} name="ansTxt" 
                    color="primary" className={classes.switch} />
                } />
                <FormControlLabel id="helpImage" label="Help Image"
              labelPlacement="start" className={classes.switchLabel}
              control={
                <Switch size="small" checked={this.state.helpImage}
                  onChange={this.toggleHelpImageChecked} name="helpImage"
                  color="primary" className={classes.switch} />
              } />
            </div>
          }
          <div className={classes.flexGrow} />
          <IconButton id="moreIcon" edge="end" aria-label="more" 
        	  aria-controls="long-menu" aria-haspopup="true" 
        	  onClick={this.handleClick} >
            <MoreVertIcon />
          </IconButton>
          <Menu id="questionOptionsMenu" elevation={3} 
          	anchorEl={this.state.anchorEl} keepMounted 
          	open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose} getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
            <RenderOptionsMenu id="renderOptionMenu" 
            	componentType={this.state.element.componentType} 
            	element={this.props.element} />
          </Menu>
        </Toolbar>
      </Fragment>
    )
  }
}

function RenderOptionsMenu(props) {
  if (props.componentType !== undefined) {
    const MenuName = MenuMap[props.componentType];
    return <MenuName element={props.element} />;
  }
  return (<div> Unknown Menu Type </div>);
}

export default withStyles(styles)(ActiveQuestionFooter);