import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import { FormStore } from '../../../services/form.store';
import { ATTRIBUTES, ANSWER_OPTION_LABELS } from '../../../services/form.helper.service';
import LinkOptionalEquipment from '../LinkOptionalEquipment';
import AssignUniqueCode from '../AssignUniqueCode';

const useStyles = makeStyles((theme) => ({
  root: {},
  sideNavDivider: {
    marginLeft: 16,
  }
}));

const AnswerOption = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [popup, setPopup] = useState(false);
  const [assignUniqueCodePopup, setAssignUniqueCodePopup] = useState(false);
  const [menuItem, setMenuItem] = useState([
    {
      label: "Answer Options",
      children: [
        { label: "Max Length", isChecked: props.element.properties && props.element.properties.hasMaxLength ? props.element.properties.hasMaxLength : false, name: ATTRIBUTES.HAS_MAX_LENGTH },
        { label: 'Allow File Upload', isChecked: props.element.properties && props.element.properties.allowFileUpload ? props.element.properties.allowFileUpload : false, name: ATTRIBUTES.ALLOW_FILE_UPLOAD }
      ],
    },
    {
      label: "Response Validation",
      children: [
        { label: "Number Only", isChecked: props.element.validations && props.element.validations.numberOnly ? props.element.validations.numberOnly : false, name: ATTRIBUTES.NUMBER_ONLY },
        { label: "Phone Number", isChecked: props.element.validations && props.element.validations.phoneNumber ? props.element.validations.phoneNumber : false, name: ATTRIBUTES.PHONE_NUMBER },
        { label: "Email", isChecked: props.element.validations && props.element.validations.email ? props.element.validations.email : false, name: ATTRIBUTES.EMAIL },
      ],
    },
    {
      label: "Other Actions",
      children: [
        { label: ANSWER_OPTION_LABELS.LINK_OPTIONAL_EQUIPMENT },
        { label: ANSWER_OPTION_LABELS.ASSIGN_UNIQUE_CODE },
      ],
    },
  ]);

    const handleToggleSwitch = (index, mainIndex) => (event) => {
      let newArr = [...menuItem];
      const child = newArr[mainIndex].children[index];
      const { name } = child;
      child.isChecked = event.target.checked;
      const resetAttributes = (attributesToReset) => {
        attributesToReset.forEach(attrName => {
          const attrIndex = newArr[mainIndex].children.findIndex(child => child.name === attrName);
          if (attrIndex !== -1) {
            newArr[mainIndex].children[attrIndex].isChecked = false;
            FormStore.sharedInstance().toggleQuestionAttr({
              attr: 'validations',
              name: attrName,
              value: false,
            });
          }
        });
      };
          if (name === ATTRIBUTES.NUMBER_ONLY && event.target.checked) {
        resetAttributes([ATTRIBUTES.PHONE_NUMBER, ATTRIBUTES.EMAIL]);
      } else if (name === ATTRIBUTES.PHONE_NUMBER && event.target.checked) {
        resetAttributes([ATTRIBUTES.EMAIL, ATTRIBUTES.NUMBER_ONLY]);
      } else if (name === ATTRIBUTES.EMAIL && event.target.checked) {
        resetAttributes([ATTRIBUTES.PHONE_NUMBER, ATTRIBUTES.NUMBER_ONLY]);
      }
    
    setMenuItem(newArr);
    if (event.target.name === ATTRIBUTES.ALLOW_FILE_UPLOAD) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.ALLOW_FILE_UPLOAD, value: event.target.checked });
    } else if (event.target.name === ATTRIBUTES.HAS_MAX_LENGTH) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.HAS_MAX_LENGTH, value: event.target.checked });
      if (event.target.checked) {
        FormStore.sharedInstance().toggleQuestionAttr({ attr: 'validations', name: ATTRIBUTES.MAX_LENGTH, value: 10 });
      } else {
        FormStore.sharedInstance().toggleQuestionAttr({ attr: 'validations', name: ATTRIBUTES.MAX_LENGTH, value: 0 });
      }
    } else if (event.target.name === ATTRIBUTES.EMAIL) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'validations', name: ATTRIBUTES.EMAIL, value: event.target.checked });
    } else if (event.target.name === ATTRIBUTES.PHONE_NUMBER) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'validations', name: ATTRIBUTES.PHONE_NUMBER, value: event.target.checked });
    } else if (event.target.name === ATTRIBUTES.NUMBER_ONLY) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'validations', name: ATTRIBUTES.NUMBER_ONLY, value: event.target.checked });
    }
  };

  const handleListItem = (event, child) => {
    if (child.label === ANSWER_OPTION_LABELS.LINK_OPTIONAL_EQUIPMENT) {
      setPopup(true);
    }
    else if (child.label === ANSWER_OPTION_LABELS.ASSIGN_UNIQUE_CODE) {
      setAssignUniqueCodePopup(true);
    }
  }

  const onClose = () => {
    setPopup(false);
  }

  const onCloseAssignUniqueCodePopup = () => {
    setAssignUniqueCodePopup(false);
  }

  return (
    <div ref={ref}>
      {menuItem.map(({ ...item }, mainIndex) => (
        <span id="menuSection" key={mainIndex}>
          <ListSubheader>
            <Typography variant="overline" component="div" style={{ marginTop: 5 }}>
              {item.label}
            </Typography>
          </ListSubheader>
          {item.children.map((child, index) => (
            <span id="menuOption" key={child.label}>
              <MenuItem>
                <ListItemText primary={child.label} onClick={(event) => handleListItem(event, child)} />
                <ListItemSecondaryAction>
                  {("isChecked" in child ) ?
                    <Switch edge="end"  
                      name={child.name}
                      checked={child.isChecked}
                      size="small"
                      color="primary"
                      onChange={handleToggleSwitch(index, mainIndex)}
                    />
                    : null}
                </ListItemSecondaryAction>
              </MenuItem>
              <Divider light className={classes.sideNavDivider} />
            </span>
          ))}
        </span>
      ))}
      <LinkOptionalEquipment
        open={popup} onClose={onClose} title="Link Optional Equipment"
      />
      {assignUniqueCodePopup &&
        <AssignUniqueCode
          onClose={onCloseAssignUniqueCodePopup}
        />
      }
    </div>
  );
});

export default AnswerOption;
