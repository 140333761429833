import React, { Fragment } from 'react';
import { Box, TextField, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FormStore } from "../../services/form.store";
import { ATTRIBUTES } from "../../services/form.helper.service";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  activeTextField: {
    backgroundColor: "rgba(255, 255, 255, .75)",
  },
});

class FileUploader extends React.Component {

  constructor(props) {
    super();
    this.maxImagesOptions = [1, 2, 3, 4, 5, 6, 7];
    this.maxImageSizeOptions = ["1 MB", "2 MB", "3 MB", "4 MB", "5 MB"];
    this.state = {
      maxImages: props.element.properties && props.element.properties.maxImages ? props.element.properties.maxImages : 1,
      maxImageSize: props.element.properties && props.element.properties.maxImageSize ? props.element.properties.maxImageSize : "1 MB"
    };
  }
  
  onMaxImagesChange = event => {
    const value = event.target.value;
    this.setState({
      maxImages: value
    });
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.MAX_IMAGES, value: value });
  }

  onMaxImageSizeChange = event => {
    const value = event.target.value;
    this.setState({
      maxImageSize: value
    });
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.MAX_IMAGE_SIZE, value: value });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Box className={classes.root}>
          <Grid container style={{ width: 'calc(100% - 288px)' }}>
            <Grid item xs={6} style={{ padding:'1px' }}>
              <TextField select fullWidth
                label="MAX IMAGES" id= "maxImages" name="maxImages" variant="outlined"
                className={classes.activeTextField}
                value={this.state.maxImages}
                onChange={this.onMaxImagesChange}>
                {this.maxImagesOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} style={{ padding:'1px' }}>
              <TextField select fullWidth
                label="MAX FILE SIZE" id="maxImageSize" name="maxImageSize" variant="outlined"
                className={classes.activeTextField}
                value={this.state.maxImageSize}
                onChange={this.onMaxImageSizeChange}>
                {this.maxImageSizeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    )
  }
}
export default withStyles(styles)(FileUploader);
