import { Box, Button, IconButton, Grid, Card, TextField, InputAdornment, CardActionArea, Link, MenuItem, Checkbox } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import Titlebar from '../shared/component/Titlebar';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReviewportalService from '../services/review-portal-services/review-portal.service';
import FormBuilderService from '../services/form-builder.service';
import SearchIcon from '@material-ui/icons/Search';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddUser from '../components/form-element/AddUser';
import { roles } from '../utility/Constant';
import { toast } from 'react-toastify';
import AlertDialog from '../components/form-element/AlertDialog';
import { ALERT_DIALOGS } from '../services/form.helper.service';

const styles = (theme) => ({
    addUserButton: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        padding: '5px 15px',
        borderRadius: 3,
    },
    rootSearch: {
        padding: '9px',
    },
    searchBar: {
        width: '30%',
    },
    infoIcon: {
        width: '40px',
        height: '40px',
        textAlign: 'center',
    },
    userMessgae: {
        height: '300px',
        margin: '1%',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    initialPoint: {
        paddingRight: "42px",
    }
});

class UserManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            title: "Users",
            users: [],
            noResultMessage: '',
            showDialog: false,
            firstHalfOfArray: [],
            secondHalfOfArray: [],
            roles: roles,
            selectedUser: {},
            selected: [],
            searchResult : [],
            showDeleteConfirmDialog: false,
        }
        this.selectedUser = {};
        this.reviewPortalService = new ReviewportalService();
        this.formBuilderService = new FormBuilderService();
    }

    componentDidMount() {
       this.getAllUserDetails();
    }

    getAllUserDetails(){
        this.formBuilderService.getUserDetails().then(response => {
            if(response.data){
                const halfWayIndex = Math.ceil(response.data.length / 2);
                this.setState({
                    users: response.data,
                    firstHalfOfArray: response.data.slice(0, halfWayIndex),
                    secondHalfOfArray: response.data.slice(halfWayIndex),
                    title: 'Users(' + response.data.length + ')',
                });
            }
        });
    }

    startTimerForSearch = (value) => {
        if (value.length !== 0) {
            this.setState({
                searchText: value
            });
            const lowercasedValue = value.toLowerCase();
            const searchResult = this.state.users.filter((user) => {
                if (user.sso.includes(value) || 
                		user.userFirstName.toLowerCase().includes(lowercasedValue) ||
                		user.userLastName.toLowerCase().includes(lowercasedValue) ||
                		user.email.toLowerCase().includes(lowercasedValue) ||
                		user.userRole.toLowerCase().includes(lowercasedValue)) {
                    return user;
                }
            });
            if (searchResult.length > 0) {
                const halfWayIndex = Math.ceil(searchResult.length / 2);
                this.setState({
                    searchResult :searchResult,
                    users: searchResult,
                    firstHalfOfArray: searchResult.slice(0, halfWayIndex),
                    secondHalfOfArray: searchResult.slice(halfWayIndex),
                    title: 'Users(' + searchResult.length + ')',
                    noResultMessage: '',
                });
            } else {
                this.setState({
                    searchResult :[],
                    noResultMessage: 'No Results Found',
                    title: 'Users(' + searchResult.length + ')',
                });
            }
        } else {
            this.setState({
                searchText: '',
            });
            this.getAllUserDetails();
        }
    }

    addUser = () => {
        this.setState({
            showDialog: true
        });
    }

    handleClose = () => {
        this.setState({
            showDialog: false
        });
    }

    onChangeRole = (user, event) => {
        let selectedUser = user ;
        if (event) {
            const value = event.target.value;
            var payload = {};
            payload.id = selectedUser.id;
            payload.sso = selectedUser.sso;
            payload.userFirstName = selectedUser.userFirstName;
            payload.userLastName = selectedUser.userLastName;
            payload.email = selectedUser.email;
            payload.createdBy = selectedUser.createdBy;
            payload.createdDate = selectedUser.createdDate;
            payload.userRole = value;
            this.formBuilderService.updateUser(payload).then((res) => {
                if (res.data) {
                    toast.success("User updated successfullyy!");
                    if (this.state.searchResult.length > 0) {
                        this.formBuilderService.getUserDetails().then(response => {
                            if (response.data) {
                                let ssoIds = this.state.searchResult.map(user1 => user.sso);
                                let newArray = response.data.filter((user2) => ssoIds.includes(user.sso));
                                const halfWayIndex = Math.ceil(newArray.length / 2);
                                this.setState({
                                    searchResult :newArray,
                                    users: newArray,
                                    firstHalfOfArray: newArray.slice(0, halfWayIndex),
                                    secondHalfOfArray: newArray.slice(halfWayIndex),
                                });
                            }
                        });
                    } else {
                        this.getAllUserDetails();
                    }
                } else {
                    toast.error("User could not be updated due to errors!")
                }
            });
        }
    }

    showSelectedRole = (user) => {
        return user.userRole;
    }

    onSelectCheckbox = (userId) => {
        const checkedIndex = this.state.selected.findIndex(element => element === userId);
        let newSelected = [];
        if (checkedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, userId);
        } else if (checkedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (checkedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (checkedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, checkedIndex),
                this.state.selected.slice(checkedIndex + 1),
            );
        }
        if (newSelected.length) {
            this.setState({
                selected: newSelected,
                title: newSelected.length + ' USER SELECTED',
            });
        } else {
            this.setState({
                selected: [],
                title: 'Users(' + this.state.users.length + ')',
            });
        }
    }

    handleDelete = () => {
        this.setState({ showDeleteConfirmDialog: true });
    }

    handleCancelDelete = () => {
        this.setState({
            showDeleteConfirmDialog: false,
            selected: [],
            title : 'Users(' + this.state.users.length + ')',
        });
    }

    handleConfirmDelete = () => {
        let selected = this.state.selected;
        if (this.state.selected !== undefined) {
            this.formBuilderService.deleteUser(selected).then(response => {
                if (response.data) {
                    toast.success("User deleted successfuly!");
                    this.getAllUserDetails();
                } else {
                    toast.error("User could not be deleted due to errors!")
                }
            });
            this.setState({
                showDeleteConfirmDialog: false,
                selected: []
            })
        }
    }

    getUserDetailsFromAddUser = (userDetails) => {
        if(userDetails.length){
            const halfWayIndex = Math.ceil(userDetails.length / 2);
            this.setState({
                users: userDetails,
                firstHalfOfArray: userDetails.slice(0, halfWayIndex),
                secondHalfOfArray: userDetails.slice(halfWayIndex),
                title: 'Users(' + userDetails.length + ')',
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Titlebar title={this.state.title}>
                    <Typography>
                        <IconButton aria-label="delete" onClick={this.handleDelete} disabled={!this.state.selected.length}>
                            <DeleteIcon />
                        </IconButton>
                        <Button className={classes.addUserButton} variant='contained' color="primary" onClick={this.addUser} disableElevation>Add Users</Button>
                    </Typography>
                </Titlebar>
                <Typography component="div" className={classes.root} >
                    <Box>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <Card>
                                    <Typography component="div" className={classes.rootSearch}>
                                        <TextField variant="outlined"
                                            className={classes.searchBar}
                                            id="search"
                                            name="search"
                                            label="Search User by Name, SSO, Email, Role"
                                            autoComplete='off'
                                            value={this.state.searchText}
                                            onChange={event => {
                                                this.startTimerForSearch(event.target.value)
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton edge="start"
                                                            onClick={this.handleExpandClick}>
                                                            <SearchIcon id="searchIcon" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}>
                                        </TextField>
                                    </Typography>
                                    {(this.state.users.length > 0) && (this.state.noResultMessage.length === 0) ?
                                        <Box p={3}>
                                            <Grid container>
                                                <Grid item md={6} xs={6}>
                                                    {this.state.firstHalfOfArray.map(({ ...user }, index) => (
                                                        <Card square variant="outlined" key={index}>
                                                            <CardActionArea>
                                                                <Box p={2}>
                                                                    <Grid container>
                                                                        <Grid item md={8} xs={8}>
                                                                            <Typography gutterBottom>
                                                                                <Checkbox checked={this.state.selected.indexOf(user.id) > -1} onClick={(event) => this.onSelectCheckbox(user.id)} />{user.userFirstName} {user.userLastName} <span>({user.sso})</span>
                                                                            </Typography>
                                                                            <span className={classes.initialPoint}></span><Link underline="always" color="primary">{user.email}</Link>
                                                                        </Grid>
                                                                        <Grid item md={4} xs={4}>
                                                                            <TextField select fullWidth
                                                                                label="Role" name="role" required variant="outlined"
                                                                                value={this.showSelectedRole(user)}
                                                                                onChange={(event) => this.onChangeRole(user, event)}>
                                                                                {(this.state.roles.length > 0) ?
                                                                                    this.state.roles.map((role) => (
                                                                                        <MenuItem key={role.roleId} value={role.roleId}>
                                                                                            {role.roleName}
                                                                                        </MenuItem>)) :
                                                                                    <MenuItem key='none' value='none'>None</MenuItem>}
                                                                            </TextField>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </CardActionArea>
                                                        </Card>
                                                    ))}
                                                </Grid>
                                                <Grid item md={6} xs={6}>
                                                    {this.state.secondHalfOfArray.map(({ ...user }, index) => (
                                                        <Card square variant="outlined" key={index}>
                                                            <CardActionArea>
                                                            <Box p={2}>
                                                                    <Grid container>
                                                                        <Grid item md={8} xs={8}>
                                                                            <Typography gutterBottom>
                                                                                <Checkbox checked={this.state.selected.indexOf(user.id) > -1} onClick={(event) => this.onSelectCheckbox(user.id)} />{user.userFirstName} {user.userLastName} <span>({user.sso})</span>
                                                                            </Typography>
                                                                            <span className={classes.initialPoint}></span><Link underline="always" color="primary">{user.email}</Link>
                                                                        </Grid>
                                                                        <Grid item md={4} xs={4}>
                                                                            <TextField select fullWidth
                                                                                label="Role" name="role" required variant="outlined"
                                                                                value={this.showSelectedRole(user)}
                                                                                onChange={(event) => this.onChangeRole(user, event)}>
                                                                                {(this.state.roles.length > 0) ?
                                                                                    this.state.roles.map((role) => (
                                                                                        <MenuItem key={role.roleId} value={role.roleId}>
                                                                                            {role.roleName}
                                                                                        </MenuItem>)) :
                                                                                    <MenuItem key='none' value='none'>None</MenuItem>}
                                                                            </TextField>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </CardActionArea>
                                                        </Card>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Box> :
                                        <Card className={classes.userMessgae}>
                                            <InfoOutlinedIcon className={classes.infoIcon} /><Typography>No User found!</Typography>
                                        </Card>}
                                    {(this.state.showDeleteConfirmDialog) ?
                                        <AlertDialog id='alertDialog'
                                            open={this.state.showDeleteConfirmDialog}
                                            onClose={this.handleCancelDelete}
                                            handleOk={this.handleConfirmDelete}
                                            dialogTitle={ALERT_DIALOGS.DELETE_FORM_TITLE}
                                            dialogContent={ALERT_DIALOGS.DELETE_USER_CONTENT}
                                            cancelButton={ALERT_DIALOGS.DELETE_FORM_CANCEL}
                                            confirmButton={ALERT_DIALOGS.DELETE_FORM_CONFIRM}
                                        /> : null}
                                </Card>
                                {this.state.showDialog ? <AddUser open={this.state.showDialog} title='Add User' users={this.state.users} getUserDetailsFromAddUser={this.getUserDetailsFromAddUser}
                                    onClose={this.handleClose} /> : null}
                            </Grid>
                        </Grid>
                    </Box>
                </Typography>
            </Fragment>
        )
    }

}

export default withStyles(styles)(UserManagement);