import React, { Component, Fragment } from 'react';
import { Avatar, Box, Card, Checkbox, FormGroup, FormControlLabel, Grid, Typography, FormControl, FormHelperText } from '@material-ui/core';
import { extractCheckBoxValue, extractCheckBoxIndices } from '../../store/form.answer.extract';
import { checkBoxAnswer } from '../../store/form.answer.fill';
import ImagePopup from './ImagePopup';
import { withStyles } from '@material-ui/styles';
import { openSectionState, currentSectionState } from '../../store/form.store';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
    },
});

class CheckBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: [],
            selectedOptionIndices: [],
            imageOpen: false,
            imageValue: [],
            imageUrl: '',
            imageName: '',
            editable: false
        };
    }

    componentDidMount() {
        let expanded = ''
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => { expanded = sectionId });
        this.currentSectionSubscription = currentSectionState.subscribe(section => {
            this.setState({ editable: section === expanded })
        });
        this.onLoadPage(this.props);
        if (this.props.element.properties.allowFileUpload) {
            this.onLoadImage(this.props);
        }
    }

    componentWillUnmount() {
        this.sectionStateSubscription.unsubscribe();
        this.currentSectionSubscription.unsubscribe();
    }

    onLoadPage(props) {
        if (props.element) {
            let answer = props.element.answer;
            let userResponse = extractCheckBoxValue(answer);
            let selectedIndices = extractCheckBoxIndices(answer, props.element.options);
            if (userResponse) {
                this.setState({
                    selectedOptions: userResponse,
                    selectedOptionIndices: selectedIndices
                });
            }
        }
    }

    onLoadImage(props) {
        if (props.element){
            const image = props.element.answer.image;
            if (image) {
                this.setState({ imageValue: image });
            }
        }
    }

    onClickAvatar = (image) => {
        this.setState({
            imageOpen: true,
            imageUrl: image.imageUrl,
            imageName: image.imageName
        });
    }

    onClose = () => {
        this.setState({ imageOpen: false });
    }

    onChange = (option, selectedOptionIndex) => {
        let options = this.state.selectedOptions;
        let selectedIndices = this.state.selectedOptionIndices;
        let index = options.indexOf(option);
        if (!selectedIndices.includes(selectedOptionIndex)) {
            options.push(option);
            selectedIndices.push(selectedOptionIndex);
        } else {
            options.splice(index, 1);
            selectedIndices.splice((selectedIndices.indexOf(selectedOptionIndex)), 1);
        }

        this.setState({ selectedOptions: options }, () => {
            if (this.props.onChange !== undefined) {
                this.props.onChange(checkBoxAnswer(options), this.props.element);
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { selectedOptionIndices } = this.state
        return (
            <Fragment>
                {this.state.editable ? (
                    <FormControl component="fieldset">
                        <FormGroup>
                            {this.props.element.options.map((option, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox 
                                                key={index} 
                                                name={option} 
                                                value={`${index}`} 
                                                onChange={() => {this.onChange(option, index)}}
                                                checked={selectedOptionIndices && !selectedOptionIndices.includes(index) ? false : true} 
                                            />}
                                        label={option}
                                    />
                                )
                            })}
                        </FormGroup>
                        <FormHelperText>{this.props.element.properties.answerLabel}</FormHelperText>
                    </FormControl>
                ) : (
                    <>
                        {this.state.selectedOptions.map((option) => (
                            <Typography>{option}</Typography>
                        ))}
                    </>
                )}
                {(this.props.element.properties.allowFileUpload && this.state.imageValue.length) ? this.state.imageValue.map((image) => (
                    <Card className={classes.root} >
                        <Box>
                            <Grid container spacing={1}>
                                <Avatar alt={image.imageName} src={image.imageUrl} variant="square" style={{ height: '75px', width: '100px', cursor: 'pointer' }}
                                    onClick={() => this.onClickAvatar(image)} />
                            </Grid>
                        </Box>
                    </Card>
                )) : null}
                <ImagePopup open={this.state.imageOpen} onClose={this.onClose} imageUrl={this.state.imageUrl} title={this.state.imageName}></ImagePopup>
            </Fragment>);
    }
}
export default withStyles(styles)(CheckBox);