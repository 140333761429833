export const mockForm = {
    id: 713,
    form_name: '1.x Single Blade Exchange Checklist ',
    description: 'Grease technician 2 main and generator bearing task',
    form_code: 639,
    turbine_serial: 116101447,
    is_delete: false,
    formStatus: "Draft",
    version: "1d",
    tenant_id: null,
    createdDate: 1632374416653,
    updatedDate: 1632374416653,
    formCategories: [
      {
        id: 1,
        categoryName: "Renewables",
        categoryType: "Business"
      },
      {
        id: 4,
        categoryName: "Onshore Wind",
        categoryType: "Sub-Business"
      },
      {
        id: 8,
        categoryName: "Projects",
        categoryType: "Group"
      },
      {
        id: 13,
        categoryName: "Commissioning",
        categoryType: "Sub-Group"
      }
    ],
    submittedBy: {
      sso: "503178655",
      userFirstName: "Ashish",
      userLastName: "Kumar",
      email: "ashish.kumar@ge.com",
      userRole: "EDTIOR",
      tenantId: "ren",
      createdBy: "Ashish",
      updateBy: "503178655",
      createdDate: "2021-11-06",
      updatedDate: null,
      id: 2,
      delete: false
    },
    formSiteRegion: [2],
    keywords: ['renewables'],
    children: [
      {
        id: 3632,
        order: 1,
        elementId: 'a19c5831-4f64-4a6c-b07c-0f41f05d147a',
        componentType: 'section',
        name: 'section_id_0',
        label: 'Basic Info ',
        buttonIcon: 'fa fa-window-maximize',
        buttonName: 'Section',
        optionsLabel: 'Section',
        enabledActions: [],
        enabledConditions: [],
        children: [
          {
            id: 3633,
            motherId: 3632,
            order: 1,
            elementId: '0bcf3dcc-0d5e-499b-853d-4a5fddf1932a',
            componentType: 'select',
            name: 'select_id_1',
            label: 'Site Name',
            buttonName: 'Select Box',
            optionsLabel: 'Select Box',
            enabledActions: [],
            enabledConditions: [],
            answer: [],
            options: [
              { name: 'Ajam', id: 1 },
              { name: 'Abilene Office', id: 2 },
              { name: 'Alta Wind', id: 3 },
              { name: 'Ararat', id: 4 },
              { name: 'Berkshire', id: 5 },
              { name: 'Flat Water', id: 6 },
              { name: 'South Plains', id: 7 },
            ],
            properties: {
              visible: true,
              disabled: false,
              answerLabel: 'Please provide detail of site',
              allowFileUpload: true,
              multiple: false,
              hasOptionalEquipmentValue: 'AKHFENNIR03',
            },
            validations: {
              email: false,
              required: true,
              maxLength: 5,
              numberOnly: false,
              phoneNumber: false,
            },
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3636,
            answer: [
              {
                answerValue: '01/01/2021 05:31',
                answerDescription: '4',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
                image:[{
                  imageId: '',
                  answerId: 1,
                  imageOrder: 2,
                  imageName: 'Second.png',
                  formdata: 3,
                  createdBy: 'dev',
                  createdDate: 1601391694714,
                  lastUpdatedBy: 'dev',
                  lastUpdatedDate: 1601391694714,
                  imageUrl:
                  'https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512__340.jpg',
              }]
              },
            ],
            motherId: 3635,
            order: 2,
            elementId: '17f03fa5-09ed-4502-9b7b-bda8eff8685b',
            componentType: 'date',
            name: 'date_id_0',
            label: '<p>WTG Stop Date</p>',
            buttonName: 'Date',
            optionsLabel: '',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: true,
              answerLabel: '',
              preFilledDate: true,
              hasHoursWorked: true,
              allowFileUpload: true,
              hoursWorkedValue: 0,
              hasOptionalEquipmentValue: 'AKHFENNIR03',
            },
            validations: {
              required: true,
              dateFormat: 'MM/DD/YYYY hh:mm',
            },
            children: [],
            tenantId: 'ren',
          },
          {
            id: 4959,
            motherId: 3632,
            order: 4,
            elementId: '76b1399a-058c-4bea-9dc7-70c100acca34',
            componentType: 'signature',
            name: 'signature_id_3',
            label: 'Lead Technician Signature',
            buttonName: 'Signature',
            optionsLabel: 'Signature',
            enabledActions: [],
            enabledConditions: [],
            answer: [
              {
                answerValue: 'Signature Image',
                answerDescription: 'Renewables',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
              },
            ],
            properties: {
              visible: true,
              disabled: false,
              answerLabel: '',
              allowDuplicate: false,
              allowFileUpload: true,
            },
            validations: {
              required: true,
            },
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3699,
            motherId: 3632,
            order: 3,
            elementId: 'f5eaffa2-88b3-483b-b9ca-5c56aaf36ebb',
            componentType: 'checkbox',
            name: 'checkbox_id_0',
            label: 'Wind park site weather conditions',
            buttonName: 'Checkboxes',
            optionsLabel: 'Checkbox',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: false,
              answerLabel: '',
              allowFileUpload: true,
              orientation: 'horizontal',
            },
            options: ['Sunny', 'Windy', 'Rainy', 'Snowy'],
            answer: [
              {
                answerValue: 'Sunny',
                answerDescription: 'Quite a hot day',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
                image: [
                  {
                    imageId: '',
                    answerId: 1,
                    imageOrder: 1,
                    imageName: 'first.png',
                    formdata: 3,
                    createdBy: 'dev',
                    createdDate: 1601391694714,
                    lastUpdatedBy: 'dev',
                    lastUpdatedDate: 1601391694714,
                    imageUrl:
                    'https://images.unsplash.com/photo-1604085572504-a392ddf0d86a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8b3JhbmdlJTIwZmxvd2VyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80',
                },
                {
                  imageId: '',
                  answerId: 1,
                  imageOrder: 2,
                  imageName: 'Second.png',
                  formdata: 3,
                  createdBy: 'dev',
                  createdDate: 1601391694714,
                  lastUpdatedBy: 'dev',
                  lastUpdatedDate: 1601391694714,
                  imageUrl:
                  'https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512__340.jpg',
              },
                ],
              },
              {
                answerValue: 'Rainy',
                answerDescription: 'Drizzling',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
                image: [
                  {
                    imageId: '',
                    answerId: 1,
                    imageOrder: 3,
                    imageName: 'Third.png',
                    formdata: 3,
                    createdBy: 'dev',
                    createdDate: 1601391694714,
                    lastUpdatedBy: 'dev',
                    lastUpdatedDate: 1601391694714,
                    imageUrl:
                    'https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510__480.jpg',
                },
                ],
              },
            ],
            validations: {
              required: true,
            },
            children: [],
            tenantId: 'ren',
            dynamicDropdownList: [],
          },
          {
            id: 3635,
            motherId: 3632,
            order: 2,
            elementId: 'ed55d30f-7743-49fa-898b-bf4c80d76a01',
            componentType: 'subsection',
            name: 'subsection_id_0',
            label: 'Site Details',
            buttonIcon: 'fa fa-window-maximize',
            buttonName: 'Subsection',
            optionsLabel: 'Subsection',
            enabledActions: [],
            enabledConditions: [],
            children: [
              {
                id: 7000,
                motherId: 3635,
                order: 1,
                elementId: 'e86b8222-006e-490a-8a30-28dfdfb90ce9',
                componentType: 'textarea',
                name: 'text_id_1',
                label: '<p>Site Location</p>',
                buttonName: 'Text',
                optionsLabel: 'Text',
                enabledActions: [],
                enabledConditions: [],
                answer: [
                  {
                    answerValue: 'Chennai',
                    answerDescription: '',
                    createdBy: 'dev',
                    createdDate: 1601391694714,
                    lastUpdatedBy: 'dev',
                    lastUpdatedDate: 1601391694714,
                    image:[{
                      imageId: '',
                      answerId: 1,
                      imageOrder: 2,
                      imageName: 'Second.png',
                      formdata: 3,
                      createdBy: 'dev',
                      createdDate: 1601391694714,
                      lastUpdatedBy: 'dev',
                      lastUpdatedDate: 1601391694714,
                      imageUrl:
                      'https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512__340.jpg',
                  }]
                  },
                ],
                properties: {
                  visible: true,
                  disabled: true,
                  answerLabel: '',
                  hasMaxLength: false,
                  allowFileUpload: true,
                },
                validations: {
                  email: false,
                  required: true,
                  maxLength: 0,
                  numberOnly: false,
                  phoneNumber: false,
                },
                children: [],
                tenantId: 'ren',
              },
            ],
            tenantId: 'ren',
          },
        ],
        tenantId: 'ren',
      },
      {
        id: 3637,
        order: 2,
        elementId: 'e25ee440-be6e-485e-a935-c2fa2a114902',
        componentType: 'section',
        name: 'section_id_1',
        label: 'Pre Work Turbine Configuration ',
        buttonIcon: 'fa fa-window-maximize',
        buttonName: 'Section',
        optionsLabel: 'Section',
        enabledActions: [],
        enabledConditions: [],
        children: [
          {
            id: 9255,
            motherId: 9254,
            order: 1,
            elementId: '3d4e7384-32c3-41cc-8fba-369eacc4d349',
            componentType: 'dependentDropdown',
            name: 'dependentDropdown_id_1',
            label: 'Dependent Dropdown Label',
            buttonName: 'Dependent Dropdown',
            optionsLabel: 'Dependent Dropdown',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: false,
              answerLabel: '',
              allowFileUpload: true,
              conditional: false,
              multiple: false,
            },
            options: ['Option Label', 'Option Label 1'],
            validations: {
              required: false,
            },
            children: [],
            tenantId: 'ren',
            dynamicDropdownList: [
              {
                id: 1,
                motherId: null,
                name: 'region List',
                tableName: 'region',
                tableColumn: 'region_name',
                filterColumn: 'id',
                mappingTableColumn: null,
                mappingTableName: null,
              },
              {
                id: 2,
                motherId: 1,
                name: 'siteList',
                tableName: 'site',
                tableColumn: 'site_name',
                filterColumn: 'id',
                mappingTableColumn: 'site_id',
                mappingTableName: 'site_region',
              },
              {
                id: 3,
                motherId: 2,
                name: 'Turbine List',
                tableName: 'turbine',
                tableColumn: 'pad_no',
                filterColumn: 'id',
                mappingTableColumn: 'site_id',
                mappingTableName: 'site_turbine',
              },
            ],
            hashCode: -1203781965,
          },
          {
            id: 3638,
            motherId: 3637,
            order: 1,
            elementId: '42c126ba-dd63-493c-88a7-8dadaa6c3170',
            componentType: 'select',
            name: 'select_id_1',
            label: 'Select Box Label',
            dataType: 'string',
            buttonName: 'Select Box',
            optionsLabel: 'Select Box',
            enabledActions: [],
            enabledConditions: [],
            businessUnit: 'ren',
            options: [
              'Option Label 2',
              'Option Label 1',
              'Option Label',
              'Option Label 3',
              'Option Label 4',
              'Option Label 5',
              'Option Label 6',
              'Option Label 7',
              'Option Label 8',
              'Option Label 9',
              'Option Label 10',
            ],
            validations: { required: true },
            conditional: [
              {
                index: 0,
                value: '',
                actions: [
                  {
                    label: 'Capture Image',
                    name: 'capture_image',
                    icon: 'fas fa-camera',
                  },
                  {
                    label: 'Add Comments',
                    name: 'add_comments',
                    icon: 'fas fa-comment',
                  },
                ],
              },
              {
                index: 1,
                value: '',
                actions: [
                  {
                    label: 'Capture Image',
                    name: 'capture_image',
                    icon: 'fas fa-camera',
                  },
                  {
                    label: 'Add Comments',
                    name: 'add_comments',
                    icon: 'fas fa-comment',
                  },
                ],
              },
            ],
            properties: {
              visible: true,
              disabled: false,
              answerLabel: '',
              allowFileUpload: true,
              conditional: true,
              multiple: false,
              hasOptionalEquipmentValue: 'AKHFENNIR001',
            },
            children: [],
            tenantId: 'ren',
          },
          {
            id: 36155,
            motherId: 3637,
            order: 1,
            elementId: '42c126ba-dd63-493c-88a7-8dadaa6c3171',
            componentType: 'range',
            name: 'range_id_1',
            label: 'Range Label',
            dataType: 'string',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            businessUnit: 'ren',
            validations: { required: true },
            properties: {
              visible: true,
              disabled: false,
              answerLabel: '',
              allowFileUpload: false,
              minRange: '0',
              maxRange: '50',
              rangeMultiplier: '1',
              minRangeLabel: 'Minimum Range Label',
              maxRangeLabel: 'Maximum Range Label',
              defaultValue: '3',
            },
            answer: [
              {
                answerValue: '35.06',
                answerDescription: '4',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
                image:[{
                  imageId: '',
                  answerId: 1,
                  imageOrder: 2,
                  imageName: 'Second.png',
                  formdata: 3,
                  createdBy: 'dev',
                  createdDate: 1601391694714,
                  lastUpdatedBy: 'dev',
                  lastUpdatedDate: 1601391694714,
                  imageUrl:
                  'https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512__340.jpg',
              }]
              },
            ],
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3639,
            motherId: 3637,
            order: 1,
            elementId: '42c126ba-dd63-493c-88a7-8dadaa6c3172',
            componentType: 'barcode',
            name: 'barcode_id_0',
            label: 'Barcode Label',
            dataType: 'string',
            buttonName: 'Barcode',
            optionsLabel: 'Barcode',
            enabledActions: [],
            enabledConditions: [],
            children: [],
            businessUnit: 'ren',
            validations: { required: true },
            answer: [
              {
                answerDescription: 'BarCode',
                answerValue: '50235256',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
                image: [
                {
                  imageId: '',
                  answerId: 1,
                  imageOrder: 2,
                  imageName: 'Second.png',
                  formdata: 3,
                  createdBy: 'dev',
                  createdDate: 1601391694714,
                  lastUpdatedBy: 'dev',
                  lastUpdatedDate: 1601391694714,
                  imageUrl:
                  'https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512__340.jpg',
              },
                ],
              },
            ],
            properties: {
              visible: true,
              disabled: false,
              answerLabel: '',
              allowFileUpload: true,
              barcodeType: '',
            },
            tenantId: 'ren',
          },
          {
            id: 7986,
            motherId: 7981,
            order: 4,
            elementId: '66b1399a-058c-4bea-9dc7-70c100acca34',
            componentType: 'signature',
            name: 'signature_id_0',
            label: 'Lead Technician Signature',
            buttonName: 'Signature',
            optionsLabel: 'Signature',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: false,
              answerLabel: '',
              allowDuplicate: false,
              allowFileUpload: true,
            },
            answer: [
              {
                answerValue: 'https://upload.wikimedia.org/wikipedia/commons/2/28/Anne-signature.png',
                answerDescription: 'Anne',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
                image:[{
                  imageId: '',
                  answerId: 1,
                  imageOrder: 2,
                  imageName: 'Second.png',
                  formdata: 3,
                  createdBy: 'dev',
                  createdDate: 1601391694714,
                  lastUpdatedBy: 'dev',
                  lastUpdatedDate: 1601391694714,
                  imageUrl:
                  'https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512__340.jpg',
              }]
              },
            ],
            validations: {
              required: true,
            },
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3640,
            motherId: 3637,
            order: 2,
            elementId: '600fab66-f5c0-4b60-820d-a038d951af9c',
            componentType: 'radio',
            name: 'radio_id_0',
            label:
              '<p>Sequence and torque value in accordance with most current bolt torque WDI/TWP</p>',
            buttonName: 'Radio Button',
            optionsLabel: 'Radio Button',
            enabledActions: [],
            enabledConditions: [],
            conditional: [
              {
                index: 0,
                value: '',
                actions: [
                  {
                    label: 'Capture Image',
                    name: 'capture_image',
                    icon: 'fas fa-camera',
                  },
                  {
                    label: 'Add Comments',
                    name: 'add_comments',
                    icon: 'fas fa-comment',
                  },
                ],
              },
              {
                index: 1,
                value: '',
                actions: [
                  {
                    label: 'Capture Image',
                    name: 'capture_image',
                    icon: 'fas fa-camera',
                  },
                  {
                    label: 'Add Comments',
                    name: 'add_comments',
                    icon: 'fas fa-comment',
                  },
                ],
              },
            ],
            properties: {
              visible: true,
              disabled: true,
              answerLabel: '',
              conditional: true,
              allowFileUpload: true,
              hasOptionalEquipmentValue: 'AKHFENNIR001',
            },
            options: ['Complete', 'Not Complete'],
            answer: [
              {
                answerValue: 'Not Complete',
                answerDescription: 'Radiooo',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
                image:[{
                  imageId: '',
                  answerId: 1,
                  imageOrder: 2,
                  imageName: 'Second.png',
                  formdata: 3,
                  createdBy: 'dev',
                  createdDate: 1601391694714,
                  lastUpdatedBy: 'dev',
                  lastUpdatedDate: 1601391694714,
                  imageUrl:
                  'https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512__340.jpg',
              }]
              },
            ],
            validations: {
              required: true,
            },
            children: [],
            tenantId: 'ren',
            dynamicDropdownList: [],
          },
          {
            id: 8000,
            motherId: 7996,
            order: 3,
            elementId: 'b4f24cc4-bfed-4164-8167-22deb81894c9',
            componentType: 'help',
            name: 'imageInput_id_0',
            label: '<p><em><strong></strong></em>Instructions/Help Label</p>',
            buttonName: 'Image Upload',
            optionsLabel: 'Image Upload',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: false,
              hasHelpImage: true,
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
            validations: {
              required: true,
            },
            children: [],
            tenantId: 'ren',
          },
          {
            id: 8001,
            motherId: 7996,
            order: 3,
            elementId: 'b4f24cc4-bfed-4164-8167-22deb81894c9',
            componentType: 'fileInput',
            name: 'imageInput_id_0',
            label: 'Image Upload',
            buttonName: 'Image Upload',
            optionsLabel: 'Image Upload',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: false,
              hasHelpImage: true,
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
            validations: {
              required: true,
            },
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3641,
            motherId: 3637,
            order: 3,
            elementId: '79dd7f89-a8c6-486b-84d0-c5bbba53f081',
            componentType: 'text',
            name: 'text_id_3',
            label: '<p>Color Pen</p>',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: true,
              answerLabel: '',
              hasMaxLength: false,
              allowFileUpload: false,
            },
            validations: {
              email: false,
              required: true,
              maxLength: 0,
              numberOnly: false,
              phoneNumber: false,
            },
            answer: [
              {
                answerValue: 'Blue color pen',
                answerDescription: 'Text',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
                image:[{
                  imageId: '',
                  answerId: 1,
                  imageOrder: 2,
                  imageName: 'Second.png',
                  formdata: 3,
                  createdBy: 'dev',
                  createdDate: 1601391694714,
                  lastUpdatedBy: 'dev',
                  lastUpdatedDate: 1601391694714,
                  imageUrl:
                  'https://cdn.pixabay.com/photo/2015/04/19/08/33/flower-729512__340.jpg',
              }]
              },
            ],
            children: [],
            tenantId: 'ren',
          },
        ],
        tenantId: 'ren',
      },
      {
        id: 3642,
        order: 3,
        elementId: '7a5541f9-e9ef-4538-93f1-0eff7db0d180',
        componentType: 'section',
        name: 'section_id_2',
        label: 'Tool and Manufacture 1',
        buttonIcon: 'fa fa-window-maximize',
        buttonName: 'Section',
        optionsLabel: 'Section',
        enabledActions: [],
        enabledConditions: [],
        children: [
          {
            id: 3643,
            motherId: 3642,
            order: 1,
            elementId: '5d31faa2-d930-46ba-92a6-08d184e2f019',
            componentType: 'text',
            name: 'text_id_4',
            label: 'Text Field Label',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: false,
              answerLabel: '',
              hasMaxLength: false,
              allowFileUpload: false,
            },
            validations: {
              email: false,
              required: true,
              maxLength: 0,
              numberOnly: false,
              phoneNumber: false,
            },
            answer: [
              {
                answerValue: 'some text need to be written',
                answerDescription: 'Text',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
              },
            ],
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3644,
            motherId: 3642,
            order: 2,
            elementId: 'ac3d5f78-7009-46ca-ba81-1c8ed5aad116',
            componentType: 'text',
            name: 'text_id_5',
            label: '<p>Tool Name</p>',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: true,
              answerLabel: '',
              hasMaxLength: false,
              allowFileUpload: false,
            },
            validations: {
              email: false,
              required: true,
              maxLength: 0,
              numberOnly: false,
              phoneNumber: false,
            },
            answer: [
              {
                answerValue: 'Hardware Box',
                answerDescription: 'Text',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
              },
            ],
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3645,
            motherId: 3642,
            order: 3,
            elementId: 'f75e66a3-cd32-4b7f-b545-a8751a629149',
            componentType: 'text',
            name: 'text_id_6',
            label: '<p>Serial No</p>',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: true,
              answerLabel: '',
              hasMaxLength: false,
              allowFileUpload: false,
            },
            validations: {
              email: false,
              required: true,
              maxLength: 0,
              numberOnly: false,
              phoneNumber: false,
            },
            answer: [
              {
                answerValue: '214782356',
                answerDescription: 'number',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
              },
            ],
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3646,
            motherId: 3642,
            order: 4,
            elementId: '4dbb1e93-4278-48e3-8e42-45c0e0fab177',
            componentType: 'text',
            name: 'text_id_7',
            label: '<p>Section</p>',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: false,
              answerLabel: '',
              hasMaxLength: false,
              allowFileUpload: false,
            },
            validations: {
              email: false,
              required: true,
              maxLength: 0,
              numberOnly: false,
              phoneNumber: false,
            },
             answer: [
              {
                answerValue: 'Review section',
                answerDescription: 'number',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
              },
            ],
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3647,
            motherId: 3642,
            order: 5,
            elementId: '6b2d1778-1068-4135-8d5f-e58e09c0d45a',
            componentType: 'date',
            name: 'date_id_1',
            label: '<p>Col Date</p>',
            buttonName: 'Date',
            optionsLabel: '',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: true,
              answerLabel: '',
              preFilledDate: false,
              hasHoursWorked: false,
              allowFileUpload: false,
              hoursWorkedValue: 0,
            },
            validations: {
              required: true,
              dateFormat: 'MM/DD/YY',
            },
            answer: [
              {
                answerValue: '28/10/2021 08:41',
                answerDescription: '5',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
              },
            ],
            children: [],
            tenantId: 'ren',
          },
        ],
        tenantId: 'ren',
      },
      {
        id: 3648,
        order: 4,
        elementId: 'e6fb038a-bea7-49a6-9930-61ab148fb2d6',
        componentType: 'section',
        name: 'section_id_3',
        label: 'General Info',
        buttonIcon: 'fa fa-window-maximize',
        buttonName: 'Section',
        optionsLabel: 'Section',
        enabledActions: [],
        enabledConditions: [],
        children: [
          {
            id: 3649,
            motherId: 3648,
            order: 1,
            elementId: '77935726-42c6-4942-8684-5720365b9b5d',
            componentType: 'text',
            name: 'text_id_8',
            label: '<p>Project</p>',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: true,
              answerLabel: '',
              hasMaxLength: false,
              allowFileUpload: false,
            },
            validations: {
              email: true,
              required: true,
              maxLength: 10,
              numberOnly: false,
              phoneNumber: false,
            },
           answer: [
              {
                answerValue: 'GE Project',
                answerDescription: '4',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
              },
            ],
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3650,
            motherId: 3648,
            order: 2,
            elementId: 'e9f32f21-0996-494f-a9d7-2f5cb012263f',
            componentType: 'text',
            name: 'text_id_9',
            label: '<p>BOI #</p>',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: true,
              answerLabel: '',
              hasMaxLength: false,
              allowFileUpload: false,
            },
            validations: {
              email: false,
              required: true,
              maxLength: 0,
              numberOnly: false,
              phoneNumber: false,
            },
            answer: [
              {
                answerValue: '123145474',
                answerDescription: '4',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
              },
            ],
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3651,
            motherId: 3648,
            order: 3,
            elementId: '84c92c7e-d4b8-46ce-83ce-eb3b3d23f055',
            componentType: 'text',
            name: 'text_id_10',
            label: '<p>GE ID Tag#</p>',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: true,
              answerLabel: '',
              hasMaxLength: false,
              allowFileUpload: false,
            },
            validations: {
              email: false,
              required: true,
              maxLength: 0,
              numberOnly: false,
              phoneNumber: false,
            },
            answer: [
              {
                answerValue: '5218789870',
                answerDescription: '4',
                createdBy: 'dev',
                createdDate: 1601391694714,
                lastUpdatedBy: 'dev',
                lastUpdatedDate: 1601391694714,
              },
            ],
            children: [],
            tenantId: 'ren',
          },
          {
            id: 3652,
            motherId: 3648,
            order: 4,
            elementId: 'fd0824ac-ccb7-43f1-a794-4c9f07877aa8',
            componentType: 'range',
            name: 'range_id_0',
            label: '<p>Turbine Range&nbsp;&nbsp;&nbsp;&nbsp;</p>',
            buttonName: 'Text',
            optionsLabel: 'Text',
            enabledActions: [],
            enabledConditions: [],
            properties: {
              visible: true,
              disabled: true,
              maxRange: '10',
              minRange: '1',
              answerLabel: '',
              defaultValue: '',
              maxRangeLabel: '',
              minRangeLabel: '',
              allowFileUpload: false,
              rangeMultiplier: '1',
            },
            validations: {
              required: true,
            },
            children: [],
            tenantId: 'ren',
          },
        ],
        tenantId: 'ren',
      },
    ],
    requests: [],
    responses: null,
    enforceOrder: null,
    copiedFromFormCode: null,
    copiedFromFormVersion: null,
    customFormCode: null,
    isInLine: false,
    formSupportedLanguages: [],
    defaultLanguageCode: 'en',
    formRegion: [],
    groupIds: [],
    translationFromCode: null,
    translationToCode: null,
    formTranslations: null,
    formLanguageCode: 'en',
    componentType: 'form',
    hashCode: 1800992663,
  }