import React from 'react';
import '../../components/App.scss';
import { withStyles } from '@material-ui/core/styles';
import AdvancedSearch from '../../review-portal/components/AdvancedSearch';
import MyForms from './MyForms';


const useStyles = (theme) => ({
  advanceSearch: {
    backgroundImage: 'linear-gradient(#202837 45%, #F6F5F6 0%)',   
    overflow: 'hidden',
  },
  myForms: {
    backgroundColor: '#F6F5F6',
    overflow: 'hidden',
  }
});

class LandingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isSearchButtonClicked: false,
      rowsPerPageSize : 20
    };
  }

  callBackFunction = (value) => {
    this.setState({
      isSearchButtonClicked: value
    });
  }

  pageSizeCallBack = (value) => {
    this.setState({
      rowsPerPageSize: value
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.advanceSearch}>
          <AdvancedSearch parentCallBack={this.callBackFunction} rowsPerPageSize={this.state.rowsPerPageSize}/>
        </div>
        <div className={classes.myForms}>
          <MyForms isSearchButtonClicked={this.state.isSearchButtonClicked} pageSizeCallBack={this.pageSizeCallBack}/>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(LandingPage);
