import React , { Component  } from 'react';
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import "../components/Navigation.scss";
import Titlebar from '../shared/component/Titlebar';
import UserGroupRestriction from '../components/UserGroupRestriction';
import SiteandRegion from '../components/SiteandRegion';
import CreateForm from './CreateForm';
import FormBuilderService from '../services/form-builder.service';

class FormTabs extends Component {
    constructor() {
        super();
        this.state = {
            formTabName: "new-form",
        }
        this.formBuilderService = new FormBuilderService();
        this.newRef=React.createRef();  
    }
    
    handleSelect = (event) => {
        this.setState({
            formTabName : event
        })
    }

    render() {
        return (
                <Tabs defaultTab={this.state.formTabName} horizontal="true" style={{ background: '#fcfcfc' }} onChange={($event) => this.handleSelect($event)}>
                    <TabList>
                        <Titlebar title={this.props.copyForm ? 'Copy Form' : this.props.updateForm?"Edit Form Meta Data":"Create New Form"} />
                        <Tab tabFor="new-form">FORM DETAIL</Tab>
                        <Tab tabFor="user-group">USER GROUP</Tab>
                        <Tab tabFor="site-region">SITE &amp; REGION</Tab>
                    </TabList>
        
                    <TabPanel tabId="new-form" style={{ width: '100%' }}>
                        <CreateForm  {...this.props} ref={this.newRef} />
                    </TabPanel>
        
                    <TabPanel tabId="user-group" style={{ width: '100%' }}>
                        <UserGroupRestriction  {...this.props} validate={this.newRef.current} />
                    </TabPanel>
        
                    <TabPanel tabId="site-region" style={{ width: '100%' }}>
                        <SiteandRegion  {...this.props} validate={this.newRef.current} />
                    </TabPanel>
                </Tabs>
            );
    }
}

export default FormTabs;
