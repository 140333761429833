import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Titlebar from '../shared/component/Titlebar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ViewModuleOutlinedIcon from '@material-ui/icons/ViewModuleOutlined';
import FormListView from "../components/FormListView";
import FormGridView from "../components/FormGridView";
import { formListService } from '../services/form-list.service';
import { Loader } from '../components/Loader';
import SearchByFormName from '../components/SearchByFormName';
import Sort from '../components/Sort';
import Pagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';
import {EXISTING_FORMS} from '../services/form.helper.service';
import { FormStore } from '../services/form.store';

export var currentPage = 1;

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    minHeight: '600px'
  },
  gridCard: {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      border: 'none',
    }
  },
  new: {
    fontSize: '.6rem',
    padding: '1px 3px',
    lineHeight: '12px',
    marginLeft: '8px',
    minWidth: 'auto',
    marginTop: '-3px',
    border: '1px solid #C935AD',
    color: '#C935AD',
    verticalAlign: 'middle',
    display: 'inline-flex',
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: '1px',
  },
  actionBar: {
    backgroundColor: '#FCFCFC',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  flexGrow: {
    flexGrow: 1,
  },
  pagination: {
    height: "28px",
    marginTop: "20px",
    marginBottom: "12px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "20%"
  },
  selected: {
    color: '#242D3E',
    backgroundColor: "transparent !important",
    border: "1px solid rgba(36,45,62,0.25)"
  }

});

class ExistingForms extends Component {

  constructor() {
    super();
    this.sortParam = { label: "", value: "", order: "" };
    this.totalPages = 0;
    this.state = {
      title: "All Forms",
      showListView: true,
      items: [],
      loading: true,
      currentPage: currentPage,
      form: 'all',
    }
  }

  componentDidMount() {
    this.formListSubscription = formListService.didChangeFormList().subscribe((response) => {
      this.onLoadAllForms(response);
    });
    this.loaderSubscription = formListService.didChangeLoadingState().subscribe(isLoading => {
      this.setState({ loading: isLoading })
    });
    this.sortSubscription = formListService.didChangeSortParameter().subscribe(response => {
      this.sortParam = response;
    });
    formListService.loadAllForms();
  }

  componentWillUnmount() {
    this.formListSubscription.unsubscribe();
    this.loaderSubscription.unsubscribe();
    this.sortSubscription.unsubscribe();
    currentPage = 1;
  }

  onLoadAllForms([formList, totalPages, totalItems, currentPageIndex]) {
    this.totalPages = totalPages;

    this.setState({ items: formList });
    this.setState({ currentPage: currentPageIndex + 1 }, () => {
      currentPage = this.state.currentPage;
      this.setState({ title: "All Forms (" + ((EXISTING_FORMS.SIZE * (this.state.currentPage - 1)) + formList.length) + ' of ' + totalItems + ")" });
    });
  }


  onClick = (event) => {
    const showListView = this.state.showListView;
    this.setState({ showListView: !showListView });
  }

  handleFormChange = (e, form) => {
    form && this.setState({ form: form });
    currentPage = 1;
    FormStore.sharedInstance().getformType(form);
    const userSSO = localStorage.getItem('ge_sso') && form === "my"? localStorage.getItem('ge_sso') : '';
    formListService.formChange(userSSO);
  };

  sortChanged(sortParameter) {
    formListService.sortForms(sortParameter);
  }

  onPageClicked(page) {
    currentPage = page;
    formListService.pageChanged();
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Titlebar title={this.state.title}>
          <ToggleButtonGroup size="small" value={this.state.form} exclusive onChange={this.handleFormChange} aria-label="text alignment">
            <ToggleButton value="all" aria-label="all forms"> All Forms </ToggleButton>
            <ToggleButton value="my" aria-label="my forms"> My Forms </ToggleButton>
          </ToggleButtonGroup>
        </Titlebar>

        <Typography component="div" className={classes.root} >
          <Paper>
            <div className={classes.actionBar}>
              <Toolbar>
                <div className={classes.flexGrow} />
                <SearchByFormName />
                <Sort sortParam = {this.sortParam} onMenuClicked={(sortParameter) => this.sortChanged(sortParameter)}/>
                <IconButton edge="end" color="inherit" onClick={this.onClick}>
                  {!this.state.showListView ? <ViewListOutlinedIcon /> : <ViewModuleOutlinedIcon />}
                </IconButton>
              </Toolbar>
            </div>
            {this.state.showListView ? <FormListView items={this.state.items} sortParam = {this.sortParam} onHeaderClick={(sortParameter) => this.sortChanged(sortParameter)}/> : null}
            {!this.state.showListView ? <FormGridView items={this.state.items} /> : null}
            <div style={{ textAlign: 'center' }}>
              <Loader loading={this.state.loading} />
            </div>
          </Paper>
          <Pagination className={classes.pagination} count={this.totalPages} page={this.state.currentPage} onChange={(event, page) => { this.onPageClicked(page) }}
            renderItem={(item) => (
              <PaginationItem
                classes={{ selected: classes.selected }}
                {...item}
              />
            )}
          />
        </Typography>
      </Fragment>
    )
  }

}
export default withStyles(styles)(ExistingForms);
