import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FormElementsList from './FormElementsList';
import { componentIconMap } from './form-element/CompMap';
import { FormStore } from '../services/form.store';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 260,
  },
  elementBtn: {
    width: '100%',
    height: 56,
    borderRadius: 4,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  categoryNav: {
    '& > .MuiMenu-paper': {
      right: 50,
      maxWidth: 260,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  listHeader: {
    marginTop: theme.spacing(1),
    lineHeight: '16px',
    letterSpacing: 1,
  },
  icon: {
    marginRight: theme.spacing(1),
    minWidth: 32,
  },
  sideNavDivider: {
    marginLeft: 56,
  },
  selected: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(65, 155, 172, .05)',
      color: `${theme.palette.primary.dark}`,
      '& > .MuiListItemIcon-root': {
        color: `${theme.palette.primary.main}`,
      },
      '&:hover': {
        backgroundColor: 'rgba(65, 155, 172, .05)',
        cursor: 'default',
      },
    },
  }
});

class FormElementTypeSelector extends Component {

  constructor(props) {
    super();
    this.state = {
      anchorEl: null
    }
  }

  handleClickListItem = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onItemClick = (element) => {
    this.handleClose();
    const prevElement = this.props.element;
    const newType = element.component;
    if (prevElement && newType) {
      const id = prevElement.elementId;
      FormStore.sharedInstance().changeElement(id, newType);
      return;
    }

    console.log("Couldn't change type - Either state element empty or new type");
  }

  render() {
    const { classes } = this.props;
    const metaMap = componentIconMap;
    const typeMeta = metaMap[this.props.type];
    return (
      <div className={classes.root}>
        <ListItem className={classes.elementBtn} button onClick={this.handleClickListItem} >
          <ListItemIcon className={classes.icon}>
            <MaterialIcon icon={typeMeta.icon} />
          </ListItemIcon>
          <ListItemText primary={typeMeta.name} />
        </ListItem>
        <Menu keepMounted id="lock-menu"
              className={classes.categoryNav}
              anchorEl={this.state.anchorEl}
              open={ Boolean(this.state.anchorEl)}
              onClose={ this.handleClose }>
        <FormElementsList id="formElementList" onItemClick={this.onItemClick} />
      </Menu>
      </div>
    )
  }
}

const MaterialIcon = ({ icon }) => {
  return React.createElement(icon);
}

export default withStyles(styles)(FormElementTypeSelector);
