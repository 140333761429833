import axios from 'axios';
import { BASEURLDETAILS } from '../../review-portal/utilities/Constants';

export const DependentDropdownService = async (params) => {
  let accessToken = localStorage.getItem('access_token') ? 
	localStorage.getItem('access_token') : '';
  let config = {};
  if(accessToken && accessToken.length > 0) 
	config = {
	  headers:{
	    'Accept': 'application/json',
		'Authorization': 'Bearer ' + accessToken + '', 'BusinessUnit': 'ren',
	    'Content-Type': 'application/json', 'tenantId': 'ren'
	  }
	};
  const parentObject = BASEURLDETAILS.DEPENDENT_DROPDOWN_URL + '?tableName=' +
    params.tableName + '&filterColumn=' + params.filterColumn + '&tableColumn=' + 
    params.tableColumn;
  const childObject = parentObject + '&dropdownType=child&mappingTableColumn=' + 
  	params.mappingTableColumn + '&mappingTableName=' + params.mappingTableName + 
  	'&parentSelectedId=' + params.parentSelectedId;
  /*eslint-disable-next-line no-useless-concat*/
  const requestOjbect = params.motherId === null ? 
	parentObject + '&dropdownType=parent' : childObject;
  return axios.get(requestOjbect, config).then((result) => {
      return new Promise((resolve, reject) => {
        let data = [{ name: 'Select Option', id: '' }, ...result.data];
        resolve(data);
      });
    })
    .catch((error) => {
      console.error(error);
    });
};