import React, { Component, Fragment } from 'react';
import Components from './CompMap';
import { Box, Grid, Divider, IconButton, Typography } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { withStyles } from '@material-ui/styles';
import FormPopup from './FormPopup';

const styles = theme => ({
  drawerPaper: {
    width: 300,
    height:587,
    padding: '1%',
    marginTop:"5%"
  },
  drawerTitle: {
    padding: '4px 12px',
    paddingBottom:'15px'
  },
  iconBtn: {
    borderRadius: 1,
    border: '1px solid grey',
    padding:'2%'
  },
  arrowIcon:{
    fontSize: '1rem',
  },
  sectionList:{
    marginTop:"1%"
  },
  toggleList:{
    margin:"0.5%"
  }
})

class FormEdit extends Component {

  render() {
    const { classes } = this.props;
    return (<Fragment>
      <Box p={3}>
        <Grid container spacing={3} >
          <Grid item md={this.props.showTOC ? 8 : 12} xs={this.props.showTOC ? 8 : 12} >
            <RenderInternal id="renderInternal" form={this.props.form} />
          </Grid>
          {this.props.showTOC ?
            <Grid item md={4} xs={4} className={classes.sectionList} >
              <RenderListInternal id="renderListInternal" form={this.props.form} />
            </Grid>
            : null}
        </Grid>
      </Box>
      <Drawer anchor={'right'} open={this.props.showSlide} onClose={this.props.toggleDrawer(false)}
        onOpen={this.props.toggleDrawer(true)} classes={{ paper: classes.drawerPaper }}>
        <Box display="flex" alignItems="center" className={classes.drawerTitle} >
          <Grid container spacing={3} >
            <Grid item md={10} xs={10} >
              <Typography>{this.props.form.title}
              </Typography>
            </Grid>
            <Grid item md={2} xs={2} >
              <IconButton edge="end" variant="outlined" className={classes.iconBtn} onClick={this.props.closeDrawer} color="secondary">
                <ArrowForwardIosOutlinedIcon className={classes.arrowIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={2}>
          <RenderListInternal form={this.props.form} className={classes.toggleList} />
        </Box>
      </Drawer>
      {(this.props.showPopup) ? <FormPopup
        id="ringtone-menu"
        keepMounted
        form={this.props.form}
        translatedForm ={this.props.translatedForm}
        open={this.props.showPopup}
        defaultTabName={this.props.defaultTabName}
        selectedLanguage={this.props.selectedLanguage}
        onClose={this.props.handleClose}
      /> : null}
    </Fragment>
    );
  }
}

function RenderInternal(props) {
  if (props.form !== undefined) {
    const TagName = Components['section'];
    const items = props.form.children.map((item, key) =>
      <TagName section={item} />
    );
    return items;
  }
  return (<div> Empty Form </div>);
}

function RenderListInternal(props) {
  if (props.form !== undefined) {
    const TagName = Components['sectionlist'];
    const items = props.form.children.map((item, key) =>
      <TagName sectionlist={item} />
    );
    return items;
  }
  return (<div> Empty Form </div>);
}

export default withStyles(styles)(FormEdit);