import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Header from '.././components/Header';



const useStyles = makeStyles( theme => ({
  root: {
    paddingTop: 64,
    height: '100%',
  },
  content: {
    minHeight: `calc(100% - 64px)`,
  },
}));


const Minimal = props => {
  const{children} = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header userRole={props.userRole}/>
      <main className={classes.content}>
        {children}
      </main>
    </div>
  )
}

export default Minimal;
