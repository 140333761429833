import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/styles';
import { Dialog, Button, DialogActions, DialogContent, Typography, IconButton, Box, Grid, TextField, Chip, Checkbox } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormBuilderService from '../../services/form-builder.service';
import { ValidationHelperService } from '../../services/validation-helper.service';
import { Alert, Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';

let formBuilderService = new FormBuilderService();

function EditUserGroup(props) {
    const [isLoading ,setIsLoading] = useState(false)
    const [groupName ,setGroupName] = useState("")
    const [selectedForms ,setSelectedForms] = useState([])
    const [selectedUsers ,setSelectedUsers] = useState([])
    const [error ,setError] = useState("")
    const [hasError ,setHasError] = useState(false)

    const useStyles = makeStyles((theme) => ({
        dialogPaper: {
            position: 'absolute',
            top: 34,
            minHeight: '50%',
            minWidth: '60%',
        },
    }));
    
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const classes = useStyles();

    const styles = (theme) => ({
        root: { margin: 0 },
        closeButton: {
            position: 'absolute', right: theme.spacing(4),
            top: theme.spacing(2), color: theme.palette.grey[500]
        }
    });

    const DialogTitle = withStyles(styles)((propsDT) => {
        const { children, classes, onClose, ...other } = propsDT;
        return (
            <MuiDialogTitle id="muiDialog" disableTypography 
            	className={classes.root} {...other}>
                <Typography variant="h5">{children}</Typography>
                {onClose ? (
                    <IconButton id="iconButton" aria-label="close" 
                    	className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });
    
    useEffect(()=>{
        setIsLoading(true)
        formBuilderService.getUserGroup(props.id).then(res => {
            setGroupName(res.data.groupName)
            setSelectedForms(res.data.forms)
            setSelectedUsers(res.data.users)
        })
        setIsLoading(false)
    },[props.id])

    const handleCancel = ()=>{
        props.handleClose();
    }

    const clearData = () =>{
        setError("")
        setGroupName("")
        setHasError(false)
        setSelectedForms([])
        setSelectedUsers([])
    }

    const alphanumericValidator = (value) => {
        return  ValidationHelperService.alphanumericValidator(value);
    }

    const getHelperTextForGroupName = (value) =>{
        let helperText = "";
        if (hasError) {
            if (!value) {
                helperText = "Group Name is required!";
            }
            else if (!alphanumericValidator(value)) {
                helperText = "Group Name should be alphanumeric.";
            }
        }
        return helperText;
    }

    const getHelperTextForUsers = () => {
        let helperText = "";
        if(hasError && !selectedUsers.length){
            helperText = "Users are required"
        }
        return helperText;
    }

    const getHelperTextForForms = () =>{
        let helperText = "";
        if (hasError &&  !selectedForms.length) {
            helperText = "Forms is required!";
        }
        return helperText;
    }

    const onGroupNameChange = (e) =>{
        setGroupName(e.target.value)
    }

    const onUsersChange  = (event, values) => {
        setSelectedUsers(values)
    }

    const onFormsChange = (event, values) => {
        setSelectedForms(values)
    }

    const validateForm = () => {
        var valid = false;
        if (groupName && selectedForms.length && selectedUsers.length) 
        	valid = true;
        return valid;
    }

    const handleSave = () =>{
        const isValid = validateForm();
        if (isValid) {
            let formCodes = [];
            let userIds = [];
            selectedForms.forEach(form => formCodes.push({"formCode": form.formCode}));
            selectedUsers.forEach(user => userIds.push({"id": user.id}));
            formBuilderService.editUserGroup({"id": props.id,"groupName":groupName,"forms":formCodes,"users": userIds}).then(res => {
                if(res.response && res.response.data){
                    setError(res.response.data.message);
                    return;
                } else if(res.data) {
                    toast.success(groupName + " group updated successfuly!");
                } else {
                    toast.error(groupName + " group could not be updated due to errors!");
                }
                props.handleUpdate();
                return;
            })
        }
        setHasError(true);
    }

    return (
        <div>
            <Dialog open={props.open} onClose={props.handleCancel}
                classes={{ paper: classes.dialogPaper }} onEnter={clearData} >
                <DialogTitle onClose={handleCancel} >{"Edit Group"}</DialogTitle>
                <DialogContent>
                    {error ? <Alert id="alert" style={{ marginBottom:20}} severity="error"> {error}</Alert> : null}
                    <Box p={3}>
                        <Grid container spacing={3} >
                            <TextField fullWidth
                                label="Group Name" name="title" id="groupName"
                                value={groupName}
                                onChange={onGroupNameChange}
                                required 
                                variant="outlined" 
                                error={hasError && ((!groupName) || !alphanumericValidator(groupName))}
                                helperText={getHelperTextForGroupName(groupName)}
                            />
                        </Grid>
                    </Box>
                    <Box p={3}>
                        <Grid container spacing={3} >
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={props.usersList}
                                value={selectedUsers}
                                onChange={onUsersChange}
                                disableCloseOnSelect
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.name ? option.name : option.userFirstName}
                                renderTags={(value, getTagProps) => value.map((option, index) =>
                                    (<Chip color="secondary"
                                    id="chip"
                                    size="small"
                                    label={option.name ? option.name : option.userFirstName} {...getTagProps({ index })} />))}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                    <Checkbox
                                        id="checkboxIcon"
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name ? option.name : option.userFirstName}
                                    </React.Fragment>
                                )}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        id="userTextField" 
                                        variant="outlined" 
                                        label="Users" 
                                        placeholder="Select Users" 
                                        required
                                        error={hasError && !selectedUsers.length }
                                        helperText={getHelperTextForUsers()}
                                    />
                                )}
                            />
                        </Grid>
                    </Box>
                    <Box p={3}>
                        <Grid container spacing={3} >
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={props.formsList}
                                onChange={onFormsChange}
                                value={selectedForms}
                                disableCloseOnSelect
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.formName}
                                loading={isLoading}
                                renderTags={(value, getTagProps) => value.map((option, index) =>
                                    (<Chip color="secondary"
                                    size="small"
                                    id="chip"
                                    label={option.formName} {...getTagProps({ index })} />))}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox id="checkboxIcon" icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.formName}
                                    </React.Fragment>
                                )}
                                style={{ width:"100%" }}
                                renderInput={(params) => (
                                    <TextField {...params} id="formTextField"
                                        variant="outlined" label="Forms" 
                                        placeholder="Select Forms" required 
                                        error={hasError && !selectedForms.length }
                                        helperText={getHelperTextForForms()}
                                    />
                                )}
                            />
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions style={{padding: "8px 24px 14px 24px"}}>
                    <Button variant="contained" color="secondary" 
                    	onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default EditUserGroup;