import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
    const { layout: Layout, component: Component, ...rest } = props;
    const userHasRequiredRole = props.userRole && props.roles.includes(props.userRole) ? true : false;

    if (userHasRequiredRole) {
        return (
            <Route
                {...rest}
                render={matchProps => (
                    <Layout userRole={props.userRole}>
                        <Component {...matchProps} />
                    </Layout>
                )}
            />
        );
    }

    else {
        return <Redirect exact from={props.location.pathname} to="/accessDenied"/>
    }
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
