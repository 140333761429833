import { Avatar, Box, Card, Grid, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { extractSignature } from '../../store/form.answer.extract';
import { withStyles } from '@material-ui/styles';
import ImagePopup from './ImagePopup';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
    },
});

class Signature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signature: '',
            name: '',
            imageSignatureOpen:false,
            imageOpen: false,
            imageValue: [],
            imageUrl: '',
            imageName: '',
            isSignature: true
        };
    }

    componentDidMount() {
        this.onLoadPage(this.props);
        if (this.props.element.properties.allowFileUpload) {
            this.onLoadImage(this.props);
        }
    }

    onLoadPage(props) {
        if (props.element) {
            let answer = props.element.answer;
            let userResponse = extractSignature(answer);
            if (userResponse) {
                let signature = userResponse.signature;
                if (signature) {
                    this.setState({ signature: signature });
                }
                let name = userResponse.name;
                if (name) {
                    this.setState({ name: name });
                }
            }
        }
    }

    onLoadImage(props) {
        if(props.element){
            let image = props.element?.answer?.image;
            if(image){
                this.setState({ imageValue: image });
            }
        }
    }

    onClickAvatar = (image) => {
        this.setState({
            imageOpen: true,
            imageUrl: image.imageUrl,
            imageName: image.imageName
        });
    }

    onClickSignature = () =>{
        this.setState({
            imageSignatureOpen: true
        });
    }

    onCloseSignature = () => {
        this.setState({ imageSignatureOpen: false });
    }

    onClose = () => {
        this.setState({ imageOpen: false });
    }

    render() {
        const { classes } = this.props;
        let signature;
        if (this.state.signature !== null && this.state.signature.length > 0) {
            signature = (
                <Avatar alt={this.state.name} src={this.state.signature} variant="square" style={{ height: '75px', width: '100px' }}
                onClick={this.onClickSignature}/>
            );
        }
        return (<Fragment>
            <Typography className={classes.userResponseFont} >{signature}</Typography>
            <ImagePopup open={this.state.imageSignatureOpen} onClose={this.onCloseSignature} imageUrl={this.state.signature} title={this.state.name} isSignature={this.state.isSignature}></ImagePopup>
            {(this.props.element.properties.allowFileUpload && this.state.imageValue !== "") ? this.state.imageValue.map((image) => (
                <Card className={classes.root} >
                    <Box>
                        <Grid container spacing={1}>
                            {image ? <Avatar alt={image?.imageName} src={image?.imageUrl} variant="square" style={{ height: '75px', width: '100px' }}
                                onClick={image?.imageUrl!=="" ? () => this.onClickAvatar(image) : null} /> : ""}
                        </Grid>
                    </Box>
                </Card>
            )) : null}
            <ImagePopup open={this.state.imageOpen} onClose={this.onClose} imageUrl={this.state.imageUrl} title={this.state.imageName}></ImagePopup>
        </Fragment>);
    }
}

export default withStyles(styles)(Signature);