export default {
  contained: {
    boxShadow: 'none',
    backgroundColor: '#51C56C',
    borderRadius: '24px',
    marginLeft: '10px',
    color: 'white',
    padding: '6px 24px',
    fontSize: '.85rem',
    letterSpacing: 1,
    '&:hover': {
      backgroundColor: '#45AA5D',
    },
    '&:disabled': {
      backgroundColor: '#bdbdbd',   
    }
  },
  outlined: {
    borderColor: '#51C56C',
    borderRadius: '24px',
    color: 'white',
    padding: '6px 24px',
    fontSize: '.85rem',
    letterSpacing: 1,
  }
};

