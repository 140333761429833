import { extractTextAnswerValue } from '../store/form.answer.extract';

const textRequired = (answer, element) => {
    let value = extractTextAnswerValue(answer);
    if (value) {
        if (value.length > 0) {
            return true;
        }
    }
    return 'This field is required.';
};

export { textRequired };