import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

export default function FormListActions(props) {
  const useStyles = makeStyles((theme) => ({
    dialogPaper: {
      minWidth: '200px',
      backgroundColor: 'red'
    }
  }));

  const classes = useStyles();

  return (
    <div classes={{ paper: classes.dialogPaper }}>
      <Popper open={props.open} 
              onClose={props.handleActionCancel}
              anchorEl={props.anchorEl} 
              role={undefined} 
              transition 
              disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                <Paper>
                <ClickAwayListener onClickAway={props.handleActionCancel}>
                <MenuList id="form-copy-menu">
                  {props.selectedForm && props.selectedForm.formStatus && props.selectedForm.formStatus === 'Published' ? <MenuItem onClick={props.handleCopy}>Copy</MenuItem> : null}
                  {props.selectedForm && props.selectedForm.formStatus && props.selectedForm.formStatus === 'Draft' ? <MenuItem onClick={props.handleDelete}>Delete</MenuItem> : null}
                </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper> 
    </div>
  );
}
