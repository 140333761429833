
import palette from '../palette';

export default {
  grouped: {
    margin: 'auto',
    color: `${palette.secondary.main}`,
    borderRadius: 0,
    '& > *': {
      padding: '8px',
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: `${palette.primary.main}`,
      boxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.25)',
      '&:hover': {
        backgroundColor: `${palette.primary.main}`,
      },
    },
  },
};
