import React, { Component, Fragment } from 'react';
import { extractImageValue } from '../../store/form.answer.extract';
import { Avatar, Box, Card, Grid } from '@material-ui/core';
import ImagePopup from './ImagePopup';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: 0,
    },
});

class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urls:[],
            imageOpen: false,
            imageValue: [],
            imageUrl: '',
            imageName: ''
        };
    }
    componentDidMount() {
        this.onLoadImage(this.props);
    }

    onLoadImage(props) {
        if(props.element){
            let image = props.element?.answer?.image;
            if(image){
                this.setState({ imageValue: image });
            }
        }
    }

    onClickAvatar = (image) => {
        this.setState({
            imageOpen: true,
            imageUrl: image.imageUrl,
            imageName: image.imageName
        });
    }

    onClose = () => {
        this.setState({ imageOpen: false });
    }

    render() {
        const { classes } = this.props;
        return (<Fragment>
            {(this.state.imageValue.length) ? this.state.imageValue.map((image) => (
                <Card className={classes.root} >
                    <Box>
                        <Grid container spacing={1}>
                            <Avatar alt={image.imageName} src={image.imageUrl} variant="square" style={{ height: '75px', width: '100px' }}
                                onClick={() => this.onClickAvatar(image)} />                       
                         </Grid>
                    </Box>
                </Card>
            )) : null}
            <ImagePopup open={this.state.imageOpen} onClose={this.onClose} imageUrl={this.state.imageUrl} title={this.state.imageName}></ImagePopup>
        </Fragment>);
    }
}
export default withStyles(styles)(FileUploader);