import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

const styles = (theme) => ({
    container: {
       padding: 5,
       paddingLeft: 1,
       paddingRight: 1,
       minHeight: '100%'
    },
    chip: {
        color: "rgb(0 94 108)",
        fontSize: '16px',
        marginBottom: '12px',
        borderRadius: '4px',
    },
    deleteIcon: {
        fill: "rgb(0 94 108)"
    }
});

class AdvancedSearchSummary extends React.Component {
    constructor(props) {
        super();
        this.onDelete = this.onDelete.bind(this);
    }

    onDelete(selection) {
        return this.props.onDelete(selection);
    }
	
	getRegionName(value) {
        let name;
        this.props.advancedSearchFields.forEach((row) => {
             row.elements.forEach((field) => {
                if (field.label == 'regionId') {
                    let selectedOption = field.options.filter((op) => op.siteGroupId == value);
                    name = selectedOption[0].siteGroupName;
                }     
            });
        });
        return name;
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container} >
                {this.props.selectedValues.map((selection, index) => (
                    <Chip size='small' className={classes.chip} label={selection.label === 'regionId' ? this.getRegionName(selection.value) : selection.value} variant="outlined" onDelete={() => this.onDelete(selection)} deleteIcon={<RemoveCircleOutline fontSize="small" className={classes.deleteIcon} />} />
                )
                )
                }
            </div>
        );
    }
}

export default withStyles(styles)(AdvancedSearchSummary);
