import React from 'react';
import Typography from '@material-ui/core/Typography';

const ToggleFormQuestion = props => {
  return (
    <Typography component="div" p={2}>
      <div id="textMesg">Yet to be developed. Please come back later.</div>
    </Typography>
  );
};

export default ToggleFormQuestion;
