import React, { Component, Fragment } from 'react';
import { Typography, Box, IconButton, Collapse, Card, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import { openSectionState, FormStore } from '../../store/form.store';
import Components from '../form-element/CompMap';
import clsx from 'clsx';
import { currentUserState } from '../../../services/session.manager';

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
        paddingBottom: '0px',
    },
    flexGrow: {
        flexGrow: 1,
    },
    sectionTitle: {
        padding: theme.spacing(1, 2)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    editButton: {
        textTransform: 'capitalize'
    }
});

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            onHover: false, 
            userRole : '',
            editable: false,
        };
    }

    componentDidMount() {
        this.sectionStateSubscription = openSectionState.subscribe(sectionId => {
            this.setState({ expanded: sectionId === this.props.section.elementId })
        });
        this.currentUserSubscription = currentUserState.subscribe(user => {
            this.setState({ userRole: user.userRole })
        });
    }

    componentWillUnmount() {
        this.sectionStateSubscription.unsubscribe();
    }

    handleExpandClick = (event) => {
        FormStore.sharedInstance().openSection(this.props.section.elementId)
    };

    handleEdit = (event) => {
        FormStore.sharedInstance().currentSection(this.props.section.elementId)
        if(!this.state.expanded){
            this.handleExpandClick(event)
        }
    }

    onMouseOver = () => {
        this.setState({ onHover: true })
    }
    
    onMouseOut = () => {
        this.setState({ onHover: false })
    }

    handleEditShow = () => {
        const NON_EDITABLE_FIELDS = ['signature', 'helpimage', 'dependentDropdown'];
        const editableFields = [];
        this.props.section?.children?.forEach((section) => {
            if (section?.componentType !== 'subsection') {
                if (!NON_EDITABLE_FIELDS.includes(section?.componentType)) {
                    editableFields.push(section?.componentType);
                }
            } else {
                section?.children?.forEach((subSection) => {
                    if (!NON_EDITABLE_FIELDS.includes(subSection?.componentType)) {
                        editableFields.push(subSection?.componentType);
                    }
                })
            }
        })
        if (editableFields?.length) {
            this.setState({ editable: true });
        } else {
            this.setState({ editable: false });
        }
    }

    handleResetEditShow = () => {
        this.setState({ editable: false })
    }

    render() {
        const { classes } = this.props;
        return (<Fragment>
            <div onMouseEnter={this.handleEditShow} onMouseLeave={this.handleResetEditShow}>
            {this.props.section ? <Typography component='div' className={classes.root}>
                <Card square id="sectionCard" onMouseEnter={this.onMouseOver} onMouseLeave={this.onMouseOut}>
                    <Box display='flex' alignItems='center' className={classes.sectionTitle}
                        style={{ backgroundColor: (this.state.onHover || this.state.expanded) ? '#D8D8D8' : 'white', color: (this.props.section.label.length <= 0) ? 'red' : 'black' }}>
                        <IconButton edge="start"
                            onClick={this.handleExpandClick}>
                            <ExpandMoreIcon className={clsx(classes.expand, { [classes.expandOpen]: this.state.expanded })} />
                        </IconButton>
                        <Box display='flex' flexDirection='column'>
                            <Typography>{(this.props.section.label.length > 0) ? this.props.section.label : 'Section name is required'}</Typography>
                            <Typography style={{color: '#616161'}} 
                            	variant='caption'>{this.props.section.description}</Typography>
                        </Box>
                        <div className={classes.flexGrow} />
                        {(((this.state.userRole!== 'TECHNICIAN') && (this.state.onHover || this.state.expanded) && this.state.editable) ? <Button disableRipple onClick={this.handleEdit} className={classes.editButton} variant='text' startIcon={<EditIcon />}>Edit</Button> : null)}
                    </Box>
                    <Collapse in={this.state.expanded} timeout="auto" 
                    	unmountOnExit>
                        <RenderChildren section={this.props.section} />
                    </Collapse>
                </Card>
            </Typography> : null}
          </div>
        </Fragment>);
    }
}
function RenderChildren(props) {
    if (props.section !== undefined && props.section.children.length !== 0) {
    	const TagName = Components['question'];
        const SubSection = Components['subsection'];
        const items = props.section.children.map((item, key) =>
            item.componentType === 'subsection' ? <SubSection key={key} element={item} /> : <TagName key={key} element={item} />
        );
        return items;
    }
    return (<div> Empty Children </div>);
}

export default withStyles(styles)(Section);
