import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FormStore } from '../../../services/form.store';
import { ANSWER_OPTION_LABELS, ATTRIBUTES } from '../../../services/form.helper.service';
import LinkOptionalEquipment from '../LinkOptionalEquipment';
import AssignUniqueCode from "../AssignUniqueCode";

const useStyles = makeStyles((theme) => ({
  root: {},
  sideNavDivider: {
    marginLeft: 16,
  },
  confirmation: {
    fontSize: 14,
    width: 210,
    height: 100,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    padding: "10px",
    border: `1px solid #c69c4b`,
    background: "#f5ecdc"
  },
  confirmationText: {
    color: "#6d6962"
  },
  confirmButton: {
    marginLeft: 0,
    padding: 0
  }
}));


const DropDownMenu = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [multiSelectConfirmationAppear, setMultiSelectConfirmationAppear] = useState(false);
  const [popup, setPopup] = useState(false);
  const [assignUniqueCodePopup, setAssignUniqueCodePopup] = useState(false);
  const [menuItem, setMenuItem] = useState([
    {
      label: "Answer Options",
      children: [
        { label: 'Allow File Upload', isChecked: props.element.properties && props.element.properties.allowFileUpload ? props.element.properties.allowFileUpload : false, name: ATTRIBUTES.ALLOW_FILE_UPLOAD },
        { label: 'Answer Logic', isChecked: props.element.properties && props.element.properties.conditional ? props.element.properties.conditional : false, name: ATTRIBUTES.ANSWER_LOGIC },
        { label: "Multi Select", isChecked: props.element.properties && props.element.properties.multiple ? props.element.properties.multiple : false, name: ATTRIBUTES.MULTI_SELECT },
      ]
    },
    {
      label: "Other Actions",
      children: [
        { label: ANSWER_OPTION_LABELS.LINK_DATA_SOURCE, link: false },
        { label: ANSWER_OPTION_LABELS.LINK_OPTIONAL_EQUIPMENT },
        { label: ANSWER_OPTION_LABELS.ASSIGN_UNIQUE_CODE }
      ]
    }
  ]);
  const [disableAnswerLogic, setDisableAnswerLogic] = useState(menuItem.find(menuSection => menuSection.label === "Answer Options").children.find(menuItem => menuItem.name === ATTRIBUTES.MULTI_SELECT).isChecked ? true : false);
  
  const handleToggleSwitch = (index, mainIndex) => (event) => {
    let newArr = [...menuItem];
    newArr[mainIndex].children[index].isChecked = event.target.checked;
    setMenuItem(newArr);
    if (event.target.name === ATTRIBUTES.ALLOW_FILE_UPLOAD) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.ALLOW_FILE_UPLOAD, value: event.target.checked });
    }
    else if (event.target.name === ATTRIBUTES.ANSWER_LOGIC) {
      FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.ANSWER_LOGIC, value: event.target.checked });
    }
    else if (event.target.name === ATTRIBUTES.MULTI_SELECT) {
      if (event.target.checked) {
        setMultiSelectConfirmationAppear(true);
      } else {
        setDisableAnswerLogic(false);
        FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.MULTI_SELECT, value: false });
      }
    }
  };

  const handleConfirmClick = () => {
    setMultiSelectConfirmationAppear(false);
    let newArr = [...menuItem];
    newArr.find(menuSection => menuSection.label === "Answer Options").children.find(mItem => mItem.name === ATTRIBUTES.MULTI_SELECT).isChecked = true;
    newArr.find(menuSection => menuSection.label === "Answer Options").children.find(meItem => meItem.name === ATTRIBUTES.ANSWER_LOGIC).isChecked = false;
    setMenuItem(newArr);
    setDisableAnswerLogic(true);
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.MULTI_SELECT, value: true });
    FormStore.sharedInstance().toggleQuestionAttr({ attr: 'properties', name: ATTRIBUTES.ANSWER_LOGIC, value: false });
  }

  const handleListItem = (event, child) => {
    if (child.label === ANSWER_OPTION_LABELS.LINK_OPTIONAL_EQUIPMENT) {
      setPopup(true);
    }
    else if (child.label === ANSWER_OPTION_LABELS.ASSIGN_UNIQUE_CODE) {
      setAssignUniqueCodePopup(true);
    }
  }

  const onClose = () => {
    setPopup(false);
  }

  const onCloseAssignUniqueCodePopup = () => {
    setAssignUniqueCodePopup(false);
  }

  return (
    <div ref={ref}>
      {menuItem.map(({ ...item }, mainIndex) => (
        <span id="menuSection" key={mainIndex}>
          <ListSubheader>
            <Typography
              variant="overline"
              component="div"
              style={{ marginTop: 5 }}>
              {item.label}
            </Typography>
          </ListSubheader>
          {item.children.map((child, index) => (
            <span id="menuOption" key={child.label}>
              <MenuItem>
                <ListItemText primary={child.label} onClick={(event) => handleListItem(event, child)} />
                <ListItemSecondaryAction>
                  {"isChecked" in child ? (
                    <Switch
                      edge="end"
                      name={child.name}
                      checked={child.isChecked}
                      disabled={child.name === ATTRIBUTES.ANSWER_LOGIC ? disableAnswerLogic :false}
                      size="small"
                      color="primary"
                      onChange={handleToggleSwitch(index, mainIndex)}
                    />
                  ) : null}
                </ListItemSecondaryAction>
              </MenuItem>
              <span>
                {child.name === ATTRIBUTES.MULTI_SELECT && child.isChecked && multiSelectConfirmationAppear ? (
                  <div className={classes.confirmation}>
                    <Typography  className={classes.confirmationText} gutterBottom>
                      Answer logic (if applied) will be discarded.
                    </Typography>
                    <Button id="confirm" className={classes.confirmButton} color="primary" onClick={handleConfirmClick}>
                      Confirm
                    </Button>
                  </div>
                ) : null}
              </span>
              <Divider light className={classes.sideNavDivider} />
            </span>
          ))}
        </span>
      ))}
      <LinkOptionalEquipment
        open={popup} onClose={onClose} title="Link Optional Equipment"
      />
      {assignUniqueCodePopup &&
        <AssignUniqueCode
          onClose={onCloseAssignUniqueCodePopup}
        />
      }
    </div>
  );
});

export default DropDownMenu;
