import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormTabs from './FormTabs'
import { makeStyles } from '@material-ui/core/styles';

export default function CopyFormDialog(props) {
  const{open} = props;
  const useStyles = makeStyles((theme) => ({
    root:{
    },
    dialogPaper: {
      minHeight: '95%',
      minWidth: '60%',
      padding:"6px",
    },
}))

  const classes = useStyles();  
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          <FormTabs copyForm={true} />
        </DialogContent>
      </Dialog>
    </div>
  );
}