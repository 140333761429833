export default {
  // borderRadius: '2px',
  // marginRight: '5px',
  // marginBottom: '3px',
  // marginTop: '3px',
  root: {
    borderRadius: '2px',
    marginRight: '5px',
    marginBottom: '3px',
    marginTop: '3px',
  },
};

