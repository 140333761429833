import React, { useState, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import FormElementSelector from './FormElementSelector';
import ToggleFormQuestion from './ToggleFormQuestion';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';

const AntTabs = withStyles({
  root: {
    minHeight: 64,
   },
  indicator: {
    display: 'none',
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 64,
    opacity: 1,
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderRight: 'none',
    },
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: `${theme.palette.primary.main}`,
      backgroundColor: 'transparent',
      borderBottom: '1px solid transparent',
    },
    '&:focus': {
      color: `${theme.palette.primary.main}`,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const RenderingTabsBasedOnState = props => {

  const [tabs, setTabs] = useState([
    { active: true, icon: <DnsOutlinedIcon />, content: <FormElementSelector /> },
    { active: false ,hidden:true ,icon: <DnsOutlinedIcon />, content: <ToggleFormQuestion />},
  ]);

  const onChange = (e, value) => {
    setTabs(
      tabs
        .map(tab => ({ ...tab, active: false }))
        .map((tab, index) => ({...tab, active: index === value}))
    );
  };

  const active = tabs.findIndex(tab => tab.active);
  const content = tabs[active].content;

  return (
    <Fragment>
      <AntTabs variant="fullWidth" value={active} onChange={onChange}>
        {tabs.map((tab, index) => {
          if (tab?.hidden !== true){
            return <AntTab key={index} icon={tab.icon} />
          }
        })}
      </AntTabs>
      <Typography component="div">
        {content}
      </Typography>
    </Fragment>
  );
}

export default RenderingTabsBasedOnState;
